import React, { useState, useEffect } from 'react'
import AuthPageWrapper from '../../components/AuthPageWrapper';

import { Box, Button, TextField, Typography, InputAdornment, CircularProgress } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { login } from '../../api/auth';
import LoginLogo from '../../assets/images/Login_logo.png';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../context/AuthProvider';
import { getBranchByClient } from '../../api/branch';
import { useClient } from '../../context/ClientProvider';
import { getAllClients } from '../../api/client';

const loginValidationSchema = Yup.object().shape({
    userName: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
})

const Login = () => {
    const { setGlobalUser } = useAuth();
    // console.log("location:", location);
    const { setGlobalClientList, setActiveGlobalClient, setGlobalBranchList, setActiveGlobalBranch } = useClient();

    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigateTo = localStorage?.getItem("appPath")?.replace(/^"|"$/g, '');
    // console.log("superadmin navigateTo", navigateTo);
    // console.log("superadmin navigateTo window", path);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const formik = useFormik({
        initialValues: {
            userName: '',
            password: '',
        },
        validationSchema: loginValidationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            const adminCredentials = {
                userName: values.userName,
                password: values.password,
            };
            try {
                const response = await login(adminCredentials);
                if (response) {
                    setGlobalUser((prevState) => { return { ...prevState, status: true, user: response } });
                    localStorage.setItem("user", JSON.stringify(response));
                    localStorage.setItem("token", JSON.stringify(response.token));
                    localStorage.setItem("refreshToken", response.refreshToken);
                    localStorage.setItem("userType", JSON.stringify(response.userType));
                    try {
                        const response = await getAllClients({
                            "allList": true
                        });
                        if (response) {
                            localStorage.setItem("globalClientList", JSON.stringify(response));
                            setGlobalClientList(response);
                            localStorage.setItem("activeGlobalClient", JSON.stringify(response[0]));
                            setActiveGlobalClient(response[0]);
                        }

                        if (response[0]?.id) {
                            const branchResponse = await getBranchByClient(response[0]?.id);
                            if (branchResponse.length > 0) {
                                localStorage.setItem("globalBranchList", JSON.stringify(branchResponse));
                                localStorage.setItem("activeGlobalBranch", JSON.stringify(branchResponse[0]));
                                setGlobalBranchList(branchResponse);
                                setActiveGlobalBranch(branchResponse[0]);
                            } else {
                                localStorage.setItem("globalBranchList", null);
                                localStorage.setItem("activeGlobalBranch", null);
                                setGlobalBranchList(null);
                                setActiveGlobalBranch(null);
                            }
                        }
                    } catch (error) {
                        console.error('Error fetching global client data: ', error);
                    }

                    navigateTo ? navigate(navigateTo, { replace: true }) : navigate("/superadmin/app", { replace: true });
                    // navigate("/superadmin/app", {replace: true});
                }
            } catch (error) {
                toast.error("Invalid Credentials");
            } finally {
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const user = localStorage.getItem("user");
        const userData = JSON.parse(user);
        userData?.userType === "super_admin" && navigate("/superadmin/app/dashboard", { replace: true });
    }, []);
    return (
        <AuthPageWrapper>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', background: '#fff', borderRadius: '32px', padding: '20px', boxShadow: '0px 4px 40px 0px #DC419C1A' }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', mb: 5 }}>
                    <div style={{ textAlign: 'left' }} >
                        <Box sx={{ my: '1.5rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={LoginLogo} width={200} height={45} alt="login_logo" />
                            <Typography variant='h4' sx={{ fontWeight: 500, fontSize: '1.4rem !important', color: '', mb: '6px', mt: 2 }}>Sign in</Typography>
                            {/* <Typography variant='body1' sx={{ color: 'rgba(47, 43, 61, 0.68)', fontSize: '0.9375rem' }}>Please sign-in to your account and start the adventure</Typography> */}
                        </Box>
                        <form onSubmit={formik.handleSubmit}>
                            <Box sx={{ mb: { xs: 4, xl: 3 }, boxShadow: '0px 18px 30px 0px #4461F21C' }}>
                                {/* <Typography variant="subtitle2" sx={{ color: 'rgba(47, 43, 61, 0.78)' }}>User Name</Typography> */}
                                <TextField
                                    id="outlined-userid-input"
                                    type="text"
                                    autoComplete="off"
                                    {...formik.getFieldProps('userName')}
                                    size="small"
                                    sx={{
                                        width: '100%',
                                    }}
                                    inputProps={{ style: { height: '21px' } }}
                                    placeholder='Username'
                                />
                                {formik.touched.userName && formik.errors.userName ? (
                                    <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors.userName}</Typography>
                                ) : null}
                            </Box>
                            <Box sx={{ mb: { xs: 2, xl: 3 }, boxShadow: '0px 18px 30px 0px #4461F21C' }}>
                                {/* <Typography variant="subtitle2" sx={{ color: 'rgba(47, 43, 61, 0.78)' }}>Password</Typography> */}
                                <TextField
                                    // id="outlined-password-input"
                                    // label="Password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="off"
                                    {...formik.getFieldProps('password')}
                                    size="small"
                                    placeholder='Password'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {!showPassword ? (
                                                    <Visibility onClick={handleTogglePassword} />
                                                ) : (
                                                    <VisibilityOff onClick={handleTogglePassword} />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        width: '100%',
                                    }}
                                    inputProps={{ style: { height: '21px' } }}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors.password}</Typography>
                                ) : null}
                            </Box>

                            {/* <Box sx={{ justifyContent: 'end', display: 'flex', mb: { xs: 3, xl: 4 } }}>
                                <Link style={{ textDecoration: 'none' }} to='forgot-password'>
                                    <Typography variant="subtitle1" sx={{ color: 'secondary.main' }}>Forgot Password?</Typography>
                                </Link>
                            </Box> */}

                            <div>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={isLoading}
                                    sx={{
                                        // minWidth: 200,
                                        width: '100%',
                                        padding: '0.625rem 1.25rem',
                                        backgroundColor: 'secondary.main',
                                        // borderRadius: '6px',
                                        typography: "btnPrimary",
                                        mt: 2
                                    }}
                                >{isLoading ? (
                                    <CircularProgress size={24} sx={{ mr: 1 }} color="inherit" />
                                ) : (
                                    'Login'
                                )}
                                </Button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Box>
        </AuthPageWrapper>
    )
}

export default Login