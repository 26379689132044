import React, { useState } from 'react';
import { Autocomplete, Box, Button, IconButton, Modal, Stack, TextField, Tooltip, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import toast from 'react-hot-toast';
import palette from '../../theme/palette';
import ClearIcon from '@mui/icons-material/Clear';

const CreateBulkRoomModal = (props) => {
    const { bulkRoomModalOpen, floorList, setBulkRoomModalOpen, createBulkRoom } = props;
    const [openModalFloor, setOpenModalFloor] = useState(false);
    const [selectedModalFloor, setSelectedModalFloor] = useState(null);

    // handle document file
    const [selectedFile, setSelectedFile] = useState(null);
    // const [fileKey, setFileKey] = useState(0);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file?.type !== 'text/csv') {
            toast.error("Invalid file type")
        } else {
            setSelectedFile(file);
        }

    };

    const handleModalClose = () => {
        setBulkRoomModalOpen(false);
        setSelectedFile(false);
    }


    return (
        <>
            <Modal
                open={bulkRoomModalOpen}
                onClose={handleModalClose}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 2,
                        borderRadius: 1,
                        // maxHeight: "80vh",
                        // height: "50px",
                        width: "400px",
                        overflowY: "auto",
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                        <Typography
                            variant="subtitle2"
                        >
                            Create Multiple Rooms
                        </Typography>
                        <IconButton
                            sx={{ position: 'absolute', top: 5, right: 5 }}
                            color={palette.grey[500]}
                            onClick={handleModalClose}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    <Stack direction="row" spacing={2} alignItems="center" mb={1}>
                        <Autocomplete
                            id="floor"
                            size="small"
                            sx={{ width: 300 }}
                            open={openModalFloor}
                            onOpen={() => {
                                setOpenModalFloor(true);
                            }}
                            onClose={() => {
                                setOpenModalFloor(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option.name}
                            options={floorList}
                            // loading={loadingFloor}
                            onChange={(event, floorValue, isClear) => {
                                // if (isClear === "clear") {
                                //     roomsDataByBranch();
                                // }
                                setSelectedModalFloor(floorValue);
                            }}
                            value={selectedModalFloor}
                            noOptionsText="No rooms"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Floor"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <Tooltip title="Upload Template">
                            <IconButton
                                component="label"
                                size="medium"
                                color="primary"
                            >
                                <CloudUploadIcon />
                                <input
                                    // key={fileKey}
                                    className="customer-file-upload-input"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                    <Box>
                        {selectedFile && (
                            <Typography sx={{ display: "inline-block", ml: 0.5 }}>
                                {selectedFile.name}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ textAlign: "right", mt: 4 }}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (!selectedModalFloor || !selectedFile) {
                                    toast.error("Please enter all fields");
                                } else {
                                    createBulkRoom(selectedModalFloor?.id, selectedFile)
                                }
                            }}
                        >
                            Create
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default CreateBulkRoomModal;