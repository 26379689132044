import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { generateRefreshToken } from "./auth";

const APP_API_BASE_URL = `${window.location.origin}/bapi`;

let isTokenRefreshed = false; // Flag to track token refresh status

const axiosInstance = axios.create({
    baseURL: APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const currentDate = new Date();

        const token = localStorage.getItem("token");
        const clientId = localStorage.getItem("clientId");
        const branchIds = localStorage.getItem("branchIds");

        if (token) {
            try {
                const parseToken = JSON.parse(token);
                const decodedToken = jwtDecode(parseToken);

                if (decodedToken.exp * 1000 < currentDate.getTime()) {
                    if (!isTokenRefreshed) {
                        try {
                            const data = await generateRefreshToken();
                            localStorage.setItem("token", JSON.stringify(data.token));
                            localStorage.setItem("clientId", JSON.stringify(data.clientId));
                            localStorage.setItem("branchIds", JSON.stringify(data.branchId));
                            config.headers.Authorization = `uid ${data.token}`;
                            config.headers.clientId = data.clientId ? JSON.stringify(data.clientId) : "null";
                            config.headers.branchIds = data.branchId ? JSON.stringify(data.branchId) : "null";
                            isTokenRefreshed = true;
                            return config;
                        } catch (error) {
                            console.log("Error refreshing token: ", error);
                            throw error;
                        }
                    }
                    config.headers.Authorization = `uid ${parseToken}`;
                    config.headers.clientId = clientId ? clientId : "null";
                    config.headers.branchIds = branchIds ? branchIds : "null";
                    return config;
                } else {
                    config.headers.Authorization = `uid ${parseToken}`;
                    config.headers.clientId = clientId ? clientId : "null";
                    config.headers.branchIds = branchIds ? branchIds : "null";
                    return config;
                }
            } catch (error) {
                // console.log("Error parsing token: ", error);
                throw error;
            }
        } else {
            // console.log("Token not found in localStorage");
            throw new Error("Token not found in localStorage");
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;