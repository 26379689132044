import React from "react";
import { Box, IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { getInvoiceStatus } from "../../api/invoice";
import { loginUserType } from "../../utils/userType";
import { Link } from "react-router-dom";

const InvoiceStatusAlert = ({ activeGlobalBranch, userType }) => {
  const [open, setOpen] = useState(true);
  const [invoiceStatusInfo, setInvoiceStatusInfo] = useState([]);

  const getInvoiceStatusByBranch = async () => {
    try {
      const response = await getInvoiceStatus({
        branchId: activeGlobalBranch.id,
      });
      if (response) {
        setInvoiceStatusInfo(response);
        setOpen(true);
      }
    } catch (error) {
      console.error("Error in fetching invoice Status", error);
    }
  };

  useEffect(() => {
    getInvoiceStatusByBranch();
  }, [activeGlobalBranch]);

  return (
    <>
      {(userType === loginUserType.ADMIN ||
        userType === loginUserType.SUPERVISOR) &&
        invoiceStatusInfo?.invoiceStatus ? (
        <Box
          sx={{
            width: "90%",
            alignItems: "center",
            position: "absolute",
            zIndex: "100",
          }}
        >
          <Collapse in={open}>
            <Alert
              severity={
                invoiceStatusInfo?.code === 2
                  ? "info" : invoiceStatusInfo?.code === 3 ? "warning" : "error"
              }
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 0.5 }}
            >
              {[4, 3, 2, 1].includes(invoiceStatusInfo?.code)
                ? (
                  <>
                    {invoiceStatusInfo.message}!
                    <Link to="invoice" style={{ marginLeft: '8px' }}>
                      Paynow
                    </Link>
                  </>
                )
                : null}
            </Alert>
          </Collapse>
        </Box>
      ) : null}
    </>
  );
};

export default InvoiceStatusAlert;
