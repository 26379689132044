import axiosInstance from ".";

export const getAllServices = async () => {
    try {
        const response = await axiosInstance.get('/services');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addService = async (newServicesData) => {
    try {
        const response = await axiosInstance.post('/admin/services/create', newServicesData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data
    } catch (error) {
        throw error
    }
}

export const deleteService = async (serviceId) => {
    try {
        const response = await axiosInstance.delete(`/admin/services/${serviceId}`)
        return response.data
    } catch (error) {
        throw error
    }
}

export const updateService = async (serviceId, newServicesData) => {
    try {
        const response = await axiosInstance.put(`/admin/services/${serviceId}`, newServicesData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data
    } catch (error) {
        throw error}
}

export const getDefaultServicesByServiceCategory = async (serviceCategoryId) => {
    try {
        const response = await axiosInstance.get(`/admin/services?branch=null&serviceCategoryId=${serviceCategoryId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getServicesByServiceCategory = async (serviceCategoryId) => {
    try {
        const response = await axiosInstance.get(`/admin/services?serviceCategoryId=${serviceCategoryId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getServicesByBranch = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/admin/services?branchId=${branchId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}