import React from 'react'
import { Box, Card, Typography, useMediaQuery } from "@mui/material";
import LoginImg from '../../assets/images/login_img.png';
import SuperAdminLoginImg from '../../assets/images/superAdmin_login.png';
import AdminLoginImg from "../../assets/images/admin_login.png"
import { useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

const AuthPageWrapper = ({ children }) => {
    const location = useLocation();
    const pathName = location.pathname?.replace(/^"|"$/g, '').substring(1);
    // console.log("location from AuthPageWrapper:", pathName);
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const leftSpring = useSpring({ from: { marginLeft: -300 }, to: { marginLeft: 50 } });
    const rightSpring = useSpring({ from: { transform: 'translateX(100%)' }, to: { transform: 'translateX(0%)' } });
    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                // backgroundColor: '',
                // border: '2px solid green'
            }}
        >
            <Card
                sx={{
                    width: '100%',
                    height: '100vh',
                    overflow: 'auto',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    // border: '2px solid red',
                    borderRadius: '0',
                    background: 'linear-gradient(114.15deg, #FFF3FB 6.87%, #F1EFFF 93.39%)',
                }}
            >
                <Box
                    sx={{
                        width: { xs: '100%', sm: '100%', lg: '60%' },
                        position: 'relative',
                        padding: { xs: 5, lg: 0 },
                        // display: { xs: 'none', sm: 'flex' },
                        display: "flex",
                        alignItems: { sm: 'center' },
                        justifyContent: { sm: 'center' },
                        flexDirection: 'column',
                        // backgroundColor: 'transparent',
                        color: (theme) => theme.palette.common.white,
                        fontSize: 14,
                    }}
                >
                    <animated.div style={leftSpring}>
                        {pathName === 'superadmin' && <Typography sx={{ color: '#5F4699', fontWeight: 700, fontSize: '25px', mb: 1 }}>Super Admin</Typography>}
                        <Typography sx={{ color: '#C656A0', fontWeight: 700, fontSize: '25px', mb: 1 }}>WELCOME TO GUESTCARE365</Typography>
                        <Typography sx={{ color: '#000', fontWeight: 400, fontSize: '16px', mb: 5 }}>Please Sign-in into your account and Start the Adventure</Typography>
                    </animated.div>
                    <animated.div style={leftSpring}>
                        <Box sx={{ margin: 'auto', width: '60%', height: '100%', objectFit: 'cover', border: '2px solid greenz' }}>
                            <img src={pathName === 'superadmin' ? SuperAdminLoginImg : AdminLoginImg} alt="" srcset="" />
                        </Box>
                    </animated.div>
                </Box>
                <Box
                    sx={{
                        width: { xs: '100%', sm: '100%', lg: '40%' },
                        padding: { xs: 5, lg: 7 },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        // backgroundColor: '#fff',
                        // overflowY: 'auto'
                        // border: '2px solid black'
                    }}
                >
                    <animated.div style={rightSpring}>
                        <Box sx={{ width: '100%', border: '2px solid reds' }}>
                            {children}
                        </Box>
                    </animated.div>
                </Box>
            </Card>
        </Box>
    );
}

export default AuthPageWrapper