import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, TextField, Typography, Button, InputAdornment } from "@mui/material";
import toast from "react-hot-toast";
import {
  resetSuperadminPassword,
  resetAdminPassword,
  changeUserPassword,
} from "../../api/resetpassword";
import { useAuth } from "../../context/AuthProvider";
import { loginUserType } from "../../utils/userType";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import { Visibility, VisibilityOff } from '@mui/icons-material';

const passwordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "should be atleast 8 characters "),
  confirmPassword: Yup.string()
    .required("Cofirm password is required")
    .min(8, "should be atleast 8 characters "),
});

const ChangePassword = () => {
  const isSmallScreen = useIsSmallScreen();
  const { globalUser } = useAuth();
  const localUserType = localStorage.getItem("userType");
  const userType = JSON.parse(localUserType);
  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: true,
    newPassword: false,
    confirmPassword: false,
  });

  const handleTogglePassword = (field) => {
    setPasswordVisibility(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: passwordValidationSchema,
    onSubmit: async (values) => {
      const passwordDetails = {
        password: values.oldPassword,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      };
      try {
        if (passwordDetails.newPassword === passwordDetails.confirmPassword) {
          if (userType === loginUserType.SUPER_ADMIN) {
            const passwordSuperadmin = {
              superAdminId: globalUser?.user?.userId,
              password: passwordDetails.password,
              newPassword: passwordDetails.newPassword,
            };
            try {
              const response = await resetSuperadminPassword(
                passwordSuperadmin
              );
              if (response) {
                toast.success("password reset is successful");
                formik.resetForm();
              }
            } catch (error) {
              toast.error(error.response.data.message.response);
            }
          } else if (
            userType === loginUserType.ADMIN ||
            userType === loginUserType.SUPERVISOR
          ) {
            const passwordAdmin = {
              userId: globalUser?.user?.userId,
              password: passwordDetails.password,
              newPassword: passwordDetails.newPassword,
            };
            try {
              const response = await resetAdminPassword(passwordAdmin);
              if (response) {
                toast.success("password reset is successful");
                formik.resetForm();
              }
            } catch (error) {
              toast.error(error.response.data.message.response);
            }
          }
        } else {
          toast.error(`Passwords don't match, Please enter again`);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || error.message);
      }
    },
  });
  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "centre" }}
      >
        <Box
          sx={{ width: 400, display: "flex", flexDirection: "column", my: 5, ...(isSmallScreen && { width: 320 }) }}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 500,
                fontSize: "1.4rem !important",
                color: "rgba(47, 43, 61, 0.78)",
                mb: "20px",
              }}
            >
              Change Password
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: { xs: 2, xl: 3 } }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "rgba(47, 43, 61, 0.78)", fontSize: 14 }}
              >
                Please Enter Old Password :
              </Typography>
              <TextField
                id="outlined-userid-input"
                type={passwordVisibility.oldPassword ? "text" : "password"}
                autoComplete="off"
                {...formik.getFieldProps("oldPassword")}
                size="small"
                sx={{
                  width: "100%",
                }}
                inputProps={{ style: { height: "21px" } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!passwordVisibility.oldPassword ? (
                        <Visibility onClick={()=>handleTogglePassword("oldPassword")} />
                      ) : (
                        <VisibilityOff onClick={()=>handleTogglePassword("oldPassword")} />
                      )}
                    </InputAdornment>
                  )
                }}
              />
              {formik.touched.oldPassword && formik.errors.oldPassword ? (
                <Typography
                  sx={{
                    color: "red",
                    variant: "body2",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.oldPassword}
                </Typography>
              ) : null}
              <Typography
                variant="subtitle2"
                sx={{
                  color: "rgba(47, 43, 61, 0.78)",
                  fontSize: 14,
                  mt: "20px",
                }}
              >
                Please Enter New Password :
              </Typography>
              <TextField
                id="outlined-userid-input"
                type={passwordVisibility.newPassword ? "text" : "password"}
                autoComplete="off"
                {...formik.getFieldProps("newPassword")}
                size="small"
                sx={{
                  width: "100%",
                }}
                inputProps={{ style: { height: "21px" } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!passwordVisibility.newPassword ? (
                        <Visibility onClick={()=>handleTogglePassword("newPassword")} />
                      ) : (
                        <VisibilityOff onClick={()=>handleTogglePassword("newPassword")} />
                      )}
                    </InputAdornment>
                  )
                }}
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <Typography
                  sx={{
                    color: "red",
                    variant: "body2",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.newPassword}
                </Typography>
              ) : null}

              <Typography
                variant="subtitle2"
                sx={{
                  color: "rgba(47, 43, 61, 0.78)",
                  fontSize: 14,
                  mt: "20px",
                }}
              >
                Confirm Password :
              </Typography>
              <TextField
                id="outlined-userid-input"
                type={passwordVisibility.confirmPassword ? "text" : "password"}
                autoComplete="off"
                {...formik.getFieldProps("confirmPassword")}
                size="small"
                sx={{
                  width: "100%",
                }}
                inputProps={{ style: { height: "21px" } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!passwordVisibility.confirmPassword ? (
                        <Visibility onClick={()=>handleTogglePassword("confirmPassword")} />
                      ) : (
                        <VisibilityOff onClick={()=>handleTogglePassword("confirmPassword")} />
                      )}
                    </InputAdornment>
                  )
                }}
              />
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                <Typography
                  sx={{
                    color: "red",
                    variant: "body2",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.confirmPassword}
                </Typography>
              ) : null}
            </Box>
            <div>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: 40,
                  fontSize: 14,
                  width: "100%",
                  mb: { xs: 2, xl: 3 },
                }}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default ChangePassword;
