import axiosInstance from ".";

export const getAllSuperadmins = async (superadminDetails) => {
    try {
        const response = await axiosInstance.post('/admin/superadmin/getall', superadminDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const addSuperadmin = async (superadminDetails) => {
    try {
        const response = await axiosInstance.post('/admin/superadmin/register', superadminDetails,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data
    } catch (error) {
        throw error
    }
}

export const deleteSuperadmin = async (superadminId) => {
    try {
        const response = await axiosInstance.delete(`/admin/superadmin/${superadminId}`)
        return response.data
    } catch (error) {
        throw error
    }
}

export const updateSuperadmin = async (superadminId, superadminDetails) => {
    try {
        const response = await axiosInstance.put(`/admin/superadmin/${superadminId}`, superadminDetails,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data
    } catch (error) {
        throw error
    }
}


export const getSuperadmin = async (superadminId) => {
    try {
        const response = await axiosInstance.get(`/admin/superadmin/${superadminId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const viewsuperadmindocumentpassword = async (superadminDetails) => {
    try {
        const response = await axiosInstance.post('/admin/superadmin/viewdocumentpassword', superadminDetails);
        return response.data
    } catch (error) {
        throw error
    }
}

export const verifysuperadmindocumentpassword = async (superadminDetails) => {
    try {
        const response = await axiosInstance.post('/admin/superadmin/verifydocumentpassword', superadminDetails);
        return response.data
    } catch (error) {
        throw error
    }
}