import axiosInstance from ".";

export const getServiceCategory = async () => {
    try {
        const response = await axiosInstance.get(`/admin/servicecategory`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getServiceCategoryByBranch = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/admin/servicecategory?branchId=${branchId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const addServiceCategory = async (serviceCategoryDetails) => {
    try {
        const response = await axiosInstance.post('/admin/servicecategory/create', serviceCategoryDetails, 
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        } )
        return response.data
    } catch (error) {
        throw error
    }
}

export const updateServiceCategory = async (serviceCategoryId, serviceCategoryDetails) => {
    try {
        const response = await axiosInstance.put(`/admin/servicecategory/${serviceCategoryId}`, serviceCategoryDetails, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data
    } catch (error) {
        throw error
    }
}

export const deleteServiceCategory = async (serviceCategoryId) => {
    try {
        const response = await axiosInstance.delete(`/admin/servicecategory/${serviceCategoryId}`)
        return response.data
    } catch (error) {
        throw error
    }
}