import React from "react";
import { Button, Stack, Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import moment from "moment";

const CheckoutDataFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  openEndDatePicker,
  setOpenEndDatePicker,
  getCheckouts,
}) => {
  const isSmallScreen = useIsSmallScreen();
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{...(isSmallScreen && { flexDirection: "column", alignItems: "flex-start" })}}>
        <Stack direction="row" spacing={2} alignItems="center">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => {
                setStartDate(date);
                setOpenEndDatePicker(true);
              }}
              slotProps={{ textField: { size: "small" } }}
              sx={{ width: 200, ...(isSmallScreen && { width: 150 }) }}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              open={openEndDatePicker}
              onOpen={() => setOpenEndDatePicker(true)}
              onClose={() => setOpenEndDatePicker(false)}
              slotProps={{ textField: { size: "small" } }}
              sx={{ width: 200, ...(isSmallScreen && { width: 150 }) }}
            />
          </LocalizationProvider>
          {/* <Button
                    variant="contained"
                    color="primary"
                    sx={{ height: 30 }}
                    onClick={() => checkoutData()}
                >
                    Find
                </Button> */}
          <Button
            sx={{ height: 30 }}
            variant="contained"
            onClick={() => {
              /* setStartDate(null);
              setEndDate(null); */
              setStartDate(moment().subtract(7, "days").startOf("day"));
              setEndDate(moment().endOf("day"));
            }}
          >
            Reset
          </Button>
        </Stack>
        <Box paddingRight={2}>
          <Button
            variant="contained"
            color="primary"
            sx={{ height: 30, ...(isSmallScreen && { mt: 1 }) }}
            onClick={() => {
              getCheckouts();
            }}
          >
            Refresh
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default CheckoutDataFilter;
