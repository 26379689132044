import { Box } from '@mui/material'
import React from 'react'
import palette from '../../theme/palette';

const Loader = () => {
    return (
        <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }
        }>
            <span
                className="loader"
                style={{
                    width: '48px',
                    height: '48px',
                    border: '5px solid #FFF',
                    borderBottomColor: palette.primary.main,
                    borderRadius: '50%',
                    display: 'inline-block',
                    boxSizing: 'border-box',
                    animation: 'rotation 1s linear infinite',
                }}
            ></span></Box >
    )
}

export default Loader;