import React from 'react';
import { Tooltip, styled, tooltipClasses } from '@mui/material';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFEBF9",
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const CustomTooltip = ({ title, children }) => {
    return (
        <LightTooltip title={title}>
            {children}
        </LightTooltip>
    );
};

export default CustomTooltip;
