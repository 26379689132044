export default function EditIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#A94C9B"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z"
        fill="#A94C9B"
      />
      <path
        d="M8.50008 17.6896C7.89008 17.6896 7.33008 17.4696 6.92008 17.0696C6.43008 16.5796 6.22008 15.8696 6.33008 15.1196L6.76008 12.1096C6.84008 11.5296 7.22008 10.7796 7.63008 10.3696L15.5101 2.48957C17.5001 0.49957 19.5201 0.49957 21.5101 2.48957C22.6001 3.57957 23.0901 4.68957 22.9901 5.79957C22.9001 6.69957 22.4201 7.57957 21.5101 8.47957L13.6301 16.3596C13.2201 16.7696 12.4701 17.1496 11.8901 17.2296L8.88008 17.6596C8.75008 17.6896 8.62008 17.6896 8.50008 17.6896ZM16.5701 3.54957L8.69008 11.4296C8.50008 11.6196 8.28008 12.0596 8.24008 12.3196L7.81008 15.3296C7.77008 15.6196 7.83008 15.8596 7.98008 16.0096C8.13008 16.1596 8.37008 16.2196 8.66008 16.1796L11.6701 15.7496C11.9301 15.7096 12.3801 15.4896 12.5601 15.2996L20.4401 7.41957C21.0901 6.76957 21.4301 6.18957 21.4801 5.64957C21.5401 4.99957 21.2001 4.30957 20.4401 3.53957C18.8401 1.93957 17.7401 2.38957 16.5701 3.54957Z"
        fill="#A94C9B"
      />
      <path
        d="M19.8501 9.83027C19.7801 9.83027 19.7101 9.82027 19.6501 9.80027C17.0201 9.06027 14.9301 6.97027 14.1901 4.34027C14.0801 3.94027 14.3101 3.53027 14.7101 3.41027C15.1101 3.30027 15.5201 3.53027 15.6301 3.93027C16.2301 6.06027 17.9201 7.75027 20.0501 8.35027C20.4501 8.46027 20.6801 8.88027 20.5701 9.28027C20.4801 9.62027 20.1801 9.83027 19.8501 9.83027Z"
        fill="#A94C9B"
      />
    </svg>
  );
}
