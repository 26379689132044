import React, { useState, useEffect } from 'react'
import { AdminLogin, NotFound, Login } from '../../pages';
import axios from 'axios';
import LoadingScreen from '../LoadingScreen';

const SubdomainCheck = ({ value }) => {
    const origin = window.location.origin;
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    const pathname = window.location.pathname;

    const [isLoading, setIsLoading] = useState(false);
    const [isClientPresent, setIsClientPresent] = useState(false);

    useEffect(() => {
        let isMounted = true;

        const checkSubdomain = async () => {
            try {
                const response = await axios.get(`${origin}/bapi/openadmin/getid/${subdomain}`);
                if (isMounted) {
                    setIsClientPresent(!!response.data);
                    localStorage.setItem("clientId", response?.data?.clientId)
                    setIsLoading(true);
                }
            } catch (error) {
                // console.error('Error checking subdomain:', error);
                if (isMounted) {
                    setIsClientPresent(false);
                    setIsLoading(true);
                }
            }
        };

        checkSubdomain();

        // Cleanup function
        return () => {
            isMounted = false;
        };
    }, []);

    if (!isLoading) {
        return <LoadingScreen />;
    }

    if (value === "superadmin") {
        if (subdomain === "adminstage" || subdomain === "admin" && pathname === "/superadmin") {
            return <Login />
        } else {
            return <NotFound />;
        }
    } else if (value === "admin") {
        if (isClientPresent) {
            return <AdminLogin />;
        } else {
            return <NotFound />;
        }
    }
}

export default SubdomainCheck;