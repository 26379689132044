import axiosInstance from ".";

export const getBranchsetting = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/admin/branchsetting/findone/${branchId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const updateBranchsetting = async (branchId, settingDetails) => {
    try {
        const response = await axiosInstance.put(`/admin/branchsetting/${branchId}`, settingDetails);
        return response.data
    } catch (error) {
        throw error
    }
}