import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const ConfirmationDialog = ({ open, onClose, actionMode }) => {
    return (
        <>
            <Dialog
                open={open}
                onClose={() => onClose(false)}
            >
                <DialogTitle>{actionMode === 'edit' ? 'Edit Checkout' : 'Add Checkout'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to {actionMode === 'edit' ? 'edit this check-out' : 'create a checkin?'} 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose(false)} color="secondary">
                        No
                    </Button>
                    <Button onClick={() => onClose(true)} variant="contained" color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConfirmationDialog;