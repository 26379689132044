import React, { useState } from "react";
import AuthPageWrapper from "../../components/AuthPageWrapper";
import { Box, TextField, Typography, Button, Grid } from "@mui/material";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { forgotPassword, resendOTP } from "../../api/resetpassword";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const passwordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Please enter new Password")
    .min(8, "should be atleast 8 characters "),
  //.min(/^[A-Z]+$/, "Must be one Upper case present"),
  confirmPassword: Yup.string().required("Please confirm your password"),
});

const ValidateOTP = () => {
  const { state } = useLocation();
  const [mobileNumber, setMobileNumber] = useState(state?.mobileNumber);
  const [otpValue, setOtpValue] = useState("");
  const [otpResent, setOtpResent] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    let timerId;
    if (otpResent && resendTimer > 0) {
      timerId = setTimeout(
        () => setResendTimer((prevTimer) => prevTimer - 1),
        1000
      );
    }
    return () => clearTimeout(timerId);
  }, [otpResent, resendTimer]);

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: passwordValidationSchema,
    onSubmit: async (values) => {
      const passwordDetails = {
        contactNumber: mobileNumber,
        otp: otpValue,
        newPassword: values.newPassword,
      };
      const reenteredPassword = values.confirmPassword;
      try {
        if (passwordDetails.newPassword === reenteredPassword) {
          const response = await forgotPassword(passwordDetails);
          if (response) {
            toast.success("password reset is successful");
            formik.resetForm();
            setOtpValue("");
            navigate("/");
          } else {
            toast.error(`otps don't match, Please try again`);
          }
        } else {
          toast.error(`Passwords don't match, Please enter again`);
        }
      } catch (error) {
        toast.error("Invalid OTP, Please enter again");
      }
    },
  });

  const handleResendOTP = async () => {
    try {
      const response = await resendOTP({ contactNumber: mobileNumber });
      if (response) {
        setOtpResent(true);
        setOtpValue("");
        setResendTimer(60);
        toast.success("New OTP sent successfully!");
      }
    } catch (error) {
      toast.error("Failed to resend OTP");
    }
  };

  return (
    <>
      <AuthPageWrapper>
        <div className="container">
          <Box sx={{ mb: { xs: 2, xl: 3 } }}>
            <Typography
              variant="subtitle2"
              sx={{ color: "rgba(47, 43, 61, 0.78)" }}
            >
              Please enter your OTP
            </Typography>
          </Box>
          <div className="otp-container">
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: { xs: 2, xl: 4 } }}>
                <OtpInput
                  value={otpValue}
                  onChange={setOtpValue}
                  numInputs={4}
                  inputType="number"
                  inputStyle={{
                    width: "40px",
                    height: "40px",
                    marginInline: "20px",
                    marginLeft: "0px",
                  }}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                />
              </Box>
              <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "rgba(47, 43, 61, 0.78)" }}
                >
                  Please Enter New Password :
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="outlined-userid-input"
                      type="password"
                      autoComplete="off"
                      {...formik.getFieldProps("newPassword")}
                      size="small"
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{ style: { height: "21px" } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {formik.touched.newPassword && formik.errors.newPassword ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.newPassword}
                      </Typography>
                    ) : null}

                    <Typography
                      variant="subtitle2"
                      sx={{ color: "rgba(47, 43, 61, 0.78)" }}
                    >
                      Confirm Password :
                    </Typography>
                    <TextField
                      id="outlined-userid-input"
                      type="text"
                      autoComplete="off"
                      {...formik.getFieldProps("confirmPassword")}
                      size="small"
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{ style: { height: "21px" } }}
                    />
                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.confirmPassword}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    height: 40,
                    fontSize: 14,
                    width: "100%",
                    mb: { xs: 2, xl: 3 },
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
              <Box>
                <Box mb={2}>
                  {otpResent && resendTimer > 0 ? (
                    <Typography
                      textAlign="center"
                      variant="body2"
                      color="#FF83E4"
                      mt={1.5}
                    >
                      Resend OTP in {Math.floor(resendTimer / 60)}:
                      {(resendTimer % 60).toString().padStart(2, "0")}
                    </Typography>
                  ) : (
                    <Box display="flex" justifyContent="center" mt={1.5}>
                      <Typography variant="body2">
                        Didn't get the otp?
                      </Typography>
                      <Typography
                        variant="body2"
                        color="#FF83E4"
                        mx={1}
                        sx={{ cursor: "pointer" }}
                        onClick={handleResendOTP}
                      >
                        Resend OTP
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </form>
          </div>
        </div>
      </AuthPageWrapper>
    </>
  );
};

export default ValidateOTP;
