import React from "react";
import {
  Box,
  Stack,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const ServerPagination = ({
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
  totalPages,
  prevPage,
  nextPage,
  data,
  totalSize,
}) => {
  // Function to handle page size change
  const handlePageSizeChange = (newPageSize) => {
    // Update the page size state
    setPageSize(newPageSize);
    // Set current page to 1 when page size changes
    setCurrentPage(1);
  };
  return (
    <>
      <Box display="flex" justifyContent="right" marginRight={2} marginTop={1}>
        <Stack direction="row" spacing={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" mr={0.5}>
              Rows per page:
            </Typography>
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}
              size="small"
            >
              {/* <MenuItem value={5}>5</MenuItem> */}
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>

            <IconButton
              size="small"
              color="primary"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography>
              {pageSize * currentPage - pageSize + 1} - {" "}
              {currentPage !== totalPages ? pageSize * currentPage : totalSize}{" "}
              of {totalSize}
            </Typography>
            <IconButton
              size="small"
              color="primary"
              onClick={nextPage}
              disabled={currentPage >= totalPages || data.length === 0}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ServerPagination;
