import axiosInstance from ".";

export const getStates = async () => {
    try {
        const response = await axiosInstance.get('/admin/state');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getCities = async (stateId) => {
    try {
        const response = await axiosInstance.get(`admin/city?stateId=${stateId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};