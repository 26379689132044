import React from 'react';
import { Popover, Box, Typography, Divider, Link, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Iconify from "../../components/iconify/Iconify";
import palette from '../../theme/palette';
import { IconFacebook, IconInstagram, IconYoutube } from '../../assets/icons';

const ContactInfoPopover = ({ id, open, anchorEl, handleClose, contactInfo, socialLinkFor }) => {
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            slotProps={{
                paper: {
                    sx: {
                        boxShadow: "0px 0px 5px 2px #ddd",
                    },
                }
            }}

        >
            <Box>
                <Box sx={{ width: 'auto' }}>
                    <Box sx={{ pl: 3, pr: 1, py: 0.5, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: 800 }}>{contactInfo?.name || contactInfo?.userName}</Typography>
                        <IconButton
                            sx={{}}
                            color={palette.grey[500]}
                            onClick={handleClose}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: "black" }} />
                    <Box sx={{ px: 3, py: 0.5, mt: 1 }}>
                        <Box sx={{ display: "flex", width: '330px', flexWrap: 'wrap', alignItems: "flex-start", mb: 0.5 }}>
                            <Typography variant='subtitle2'>Address:</Typography>
                            <Typography variant='body2' pl={1}> {contactInfo?.address ? contactInfo?.address : "NA"} </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                            <Typography variant='subtitle2'>Contact Number:</Typography>
                            <Typography variant='body2' pl={1}> {contactInfo?.contactNumber} </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                            <Typography variant='subtitle2'>Whatsapp Number:</Typography>
                            <Typography variant='body2' pl={1}> {contactInfo?.whatsappNumber} </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                            <Typography variant='subtitle2' color="#000"> Email: </Typography>
                            <Link href={`mailto:${contactInfo?.email}`} style={{ display: "flex", alignItems: "flex-start", mb: 0.5 }}>
                                <Typography variant='body2' pl={1} >{contactInfo?.email}</Typography>
                            </Link>
                        </Box>
                    </Box>

                    <Box sx={{ pl: 2, py: 0.5, my: 1, display: "flex", alignItems: "center" }}>
                        {socialLinkFor === "branch" ?
                            <>
                                <Link href={contactInfo?.fbUrl} target="_blank" rel="noopener">
                                    <IconButton>
                                        <img src={IconFacebook} />
                                    </IconButton>
                                </Link>
                                <Link href={contactInfo?.instagramUrl} target="_blank" rel="noopener">
                                    <IconButton>
                                        <img src={IconInstagram} />
                                    </IconButton>
                                </Link>
                                <Link href={contactInfo?.twitterUrl} target="_blank" rel="noopener">
                                    <IconButton>
                                        <Iconify
                                            height="4"
                                            icon={"devicon:twitter"}
                                        />
                                    </IconButton>
                                </Link>
                                <Link href="www.youtube.com" target="_blank" rel="noopener">
                                    <IconButton>
                                        <img src={IconYoutube} />
                                    </IconButton>
                                </Link>
                            </> : null}
                    </Box>
                </Box>
            </Box>
        </Popover>
    );
};

export default ContactInfoPopover;