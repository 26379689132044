import axiosInstance from ".";
import noAuthInstance from "./noAuthInstance";

// export const login = (adminCredentials) => {
//     return baseApiClient.post('/admin/auth/login', adminCredentials);
// };

export const login = async (adminCredentials) => {
    try {
        const response = await noAuthInstance.post('/admin/auth/login', adminCredentials);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const adminLogin = async (adminCredentials) => {
    try {
        const response = await noAuthInstance.post('/api/auth/login', adminCredentials);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const generateRefreshToken = async () => {
    try {
        const refreshToken = localStorage.getItem("refreshToken");
        const userType = JSON.parse(localStorage.getItem("userType"));
        const response = await noAuthInstance.get(`/${userType === 'super_admin' ? 'admin' : 'api'}/auth/getrefreshtoken`, {
            headers: {
                refreshToken: `uid ${refreshToken}`
            }
        });
        return response.data;
    } catch (error) {
        // console.log("access token error: ", error)
        throw error;
    }
}