import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    Container,
    Drawer,
    Grid,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import Iconify from "../../components/iconify/Iconify";
import palette from "../../theme/palette";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-hot-toast";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import deleteIcon from "../../assets/icons/trash.png";
import EditIcon from "../../assets/icons/EditIcon";
import ClearIcon from "@mui/icons-material/Clear";
import { addPlan, getAllPlans, updatePlan } from '../../api/plan';

import * as Yup from "yup";
import { useFormik } from "formik";
import { useIsSmallScreen } from '../../hooks/useResponsive';

const planValidationSchema = Yup.object().shape({
    planName: Yup.string().required("Name is required"),
    planDescription: Yup.string().optional("plan description"),
    planPerRoomPrice: Yup.number().required("per room price required"),
    planIsActive: Yup.boolean().required("Active is required"),
});


const Plan = () => {
    const isSmallScreen = useIsSmallScreen();
    const [plans, setPlans] = useState([]);
    const [editedPlan, setEditedPlan] = useState(null);
    // delete modal
    const [openDeleteDialog, setOpenDeleteDialog] = useState({
        status: false,
        deleteId: null,
    });

    // modal mode
    const [modalMode, setModalMode] = useState("add");

    // modal
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalClose = () => setModalOpen(false);

    const handleModalOpen = (planData) => {
        setModalOpen(true);
        if (planData.name) {
            setModalMode("edit");
            setEditedPlan(planData);
            formik.setValues({
                planName: planData.name,
                planDescription: planData.description,
                planPerRoomPrice: planData.planFeature?.perRoomPrice,
                planIsActive: planData.isActive,
            });
        } else {
            setModalMode("add");
            formik.resetForm();
        }
    };

    // get all plan Data
    const getAllPlanData = async () => {
        try {
            const response = await getAllPlans();
            setPlans(response);
        } catch (error) {
            console.log("getall plans error", error);
        }
    }

    // plan column
    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1.5,
            filterable: false,
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: "description",
            headerName: "Description",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            flex: 1.5,
        },
        {
            field: "planFeature",
            headerName: "Room Price",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            flex: 1,
            renderCell: ({ row }) => (
                <span>{row?.planFeature?.perRoomPrice ? row?.planFeature?.perRoomPrice : "NA"}</span>

            ),
        },
        {
            field: "isActive",
            headerName: "isActive",
            // width: 200,
            flex: 0.5,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
        },
        {
            field: "actions",
            headerName: "Actions",
            with: 120,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => {
                const handleDeleteDialog = (id) => {
                    setOpenDeleteDialog({
                        ...openDeleteDialog,
                        status: true,
                        deleteId: id,
                    });
                };
                const handleDeleteClose = () => {
                    setOpenDeleteDialog({ ...openDeleteDialog, status: false });
                };
                const handleConfirmDelete = () => {
                    // deletePlanData();
                    handleDeleteClose();
                };
                return (
                    <Stack direction="row" spacing={0}>
                        {/* Edit Icon */}
                        <Tooltip title="Edit">
                            <IconButton
                                size="medium"
                                color="primary"
                                onClick={() => handleModalOpen(row)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>

                        {/* Delete Icon */}
                        <Tooltip title="Delete">
                            <IconButton
                                size="medium"
                                color="error"
                            // onClick={() => handleDeleteDialog(row.id)}
                            >
                                <img src={deleteIcon} />
                            </IconButton>
                        </Tooltip>
                        {/* Confirmation Modal */}
                        <ConfirmationModal
                            open={openDeleteDialog.status}
                            onClose={handleDeleteClose}
                            onConfirm={handleConfirmDelete}
                            iconName="plan"
                        />
                    </Stack>
                );
            },
        },
    ];

    // edit plan
    const editPlanData = async (planDetails) => {
        try {
            // Check if the planPerRoomPrice has been modified
            const priceChanged = editedPlan.planFeature.perRoomPrice !== planDetails.perRoomPrice;
            const newPlanDetails = priceChanged ? { ...planDetails, planFeatureId: editedPlan.planFeature.id } : (({ perRoomPrice, ...rest }) => rest)(planDetails);
            const response = await updatePlan(editedPlan.id, newPlanDetails);
            if (response) {
                setPlans((prevPlans) =>
                    prevPlans.map((plan) =>
                        plan.id === editedPlan.id
                            ? {
                                ...plan,
                                // id: editedPlan.id,
                                name: newPlanDetails.name,
                                description: newPlanDetails.description,
                                isActive: newPlanDetails.isActive,
                                planFeature: priceChanged
                                    ? { ...plan.planFeature, perRoomPrice: newPlanDetails.perRoomPrice }
                                    : plan.planFeature
                            }
                            : plan,
                    )
                );
                toast.success("Plan updated successfully");
                handleModalClose();
            }
            // console.log("plan details", newPlanDetails);
        } catch (error) {
            toast.error(error.response?.data?.message?.message[0]);
            // console.log("edit plan error", error);
        }
    };

    // create plan
    const formik = useFormik({
        initialValues: {
            planName: "",
            planDescription: "",
            planPerRoomPrice: null,
            planIsActive: true,
        },
        validationSchema: planValidationSchema,
        onSubmit: async (values) => {
            const planDetails = {
                name: values.planName,
                description: values.planDescription,
                perRoomPrice: values.planPerRoomPrice,
                isActive: values.planIsActive,
            };
            try {
                if (modalMode === "add") {
                    const response = await addPlan(planDetails);
                    response && toast.success("Plan added successfully");
                    getAllPlanData();
                    handleModalClose();
                } else if (modalMode === "edit") {
                    editPlanData(planDetails);
                }
            } catch (error) {
                toast.error(error.response?.data?.message?.message[0]);
                // console.error("create plan Error:", error);
            }
        },
    });

    useEffect(() => {
        getAllPlanData();
    }, []);

    return (
        <>
            <Container
                sx={{
                    paddingX: "8px !important",
                    marginX: "auto !important",
                    maxWidth: "100% !important",
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={3}
                    sx={{ border: "", ...(isSmallScreen && { marginTop: 2 }) }}
                >
                    <Typography variant="h5" gutterBottom color={palette.primary.light}>
                        Billing Plan
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={handleModalOpen}
                    >
                        New Plan
                    </Button>
                </Stack>
                <Card sx={{ mt: 3, overflowX: "auto" }}>
                    <DataGrid
                        rows={plans}
                        columns={columns}
                        rowHeight={55}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10},
                            },
                        }}
                        pageSizeOptions={[5, 10, 50]}
                        disableRowSelectionOnClick
                        autoHeight
                        sx={{
                            "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                                outline: "none",
                            },
                            "& .MuiDataGrid-row": {
                                position: "relative",
                            },
                            // header-bottom
                            "& .MuiDataGrid-row:first-child::before": {
                                content: "''",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "0.5px",
                                backgroundColor: "#0000004D",
                            },
                            // each-row-bottom
                            "& .MuiDataGrid-row::after": {
                                content: "''",
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                width: "100%",
                                height: "0.5px",
                                backgroundColor: "#0000004D",
                            },
                            // footer-bottom
                            "& .MuiDataGrid-row:last-child::after": {
                                content: "''",
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                width: "100%",
                                height: "0.5px",
                                backgroundColor: "#0000004D",
                            },
                            minWidth: "678px",
                            width: "100%",
                            overflowX: "auto",
                        }}
                        localeText={{ noRowsLabel: "No plan found" }}
                    />
                </Card>

                <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
                    <Container maxWidth="sm">
                        <Box
                            sx={{
                                bgcolor: "background.paper",
                                width: 400,
                                marginY: 2,
                                ...(isSmallScreen && { width: 280 })
                            }}
                        >
                            <IconButton
                                sx={{ position: "absolute", top: 5, right: 5 }}
                                color={palette.grey[500]}
                                onClick={handleModalClose}
                            >
                                <ClearIcon />
                            </IconButton>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: 0,
                                }}
                            >
                                <Typography variant="h6" gutterBottom mb={3}>
                                    {modalMode === "add"
                                        ? `Add Plan`
                                        : `Edit Plan`}
                                </Typography>
                            </Box>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Name *"
                                            name="planName"
                                            type="text"
                                            {...formik.getFieldProps("planName")}
                                        />
                                        {formik.touched.planName && formik.errors.planName ? (
                                            <Typography
                                                sx={{
                                                    color: "red",
                                                    variant: "body2",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {formik.errors.planName}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Description"
                                            name="planDescription"
                                            multiline
                                            rows={2}
                                            {...formik.getFieldProps("planDescription")}
                                        />
                                        {formik.touched.planDescription &&
                                            formik.errors.planDescription ? (
                                            <Typography
                                                sx={{
                                                    color: "red",
                                                    variant: "body2",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {formik.errors.planDescription}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Room Price *"
                                            name="planPerRoomPrice"
                                            type='number'
                                            {...formik.getFieldProps("planPerRoomPrice")}
                                        />
                                        {formik.touched.planPerRoomPrice &&
                                            formik.errors.planPerRoomPrice ? (
                                            <Typography
                                                sx={{
                                                    color: "red",
                                                    variant: "body2",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {formik.errors.planPerRoomPrice}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="IsActive"
                                            name="planIsActive"
                                            select
                                            value={formik.values.planIsActive}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </TextField>
                                        {formik.touched.planIsActive &&
                                            formik.errors.planIsActive ? (
                                            <Typography
                                                sx={{
                                                    color: "red",
                                                    variant: "body2",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {formik.errors.planIsActive}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                </Grid>
                                <Box
                                    mt={2}
                                    sx={{ display: "flex", justifyContent: "flex-end" }}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    // disabled={!formik.dirty}
                                    >
                                        {modalMode === "add" ? "Add" : "Update"}
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            backgroundColor: palette.grey[500],
                                            "&:hover": {
                                                backgroundColor: palette.grey[600],
                                            },
                                            marginLeft: 1,
                                        }}
                                        onClick={() => formik.resetForm()}
                                    >
                                        clear
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Container>
                </Drawer>
            </Container>
        </>
    )
}

export default Plan;