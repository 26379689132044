import React, { useEffect, useState } from "react";
import palette from "../../theme/palette";
import deleteIcon from "../../assets/icons/trash.png";
import EditIcon from "../../assets/icons/EditIcon";

// mui
import {
  Card,
  Stack,
  Avatar,
  Button,
  Container,
  Typography,
  IconButton,
  TablePagination,
  Box,
  Link,
  Tooltip,
  TextField,
  Grid,
  InputAdornment,
  Input,
  Drawer,
  Modal,
  Autocomplete,
  Select,
  MenuItem,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Iconify from "../../components/iconify/Iconify";
import { toast } from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";

// apis
import {
  addClient,
  checkSubdomainAvailable,
  deleteClient,
  getAllClients,
  updateClient,
} from "../../api/client";
import { getCities, getStates } from "../../api/statecity";

import * as Yup from "yup";
import { useFormik } from "formik";
import { DataGrid } from "@mui/x-data-grid";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import Loader from "../../components/Loader";
import { useClient } from "../../context/ClientProvider";
import { IconCross, IconPlus } from "../../assets/icons";
import ContactInfoPopover from "../../components/common/ContactInfoPopover";
import { getAllPlans } from "../../api/plan";
import ServerPagination from "../../components/ServerPagination";
import { Clear } from "@mui/icons-material";
import { useIsSmallScreen } from "../../hooks/useResponsive";

const createClientValidationSchema = Yup.object().shape({
  clientName: Yup.string().required("Client Name is required"),
  clientAddress: Yup.string().required("Client Address is required"),
  clientDescription: Yup.string().optional("Client Description"),
  clientContactNumber: Yup.string()
    .required("Number is required")
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
  clientWhatsapp: Yup.string()
    .optional("Whatsapp number is required")
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
  clientEmail: Yup.string()
    .email("Invalid email format")
    .required("Client Email id is required"),
  clientStateId: Yup.object().required("State is required"),
  clientCityId: Yup.object().required("City is required"),
  selectedPlan: Yup.object().required("Plan is required"),
});

const Client = () => {
  const isSmallScreen = useIsSmallScreen();
  const { globalClientList, setGlobalClientList } = useClient();
  const [client, setClient] = useState([]);
  const [clientImage, setClientImage] = useState(null);
  const [subdomain, setSubdomain] = useState("");
  const [isSubdomainAvailable, setIsSubdomainAvailable] = useState(null);
  const [searchClientValue, setSearchClientValue] = useState(null);
  const [searchedValue, setSearchedValue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editedClient, setEditedClient] = useState(null);
  const [modalMode, setModalMode] = useState("add");
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    status: false,
    deleteId: [],
  });
  const [highlightedRowId, setHighlightedRowId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [contactInfo, setContactInfo] = useState(null);
  const [rowClicked, setRowClicked] = useState(null);

  const [plans, setPlans] = useState(null);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalSize, setTotalSize] = useState(1);

  // state & city
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  // Function to handle image upload
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // You may want to validate the file type and size here
      setClientImage(file);
    }
  };

  // Function to reset image selection
  const handleClearImage = () => {
    setClientImage(null);
  };

  const showContacts = (event, row) => {
    setAnchorEl(event.currentTarget);
    setContactInfo(row);
    setRowClicked(row.id);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setRowClicked(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverProps = {
    id: openPopover ? "simple-popover" : undefined,
    open: openPopover,
    anchorEl: anchorEl,
    handleClose: handleClosePopover,
    contactInfo: contactInfo,
  };

  const highlightRow = (rowId) => {
    setHighlightedRowId(rowId);
    setTimeout(() => {
      setHighlightedRowId(null);
    }, 2000);
  };

  // open modal
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = (clientData) => {
    setModalOpen(true);
    if (clientData.name) {
      setModalMode("edit");
      setEditedClient(clientData);
      formik.setValues({
        clientName: clientData.name,
        clientDescription: clientData.description,
        clientContactNumber: clientData.contactNumber,
        clientAddress: clientData.address,
        clientWhatsapp: clientData.whatsappNumber,
        clientEmail: clientData.email,
        clientStateId: clientData?.city?.state,
        clientCityId: clientData?.city,
        clientFile: clientData.clientFile,
        selectedPlan: clientData.plan,
      });
    } else {
      setModalMode("add");
      formik.resetForm();
    }
  };

  const handleModalClose = () => setModalOpen(false);

  // get all plan Data
  const getAllPlanData = async () => {
    try {
      const response = await getAllPlans();
      setPlans(response);
    } catch (error) {
      console.log("getall plans error on client", error);
    }
  };

  // getClientData
  const clientData = async () => {
    setLoading(true);
    try {
      const response = await getAllClients({
        currentPage: currentPage,
        pageSize: pageSize,
        search: searchedValue ? searchedValue : null,
      });
      if (response) {
        const { data, totalPage, totalSize } = response;
        setClient(data);
        setTotalPages(totalPage);
        setTotalSize(totalSize);
      }
    } catch (error) {
      console.error("get all clients Error:", error);
    } finally {
      setLoading(false);
    }
  };

  // editClientData
  const editClientData = async (clientDetails) => {
    const updatedClientDetails = {};
    if (clientDetails.name !== editedClient.name) {
      updatedClientDetails.name = clientDetails.name;
    }
    if (clientDetails.description !== editedClient.description) {
      updatedClientDetails.description = clientDetails.description;
    }
    if (clientDetails.contactNumber !== editedClient.contactNumber) {
      updatedClientDetails.contactNumber = clientDetails.contactNumber;
    }
    if (clientDetails.address !== editedClient.address) {
      updatedClientDetails.address = clientDetails.address;
    }
    if (clientDetails.whatsappNumber !== editedClient.whatsappNumber) {
      updatedClientDetails.whatsappNumber = clientDetails.whatsappNumber;
    }
    if (clientDetails.email !== editedClient.email) {
      updatedClientDetails.email = clientDetails.email;
    }
    if (clientDetails.cityId !== editedClient.cityId) {
      updatedClientDetails.cityId = clientDetails.cityId;
    }
    if (clientDetails.planId !== editedClient.planId) {
      updatedClientDetails.planId = clientDetails.planId;
    }
    if (clientImage) {
      updatedClientDetails.file = clientImage;
    }
    try {
      const isNotingToUpdate = Object.keys(updatedClientDetails).length === 0;
      const response =
        !isNotingToUpdate &&
        (await updateClient(editedClient.id, updatedClientDetails));
      if (response) {
        // setClient((prevClient) =>
        //   prevClient.map((client) =>
        //     client.id === editedClient.id
        //       ? {
        //         ...client,
        //         // id: editedClient.id,
        //         // name: clientDetails.name,
        //         // description: clientDetails.description,
        //         // contactNumber: clientDetails.contactNumber,
        //         // address: clientDetails.address,
        //         // whatsappNumber: clientDetails.whatsappNumber,
        //         // file: clientImage,
        //         // emai: clientDetails.email,
        //         // plan: plans.find(item => item.id === clientDetails.planId),
        //         ...clientDetails,
        //         file: clientImage,
        //         plan: plans.find(item => item.id === clientDetails.planId),
        //       }
        //       : client
        //   )
        // );

        setGlobalClientList((prevClient) =>
          prevClient.map((client) =>
            client.id === editedClient.id
              ? {
                  ...client,
                  id: editedClient.id,
                  name: clientDetails.name,
                }
              : client
          )
        );
        // setClient(response);
        setLoading(false);
        handleModalClose();
        toast.success("Client updated successfully");
        clientData();
        highlightRow(editedClient.id);
        handleClearImage();
      }
    } catch (error) {
      if (error.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message?.message[0]);
      } else if (error.response?.data?.statusCode === 409) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error(error.response?.data?.message);
      }
    }
  };

  // deleteClientData
  const deleteClientData = async () => {
    const { deleteId } = openDeleteDialog;
    try {
      const updatedClientValue = client.filter((val) => val.id !== deleteId);
      const updatedGlobalClientValue = globalClientList.filter(
        (val) => val.id !== deleteId
      );
      const response = await deleteClient(deleteId);
      if (response) {
        setClient(updatedClientValue);
        setGlobalClientList(updatedGlobalClientValue);
        toast.success("Client deleted successfully");
      }
    } catch (error) {
      toast.error(error.response?.data?.message[0]);
    }
  };

  // searchClientData
  const handleClientSearch = () => {
    setSearchedValue(searchClientValue);
  };

  const handleClearSearch = () => {
    setSearchClientValue("");
    setSearchedValue(null);
  };

  // create client
  const formik = useFormik({
    initialValues: {
      clientName: "",
      clientDescription: "",
      clientContactNumber: "",
      clientAddress: "",
      clientWhatsapp: "",
      clientEmail: null,
      clientStateId: null,
      clientCityId: null,
      clientFile: undefined,
      selectedPlan: null,
    },
    validationSchema: createClientValidationSchema,

    onSubmit: async (values) => {
      const clientDetails = {
        name: values.clientName,
        description: values.clientDescription,
        contactNumber: values.clientContactNumber,
        address: values.clientAddress,
        whatsappNumber: values.clientWhatsapp,
        email: values.clientEmail ? values.clientEmail : null,
        cityId: values.clientCityId?.id,
        subdomain: subdomain,
        planId: values.selectedPlan.id,
      };
      if (values.clientFile) {
        clientDetails.file = values.clientFile;
      }
      try {
        if (modalMode === "add") {
          clientDetails.file = clientImage;
          console.log("new cient data :", clientDetails);
          const response = await addClient(clientDetails);
          if (response) {
            setGlobalClientList((prevClientList) => [
              ...prevClientList,
              {
                id: response.id,
                name: response.name,
              },
            ]);

            setClient((prevClientList) => [response, ...prevClientList]);

            toast.success("Client added successfully");
            setSubdomain("");

            formik.resetForm();
            handleModalClose();
            handleClearImage();
          }

          // clientData();
        } else if (modalMode === "edit") {
          editClientData(clientDetails);
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
      }
    },
  });

  // Function to generate subdomain from client name
  const generateSubdomain = (name) => {
    // Replace spaces with hyphens and make lowercase
    return name.trim().toLowerCase().replace(/\s+/g, "");
  };

  // Function to check subdomain availability
  const checkSubdomainAvailability = async (subdomain) => {
    try {
      const response = await checkSubdomainAvailable({ subdomain });
      // console.log("first response:", response)
      return response;
    } catch (error) {
      console.error("Error checking subdomain availability:", error);
      return false;
    }
  };

  useEffect(() => {
    const generateAndCheckSubdomain = async () => {
      const generatedSubdomain = generateSubdomain(formik.values.clientName);
      setSubdomain(generatedSubdomain);
      if (modalMode === "add" && generatedSubdomain.length >= 3) {
        const isAvailable = await checkSubdomainAvailability(
          generatedSubdomain
        );
        isAvailable?.length === 0
          ? setIsSubdomainAvailable("available")
          : setIsSubdomainAvailable("not-available");
        // console.log("isAvailable: ", isAvailable)
      }
    };

    generateAndCheckSubdomain();
  }, [formik.values.clientName]);

  useEffect(() => {
    clientData();
  }, [currentPage, pageSize, searchedValue]);

  const getAllClientList = async () => {
    const response = await getAllClients({
      allList: true,
    });
    if (response) {
      localStorage.setItem("globalClientList", JSON.stringify(response));
      setGlobalClientList(response);
    }
  };

  useEffect(() => {
    getAllPlanData();
    getAllClientList();
  }, []);

  /* useEffect(() => {
    activeGlobalClient && branchData();
  }, [activeGlobalClient]); */

  // fetch state
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await getStates();
        setStates(response);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, []);

  // Fetch cities based on selected state
  const fetchCities = async (stateId) => {
    try {
      const response = await getCities(stateId);
      setCities(response);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const columns = [
    {
      field: "image",
      headerName: "",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                alt="Client Img"
                src={`${process.env.REACT_APP_API_BASE_IMAGE_URL}/client/${row.imageName}`}
                sx={{ width: 30, height: 30 }}
              />
              <Tooltip title="Contact Info">
                <IconButton
                  //className="iconPlus"
                  aria-describedby={popoverProps.id}
                  variant="contained"
                  onClick={(e) => showContacts(e, row)}
                >
                  <img
                    height={18}
                    src={row.id === rowClicked ? IconCross : IconPlus}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "plan",
      headerName: "Plan",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <span>{row?.plan?.name ? row?.plan?.name : "NA"}</span>
      ),
    },
    {
      field: "uid",
      headerName: "UID",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "subdomain",
      headerName: "Subdomain",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const handleDeleteDialog = (id) => {
          setOpenDeleteDialog({
            ...openDeleteDialog,
            status: true,
            deleteId: id,
          });
        };

        const handleDeleteClose = () => {
          setOpenDeleteDialog({ ...openDeleteDialog, status: false });
        };
        const handleConfirmDelete = () => {
          deleteClientData();
          handleDeleteClose();
        };
        return (
          <Stack direction="row" spacing={0}>
            {/* Edit Icon */}
            <Tooltip title="Edit">
              <IconButton
                size="medium"
                color="primary"
                onClick={() => handleModalOpen(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            {/* Delete Icon */}
            <Tooltip title="Delete">
              <IconButton
                size="medium"
                color="error"
                onClick={() => handleDeleteDialog(row.id)}
              >
                <img height="22px" src={deleteIcon} />
              </IconButton>
            </Tooltip>

            <ConfirmationModal
              open={openDeleteDialog.status}
              onClose={handleDeleteClose}
              onConfirm={handleConfirmDelete}
              iconName="client"
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
          position: "relative",
          minHeight: "500px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          sx={{ border: "", ...(isSmallScreen && { marginTop: 2 }) }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            Client
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleModalOpen}
          >
            New Client
          </Button>
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Card
              sx={{
                height: "auto",
                width: "100%",
                "& .row": {},
              }}
            >
              {/* <TextField
                label="Search Client"
                variant="outlined"
                size="small"
                value={searchClientValue}
                onChange={(e) => {setSearchClientValue(e.target.value);}}
                onClear={() => {setSearchClientValue("");}}
                sx={{ ml: 1, mt: 1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClientSearch()}
                        edge="end"
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}
              <TextField
                label="Search Client"
                variant="outlined"
                size="small"
                value={searchClientValue}
                onChange={(e) => setSearchClientValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleClientSearch();
                  }
                }}
                sx={{ ml: 1, mt: 1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchClientValue ? (
                        <IconButton
                          onClick={handleClearSearch}
                          edge="end"
                          aria-label="clear-search"
                        >
                          <Clear />
                        </IconButton>
                      ) : null}
                      <IconButton
                        onClick={handleClientSearch}
                        edge="end"
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div style={{ overflowX: "auto" }}>
                <DataGrid
                  rows={client ? client : []}
                  columns={columns}
                  rowHeight={55}
                  localeText={{ noRowsLabel: "No Client found" }}
                  disableRowSelectionOnClick
                  autoHeight={true}
                  getRowClassName={(params) =>
                    params.id === highlightedRowId ? "highlighted-row" : ""
                  }
                  hideFooterPagination
                  hideFooter
                  sx={{
                    "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus":
                      {
                        outline: "none",
                      },
                    "& .MuiDataGrid-row": {
                      position: "relative",
                    },
                    // header-bottom
                    "& .MuiDataGrid-row:first-child::before": {
                      content: "''",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "0.5px",
                      backgroundColor: "#0000004D",
                    },
                    // each-row-bottom
                    "& .MuiDataGrid-row::after": {
                      content: "''",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "0.5px",
                      backgroundColor: "#0000004D",
                    },
                    // footer-bottom
                    "& .MuiDataGrid-row:last-child::after": {
                      content: "''",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "0.5px",
                      backgroundColor: "#0000004D",
                    },
                    minWidth: "678px",
                    width: "100%",
                    overflowX: "auto",
                    height: client.length === 0 ? "500px" : "auto",
                  }}
                />
              </div>
            </Card>
            {/* Server Pagination */}
            <ServerPagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              prevPage={prevPage}
              nextPage={nextPage}
              data={client}
              totalSize={totalSize}
            />
          </>
        )}

        <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "background.paper",
                marginY: 2,
                width: 400,
                ...(isSmallScreen && { width: 280 }),
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Typography variant="h6" gutterBottom mb={0}>
                  {modalMode === "add" ? "Add Client" : "Edit Client"}
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ textAlign: "center" }}>
                  <Avatar
                    alt="Client Avatar"
                    // src={
                    //   formik.values.clientFile
                    //     ? URL.createObjectURL(formik.values.clientFile)
                    //     : modalMode === "edit" &&
                    //       editedClient &&
                    //       editedClient.imageName
                    //       ? `${process.env.REACT_APP_API_BASE_IMAGE_URL}/client/${editedClient.imageName}`
                    //       : "/default-avatar.png"
                    // }
                    src={
                      clientImage
                        ? URL.createObjectURL(clientImage)
                        : modalMode === "edit" &&
                          editedClient &&
                          editedClient.imageName
                        ? `${process.env.REACT_APP_API_BASE_IMAGE_URL}/client/${editedClient.imageName}`
                        : "/default-avatar.png"
                    }
                    sx={{
                      width: 70,
                      height: 70,
                      borderRadius: "50%",
                      mx: "auto",
                      mb: 2,
                    }}
                    onClick={() => {
                      // Trigger the hidden file input when the avatar is clicked
                      document.getElementById("clientFile").click();
                    }}
                  />
                  <Input
                    type="file"
                    accept="image/*"
                    id="clientFile"
                    name="clientFile"
                    style={{ display: "none" }}
                    // onChange={(event) => {
                    //   formik.setFieldValue(
                    //     "clientFile",
                    //     event.currentTarget.files[0]
                    //   );
                    // }}
                    onChange={handleImageChange}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Name *"
                      name="name"
                      type="text"
                      {...formik.getFieldProps("clientName")}
                    />
                    {formik.touched.clientName && formik.errors.clientName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.clientName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Subdomain"
                      name="subdomain"
                      type="text"
                      value={subdomain}
                      // onChange={(e) => setSubdomain(e.target.value)}
                    />
                    {subdomain.length >= 3 && isSubdomainAvailable && (
                      <Typography
                        sx={{
                          color:
                            isSubdomainAvailable === "available"
                              ? "green"
                              : "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {isSubdomainAvailable === "available"
                          ? "Subdomain Available"
                          : "Subdomain Not Available"}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Address *"
                      name="address"
                      multiline
                      rows={2}
                      {...formik.getFieldProps("clientAddress")}
                    />
                    {formik.touched.clientAddress &&
                    formik.errors.clientAddress ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.clientAddress}
                      </Typography>
                    ) : null}
                  </Grid>
                  {/* state */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={states ? states : []}
                      disableClearable
                      getOptionLabel={(option) => option?.name || ""}
                      onChange={(event, state) => {
                        formik.setFieldValue("clientStateId", state);
                        formik.setFieldValue("clientCityId", null);
                        fetchCities(state.id); // Fetch cities on state selection
                      }}
                      value={formik.values.clientStateId}
                      isOptionEqualToValue={(option, value) =>
                        option?.name === value
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="State *" />
                      )}
                    />
                    {formik.touched.clientStateId &&
                      formik.errors.clientStateId && (
                        <Typography
                          sx={{
                            color: "red",
                            variant: "body2",
                            fontSize: "12px",
                          }}
                        >
                          {formik.errors.clientStateId}
                        </Typography>
                      )}
                  </Grid>
                  {/* city */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={cities ? cities : []}
                      disableClearable
                      getOptionLabel={(option) => option?.name || ""}
                      onChange={(event, city) => {
                        formik.setFieldValue("clientCityId", city);
                      }}
                      value={formik.values.clientCityId}
                      isOptionEqualToValue={(option, value) =>
                        option?.name === value
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="City *" />
                      )}
                    />
                    {formik.touched.clientCityId &&
                      formik.errors.clientCityId && (
                        <Typography
                          sx={{
                            color: "red",
                            variant: "body2",
                            fontSize: "12px",
                          }}
                        >
                          {formik.errors.clientCityId}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      multiline
                      rows={3}
                      {...formik.getFieldProps("clientDescription")}
                    />
                    {formik.touched.clientDescription &&
                    formik.errors.clientDescription ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.clientDescription}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Contact Number *"
                      name="contactNumber"
                      {...formik.getFieldProps("clientContactNumber")}
                    />
                    {formik.touched.clientContactNumber &&
                    formik.errors.clientContactNumber ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.clientContactNumber}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Whatsapp Number"
                      name="whatsAppNumber"
                      {...formik.getFieldProps("clientWhatsapp")}
                    />
                    {formik.touched.clientWhatsapp &&
                    formik.errors.clientWhatsapp ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.clientWhatsapp}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Email *"
                      name="email"
                      type="text"
                      {...formik.getFieldProps("clientEmail")}
                    />
                    {formik.touched.clientEmail && formik.errors.clientEmail ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.clientEmail}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      options={plans}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, planValue) => {
                        formik.setFieldValue("selectedPlan", planValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Plan *" />
                      )}
                      renderOption={(props, option) => (
                        <div {...props}>{option.name}</div>
                      )}
                      value={formik.values.selectedPlan}
                    />
                    {formik.touched.selectedPlan &&
                    formik.errors.selectedPlan ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.selectedPlan}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      isSubdomainAvailable === "not-available" ? true : false
                    }
                  >
                    {modalMode === "add" ? "Add" : "Update"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: palette.grey[500],
                      "&:hover": {
                        backgroundColor: palette.grey[600],
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>

        <ContactInfoPopover {...popoverProps} />
      </Container>
    </>
  );
};

export default Client;
