import { Box, Typography } from '@mui/material'
import React from 'react'

const NotFound = () => {
  return (
    <Box>
      <Typography sx={{textAlign: 'center', pt: 20}} variant='h3'>
        404 Page Not Found
      </Typography>
    </Box>
  )
}

export default NotFound