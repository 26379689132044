import axios from "axios";

const APP_API_BASE_URL = `${window.location.origin}/bapi`;

const noAuthInstance = axios.create({
    baseURL: APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

export default noAuthInstance;