import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Iconify from "../../components/iconify/Iconify";
import ClearIcon from "@mui/icons-material/Clear";
import { Toaster, toast } from "react-hot-toast";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import deleteIcon from "../../assets/icons/trash.png";
import EditIcon from "../../assets/icons/EditIcon";

import * as Yup from "yup";
import { useFormik } from "formik";

// apis
import {
  addFloor,
  deleteFloor,
  getFloorByBranch,
  updateFloor,
} from "../../api/floor";
import palette from "../../theme/palette";
import SearchInput from "../../components/SearchInput";
import { useClient } from "../../context/ClientProvider";
import { useIsSmallScreen } from "../../hooks/useResponsive";

const floorValidationSchema = Yup.object().shape({
  floorName: Yup.string().required("Name is required"),
  floorDescription: Yup.string().optional("Floor description"),
  floorIsActive: Yup.boolean().required("Active is required"),
});

const Floor = () => {
  const isSmallScreen = useIsSmallScreen();
  const [floors, setFloors] = useState([]);
  const [searchFloorValue, setSearchFloorValue] = useState("");
  const [editedFloor, setEditedFloor] = useState(null);
  const { activeGlobalBranch } = useClient();

  // delete modal
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    status: false,
    deleteId: null,
  });

  // modal mode
  const [modalMode, setModalMode] = useState("add");

  // modal
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = (floorData) => {
    if (activeGlobalBranch) {
      setModalOpen(true);
      if (floorData.name) {
        setModalMode("edit");
        setEditedFloor(floorData);
        formik.setValues({
          floorName: floorData.name,
          floorDescription: floorData.description,
          floorIsActive: floorData.isActive,
        });
      } else {
        setModalMode("add");
        formik.resetForm();
      }
    } else {
      toast.error("Select Client and Branch");
    }
  };

  // get floor by branchId
  const floorData = async () => {
    try {
      const response = await getFloorByBranch(activeGlobalBranch?.id);
      setFloors(response);
    } catch (error) {
      console.log("get branch by client error", error);
    }
  };

  // editFloorData
  const editFloorData = async (floorDetails) => {
    if (
      floors.find((floor) => floor.name === floorDetails.name) &&
      editedFloor.name !== floorDetails.name
    ) {
      toast.error("Floor name already exists");
    } else {
      try {
        const response = await updateFloor(editedFloor.id, floorDetails);
        if (response) {
          setFloors((prevFloors) =>
            prevFloors.map((floor) =>
              floor.id === editedFloor.id
                ? {
                    ...floors,
                    id: editedFloor.id,
                    name: floorDetails.name,
                    description: floorDetails.description,
                    isActive: floorDetails.isActive,
                  }
                : floor
            )
          );
          toast.success("Floor updated successfully");
          handleModalClose();
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
      }
    }
  };

  // deleteFloorData
  const deleteFloorData = async () => {
    const { deleteId } = openDeleteDialog;
    try {
      const updatedFloorValue = floors.filter((floor) => floor.id !== deleteId);
      const response = await deleteFloor(deleteId);
      if (response) {
        toast.success("Floor deleted successfully");
        setFloors(updatedFloorValue);
      }
    } catch (error) {
      toast.error("Error on deleting floor")
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: "isActive",
      headerName: "isActive",
      // width: 200,
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      with: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const handleDeleteDialog = (id) => {
          setOpenDeleteDialog({
            ...openDeleteDialog,
            status: true,
            deleteId: id,
          });
        };
        const handleDeleteClose = () => {
          setOpenDeleteDialog({ ...openDeleteDialog, status: false });
        };
        const handleConfirmDelete = () => {
          deleteFloorData();
          handleDeleteClose();
        };
        return (
          <Stack direction="row" spacing={0}>
            {/* Edit Icon */}
            <Tooltip title="Edit">
              <IconButton
                size="medium"
                color="primary"
                onClick={() => handleModalOpen(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            {/* Delete Icon */}
            <Tooltip title="Delete">
              <IconButton
                size="medium"
                color="error"
                onClick={() => handleDeleteDialog(row.id)}
              >
                <img src={deleteIcon} />
              </IconButton>
            </Tooltip>
            {/* Confirmation Modal */}
            <ConfirmationModal
              open={openDeleteDialog.status}
              onClose={handleDeleteClose}
              onConfirm={handleConfirmDelete}
              iconName="floor"
            />
          </Stack>
        );
      },
    },
  ];

  // create floor
  const formik = useFormik({
    initialValues: {
      floorName: "",
      floorDescription: "",
      floorIsActive: true,
    },
    validationSchema: floorValidationSchema,
    onSubmit: async (values) => {
      const floorDetails = {
        name: values.floorName,
        description: values.floorDescription,
        isActive: values.floorIsActive,
        branchId: activeGlobalBranch.id,
      };
      try {
        if (modalMode === "add") {
          if (floors.find((floor) => floor.name === floorDetails.name)) {
            toast.error("Floor name already exists");
          } else {
            const response = await addFloor(floorDetails);
            response && toast.success("Floor added successfully");
            floorData();
            handleModalClose();
          }
        } else if (modalMode === "edit") {
          editFloorData(floorDetails);
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
        // console.error("create floor Error:", error);
      }
    },
  });

  useEffect(() => {
    activeGlobalBranch && floorData();
  }, [activeGlobalBranch]);

  return (
    <>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          sx={{ border: "", ...(isSmallScreen && { marginTop: 2 }) }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            Floor
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleModalOpen}
          >
            New Floor
          </Button>
        </Stack>
        <Card sx={{ mt: 3, overflowX: "auto" }}>
          {/* search */}
          <SearchInput
            sx={{ ml: 1, mt: 1 }}
            label="floor"
            value={searchFloorValue}
            onChange={(e) => {
              setSearchFloorValue(e.target.value);
            }}
          />
          <DataGrid
            rows={floors?.filter((floor) =>
              Object.values(floor).some(
                (value) =>
                  value !== null &&
                  value
                    ?.toString()
                    .toLowerCase()
                    .includes(searchFloorValue.toLowerCase())
              )
            )}
            columns={columns}
            rowHeight={55}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 50]}
            disableRowSelectionOnClick
            autoHeight
            sx={{
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row": {
                position: "relative",
              },
              // header-bottom
              "& .MuiDataGrid-row:first-child::before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // each-row-bottom
              "& .MuiDataGrid-row::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // footer-bottom
              "& .MuiDataGrid-row:last-child::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              minWidth: "678px",
              width: "100%",
              overflowX: "auto",
            }}
            localeText={{ noRowsLabel: "No floor found" }}
          />
        </Card>

        <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "background.paper",
                width: 400,
                marginY: 2,
                ...(isSmallScreen && { width: 280 })
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Typography variant="h6" gutterBottom mb={3}>
                  {modalMode === "add"
                    ? `Add Floor on ${activeGlobalBranch?.name}`
                    : `Edit Floor on ${activeGlobalBranch?.name}`}
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Name *"
                      name="name"
                      type="text"
                      {...formik.getFieldProps("floorName")}
                    />
                    {formik.touched.floorName && formik.errors.floorName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.floorName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      multiline
                      rows={2}
                      {...formik.getFieldProps("floorDescription")}
                    />
                    {formik.touched.floorDescription &&
                    formik.errors.floorDescription ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.floorDescription}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="IsActive"
                      name="floorIsActive"
                      select
                      value={formik.values.floorIsActive}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </TextField>
                    {formik.touched.floorIsActive &&
                    formik.errors.floorIsActive ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.floorIsActive}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    // disabled={!formik.dirty}
                  >
                    {modalMode === "add" ? "Add" : "Update"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: palette.grey[500],
                      "&:hover": {
                        backgroundColor: palette.grey[600],
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>
      </Container>

      <Toaster position="top-right" />
    </>
  );
};

export default Floor;
