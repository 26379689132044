import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';

const ImageDialog = ({ open, onClose, imageUrl }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    padding: '0',
                    borderRadius: '8px',
                },
            }}
        >
            <DialogContent sx={{ p: 0 }}>
                <Box
                    component="img"
                    src={imageUrl}
                    sx={{ width: '100%', height: 'auto' }}
                />
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 10, right: 10 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogContent>
        </Dialog>
    );
};

export default ImageDialog;
