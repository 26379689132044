import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  MenuItem,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Modal,
  Drawer,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Divider,
} from "@mui/material";
import Iconify from "../../components/iconify/Iconify";
import { Toaster, toast } from "react-hot-toast";
import { DataGrid } from "@mui/x-data-grid";
import palette from "../../theme/palette";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import SearchInput from "../../components/SearchInput";
import { useClient } from "../../context/ClientProvider";
import iconProfile from "../../assets/icons/Profile ioc.png";
import deleteIcon from "../../assets/icons/trash.png";
import EditIcon from "../../assets/icons/EditIcon";

// apis
import {
  addTeam,
  getTeamsByBranch,
  updateTeam,
  deleteTeam,
} from "../../api/team";
import { getServiceCategoryByBranch } from "../../api/serviceCategory";
import { getTeamMembersByBranch } from "../../api/user";
import { updateTeamMember, deleteTeamMember } from "../../api/teammember";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomTooltip from "../../components/common/CustomTooltip";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const teamValidationSchema = Yup.object().shape({
  teamName: Yup.string().required("Team name is required"),
  teamDescription: Yup.string().optional("Team description"),
  teamIsActive: Yup.boolean().required("Team active status is required"),
  teamServiceCategoryValue: Yup.array().optional(),
});

const Team = () => {
  const isSmallScreen = useIsSmallScreen();
  const [modalMode, setModalmode] = useState("add");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForUser, setModalOpenForUser] = useState(false);
  const [serviceCategoryList, setServiceCategoryList] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    status: false,
    iconName: null,
    deleteId: null,
  });
  const [teamDataforedit, setTeamDataforedit] = useState(null);
  const [searchTeamValue, setSearchTeamValue] = useState("");
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [teamsRowId, setTeamsRowId] = useState(0);
  const { activeGlobalBranch } = useClient();
  const [role, setRole] = useState({ roleId: null, isManager: false });
  const [teamMemberObjectInput, setTeamMemberObjectInput] = useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleModalClose = () => {
    setModalOpen(false);
    setTeamMemberObjectInput([]);
  };
  const handleModalCloseForUser = () => setModalOpenForUser(false);

  useEffect(() => {
    teamData();
  }, [activeGlobalBranch]);

  // get Team data by branchId
  const teamData = async () => {
    if (activeGlobalBranch) {
      try {
        const response = await getTeamsByBranch(activeGlobalBranch.id);
        setTeams(response.sort((a, b) => a.name.localeCompare(b.name)));
      } catch (error) {
        console.log("Error in fetching teams data for branch id ", error);
      }
      try {
        const response = await getTeamMembersByBranch(activeGlobalBranch.id);
        setUsers(response);
      } catch (error) {
        console.log("Error in fetching users data for branch id ", error);
      }
      try {
        const response = await getServiceCategoryByBranch(
          activeGlobalBranch.id
        );
        setServiceCategoryList(
          response.sort((a, b) => a.name.localeCompare(b.name))
        );
      } catch (error) {
        console.log(
          "Error in fetching service category data for branch id ",
          error
        );
      }
    }
  };

  // Edit Team data
  const editTeamData = async (newTeamDetails) => {
    try {
      const response = await updateTeam(teamDataforedit.id, {
        ...newTeamDetails,
        serviceCategoryId: newTeamDetails.serviceCategoryId,
      });
      if (response) {
        setTeams((previousTeams) =>
          previousTeams.map((teams) =>
            teams.id === teamDataforedit.id
              ? {
                  ...teams,
                  id: teamDataforedit.id,
                  description: newTeamDetails.description,
                  isActive: newTeamDetails.isActive,
                  name: newTeamDetails.name,
                  serviceCategoryId: newTeamDetails.serviceCategoryId?.id,
                }
              : teams
          )
        );
        teamData();
        handleModalClose();
        toast.success("Team updated successfully");
      }
    } catch (error) {
      if (error.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message?.message[0]);
      } else if (error.response?.data?.statusCode === 409) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error(error.response?.data?.message);
      }
    }
  };

  // delete Team Data
  const deleteTeamData = async (deleteId) => {
    try {
      const updatedTeams = teams.filter((team) => team.id !== deleteId);
      const response = await deleteTeam(deleteId);
      if (response) {
        setTeams(updatedTeams);
        toast.success("Team deleted successfully");
      }
    } catch (error) {
      console.error("delete teams Error:", error);
    }
  };

  const handleDeleteDialog = (id, iconName) => {
    setOpenDeleteDialog({
      ...openDeleteDialog,
      status: true,
      deleteId: id,
      iconName: iconName,
    });
  };

  const handleDeleteClose = () => {
    setOpenDeleteDialog({ ...openDeleteDialog, status: false });
  };

  // delete icon for team/user
  const handleConfirmDelete = () => {
    const { deleteId } = openDeleteDialog;
    const { iconName } = openDeleteDialog;
    if (iconName === "team") {
      deleteTeamData(deleteId);
    } else {
      removeUserRole(deleteId);
      setModalOpenForUser(false);
      teamData();
    }
    handleDeleteClose();
  };

  const formik = useFormik({
    initialValues: {
      teamName: "",
      teamDescription: "",
      teamIsActive: true,
      teamMemberObject: null,
      teamServiceCategoryValue: [],
    },
    validationSchema: teamValidationSchema,
    onSubmit: async (values) => {
      const teamsDetails = {
        name: values.teamName,
        description: values.teamDescription,
        isActive: values.teamIsActive,
        teamUserJunction: teamMemberObjectInput,
        serviceCategoryIds: values?.teamServiceCategoryValue?.map(
          (item) => item.id
        ),
      };
      try {
        if (modalMode === "add") {
          if (activeGlobalBranch) {
            teamsDetails.branchId = activeGlobalBranch.id;
          }
          const response = await addTeam(teamsDetails);
          response && toast.success("New Team added successfully");
          setTeamMemberObjectInput([]);
          formik.resetForm();
          handleModalClose();
          setRole({ roleId: null, isManager: false });
          teamData();
        } else if (modalMode === "edit") {
          editTeamData(teamsDetails);
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
      }
    },
  });

  // selects the role of the teammember from the dropdown and updates accordingly
  const handleTeammebersUpdate = async () => {
    const teamDetailsForUpdate = {
      teamId: teamsRowId,
      teamMembers: users
        .filter(
          (user) => !(user.isManager === false && user.isMember === false)
        )
        .map(({ id, isManager }) => ({ userId: id, isManager: isManager })),
    };

    try {
      const response = await updateTeamMember(teamDetailsForUpdate);
      if (response) {
        toast.success("Team members updated succesfully");
      }
    } catch (error) {
      toast.error(error.response?.data?.message[0]);
    }
    setModalOpenForUser(false);
    teamData();
  };

  // updates the role of the teammeber
  const handleOptionChange = (id) => (e) => {
    const index = e.nativeEvent.target.selectedIndex;
    const selectedOptionName = e.nativeEvent.target[index].text;

    if (selectedOptionName === "Member") {
      setUsers((previousValue) =>
        previousValue.map((value) =>
          value.id === id
            ? { ...value, isMember: true, isManager: false }
            : value
        )
      );
    } else if (selectedOptionName === "Manager") {
      setUsers((previousValue) =>
        previousValue.map((value) =>
          value.id === id
            ? { ...value, isMember: false, isManager: true }
            : value
        )
      );
    } else {
      setUsers((previousValue) =>
        previousValue.map((value) =>
          value.id === id
            ? { ...value, isMember: false, isManager: false }
            : value
        )
      );
    }
  };

  const handleRole = (value, id) => {
    value === "Manager"
      ? setRole({ roleId: id, isManager: true })
      : setRole({ roleId: id, isManager: false });
  };
  const handleModalOpen = (currentTeamData) => {
    if (activeGlobalBranch) {
      setModalOpen(true);
      if (currentTeamData.name) {
        setModalmode("edit");
        setTeamDataforedit(currentTeamData);
        formik.setValues({
          teamName: currentTeamData.name,
          teamDescription: currentTeamData.description,
          teamIsActive: currentTeamData.isActive,
          teamServiceCategoryValue: currentTeamData.teamServiceCategory?.map(
            (item) => item.serviceCategory
          ),
        });
      } else {
        setModalmode("add");
        formik.resetForm();
      }
    } else {
      toast.error("select client and branch");
    }
  };

  // removes the role of the teammember
  const removeUserRole = async (userId) => {
    try {
      const updatedUsers = users.filter((user) => user.id !== userId);
      const response = await deleteTeamMember(userId, teamsRowId);
      if (response) {
        setUsers(updatedUsers);
        toast.success("Teammember deleted successfully");
      }
    } catch (error) {
      console.error("delete Teammember Error:", error);
    }
  };

  // handles modal for the users for the selected branch
  const handleModalOpenForUser = (selectedTeamsRow, branchId) => {
    setModalOpenForUser(true);
    setTeamsRowId(selectedTeamsRow.id);
    const rowIndex = teams.findIndex((team) => team.id === selectedTeamsRow.id);
    const userData = users.map((item1) => {
      const match = teams[rowIndex].teamMembers?.find(
        (item2) => item1?.id === item2.user?.id
      );

      if (match) {
        item1.isMember = true;
        item1.isManager = match.isManager;
      } else {
        item1.isMember = false;
        item1.isManager = false;
      }
      return item1;
    });
    setUsers(userData);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      filterable: false,
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: ({ row }) => {
        return <CustomTooltip title={row?.name}>{row?.name}</CustomTooltip>;
      },
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row?.description}>
            {row?.description}
          </CustomTooltip>
        );
      },
    },
    {
      field: "serviceCategory",
      headerName: "ServiceCategory",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: ({ row }) => {
        return (
          <div>
            {row?.teamServiceCategory
              ?.sort((a, b) =>
                a.serviceCategory?.name?.localeCompare(b?.serviceCategory?.name)
              )
              .map((element) => (
                <div title={element?.serviceCategory?.name}>
                  {element?.serviceCategory?.name}
                </div>
              ))}
          </div>
        );
      },
    },
    {
      field: "teamMembers",
      headerName: "Team members",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.5,
      // width: 1.5,

      renderCell: ({ row }) => {
        return <>{row.teamMembers.length}</>;
      },
    },
    {
      field: "isActive",
      headerName: "IsActive",
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 160,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={0}>
            {/* Edit Icon */}
            <Tooltip title="Edit">
              <IconButton
                size="large"
                color="primary"
                onClick={() => handleModalOpen(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            {/* Delete Icon */}
            <Tooltip title="Delete">
              <IconButton
                size="large"
                color="error"
                onClick={() => handleDeleteDialog(row.id, "team")}
              >
                <img height="22px" src={deleteIcon} />
              </IconButton>
            </Tooltip>

            {activeGlobalBranch ? (
              <Tooltip title="view teammember">
                <IconButton
                  size="small"
                  color="green"
                  onClick={() =>
                    handleModalOpenForUser(row, activeGlobalBranch.id)
                  }
                >
                  <img src={iconProfile} />
                </IconButton>
              </Tooltip>
            ) : null}

            {/* Confirmation Modal */}
            <ConfirmationModal
              open={openDeleteDialog.status}
              onClose={handleDeleteClose}
              onConfirm={handleConfirmDelete}
              iconName={openDeleteDialog.iconName}
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          sx={{ border: "", ...(isSmallScreen && { marginTop: 2 }) }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            Team
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleModalOpen}
          >
            New Team
          </Button>
        </Stack>
        <Card sx={{ mt: 3, overflowX: "auto" }}>
          <SearchInput
            sx={{ ml: 1, mt: 1 }}
            label="team"
            value={searchTeamValue}
            onChange={(e) => {
              setSearchTeamValue(e.target.value);
            }}
            // onClear={() => {
            //   setSearchTeamValue("");
            // }}
          />
          <DataGrid
            getRowHeight={() => "auto"}
            rows={teams?.filter((team) =>
              Object.values(team).some(
                (value) =>
                  value !== null &&
                  value
                    ?.toString()
                    .toLowerCase()
                    .includes(searchTeamValue.toLowerCase())
              )
            )}
            columns={columns}
            getRowClassName={(row) => {
              if (
                Object.keys(row.row?.teamMembers).length === 0 ||
                Object.keys(row.row?.teamServiceCategory).length == 0
              ) {
                return "highlighted-row";
              }
              return "";
            }}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 50]}
            disableRowSelectionOnClick
            autoHeight
            localeText={{ noRowsLabel: "No Team data available" }}
            sx={{
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row": {
                position: "relative",
              },
              // header-bottom
              "& .MuiDataGrid-row:first-child::before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // each-row-bottom
              "& .MuiDataGrid-row::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // footer-bottom
              "& .MuiDataGrid-row:last-child::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              minWidth: "678px",
              width: "100%",
              overflowX: "auto",
            }}
          />
        </Card>
        <Modal open={modalOpenForUser} onClose={handleModalCloseForUser}>
          <Container maxWidth="lg">
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 1,
                width: "50%",
                maxHeight: "auto",
                overflowY: "auto",
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalCloseForUser}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Box sx={{ mt: 2, width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ color: palette.primary.dark }}
                    >
                      TeamMember
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: palette.primary.dark }}
                    >
                      Role
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: palette.primary.dark }}
                    >
                      Action
                    </Typography>
                  </Box>
                  <Divider
                    variant="fullWidth"
                    sx={{ backgroundColor: palette.primary.dark }}
                  />
                  <Box sx={{ overflowY: "auto", height: 210 }}>
                    {users.map((item) => (
                      <Box
                        key={item.id}
                        sx={{
                          mt: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ width: 150 }}>
                          {item.userName}
                        </Typography>

                        <select
                          className="mui-select"
                          //value={item.selectedOption}
                          onChange={handleOptionChange(item.id)}
                          style={{
                            // backgroundColor: '#f0f0f0',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '6px',
                          }}
                        >
                          <option selected>Select role</option>
                          <option selected={item.isManager}>Manager</option>
                          <option selected={!item.isManager && item.isMember}>
                            Member
                          </option>
                        </select>
                        <Typography>
                          <Tooltip title="Remove">
                            <IconButton
                              size="large"
                              color="error"
                              onClick={() =>
                                handleDeleteDialog(item.id, "user")
                              }
                            >
                              <Iconify icon={"fa-solid:user-times"} />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                        <ConfirmationModal
                          open={openDeleteDialog.status}
                          onClose={handleDeleteClose}
                          onConfirm={handleConfirmDelete}
                          iconName={openDeleteDialog.iconName}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleTeammebersUpdate}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </Container>
        </Modal>

        <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "background.paper",
                width: 400,
                marginTop: "40px",
                ...(isSmallScreen && { width: 280 }),
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Typography variant="h6" gutterBottom mb={3}>
                  {modalMode === "add" ? `Add Team` : `Edit Team`}
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Name *"
                      name="name"
                      type="text"
                      {...formik.getFieldProps("teamName")}
                    ></TextField>
                    {formik.touched.teamName && formik.errors.teamName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.teamName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      type="text"
                      {...formik.getFieldProps("teamDescription")}
                    ></TextField>
                    {formik.touched.teamDescription &&
                    formik.errors.teamDescription ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.teamDescription}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="IsActive"
                      name="teamIsActive"
                      select
                      value={formik.values.teamIsActive}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </TextField>
                    {formik.touched.teamIsActive &&
                    formik.errors.teamIsActive ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.teamIsActive}
                      </Typography>
                    ) : null}
                  </Grid>
                  {modalMode === "add" ? (
                    <Grid item xs={12} sm={12}>
                      <Box sx={{ my: 1, width: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 2,
                          }}
                        >
                          <Typography sx={{ fontWeight: 500 }}>
                            Team Member
                          </Typography>
                          <Typography sx={{ fontWeight: 500 }}>Role</Typography>
                        </Box>
                        <Box
                          sx={{ overflowY: "auto", height: "auto", px: 0.5 }}
                        >
                          {users.map((item) => (
                            <Box
                              key={item.id}
                              sx={{
                                my: 1.5,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                if (selectedValue === "") {
                                  // If the selected value is empty, remove the object from the array
                                  setTeamMemberObjectInput((prevValue) =>
                                    prevValue.filter(
                                      (member) => member.userId !== item.id
                                    )
                                  );
                                } else {
                                  // If the selected value is not empty, update the array as before
                                  const isManager = selectedValue === "Manager";
                                  const existingIndex =
                                    teamMemberObjectInput.findIndex(
                                      (member) => member.userId === item.id
                                    );

                                  if (existingIndex !== -1) {
                                    setTeamMemberObjectInput((prevValue) => {
                                      const updatedArray = [...prevValue];
                                      updatedArray[existingIndex] = {
                                        userId: item.id,
                                        isManager: isManager,
                                      };
                                      return updatedArray;
                                    });
                                  } else {
                                    setTeamMemberObjectInput((prevValue) => [
                                      ...prevValue,
                                      { userId: item.id, isManager: isManager },
                                    ]);
                                  }
                                }
                              }}
                            >
                              <Typography sx={{ width: 150 }}>
                                {" "}
                                {item.userName}
                              </Typography>
                              <select
                                className="mui-select"
                                onChange={(e) =>
                                  handleRole(e.target.value, item.id)
                                }
                                style={{
                                  border: '1px solid #ccc',
                                  borderRadius: '4px',
                                  padding: '6px',
                                }}
                              >
                                <option value="" selected>
                                  Select role
                                </option>
                                <option value="Manager">Manager</option>
                                <option value="Member">Member</option>
                              </select>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={serviceCategoryList}
                      value={formik.values.teamServiceCategoryValue}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option?.name}
                      onChange={(event, checked) => {
                        formik.setFieldValue(
                          "teamServiceCategoryValue",
                          checked
                        );
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginLeft: 0 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      // style={{ width: 400 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Service Category"
                          placeholder="Service Category"
                        />
                      )}
                    />
                    {formik.touched.teamServiceCategoryValue &&
                    formik.errors.teamServiceCategoryValue ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.teamServiceCategoryValue}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button type="submit" variant="contained" color="primary">
                    {modalMode === "add" ? "Add" : "Update"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: palette.grey[500],
                      "&:hover": {
                        backgroundColor: palette.grey[600],
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>
      </Container>
      <Toaster position="top-right" />
    </>
  );
};

export default Team;
