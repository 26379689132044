import noAuthInstance from "./noAuthInstance";
import axiosInstance from ".";

export const getAllInvoice = async (invoiceDetail) => {
    try {
        const response = await axiosInstance.post('/invoice/getall-invoice', invoiceDetail);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const payInvoice = async (invoiceId) => {
    try {
        const response = await axiosInstance.get(`/invoice/pay/${invoiceId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getInvoiceStatus = async (branchId) => {
    try {
        const response = await axiosInstance.post('/invoice/invoice-status', branchId);
        return response.data;
    } catch (error) {
        throw error;
    }
};