import axiosInstance from ".";
import noAuthInstance from "./noAuthInstance";

export const addCustomer = async (customerDetails) => {
    try {
        const response = await axiosInstance.post('/admin/customer/create', customerDetails,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        return response.data
    } catch (error) {
        throw error
    }
}

export const updateCustomer = async (customerId, customerDetails) => {
    try {
        const response = await axiosInstance.put(`/admin/customer/${customerId}`, customerDetails,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        return response.data
    } catch (error) {
        throw error
    }
}

export const getCustomerByBranch = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/admin/customer?branchId=${branchId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getCustomerByContactNumber = async (branchId, contactNumber) => {
    try {
        const response = await axiosInstance.get(`/admin/customer?branchId=${branchId}&contactNumber=${contactNumber}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const findAllCustomersByBranch = async (customerDetails) => {
    try {
        const response = await axiosInstance.post('/admin/customer/findall', customerDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const deleteCustomer = async (customerId) => {
    try {
        const response = await axiosInstance.delete(`/admin/customer/${customerId}`)
        return response.data
    } catch (error) {
        throw error
    }
}

export const resendOtp = async (roomId) => {
    try {
        const response = await noAuthInstance.get(`/customer/resendotp?roomId=${roomId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};