import axiosInstance from ".";

export const getAllPlans = async () => {
    try {
        const response = await axiosInstance.get('/admin/plans');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addPlan = async (planDetails) => {
    try {
        const response = await axiosInstance.post('/admin/plans/create', planDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updatePlan = async (planId, planDetails) => {
    try {
        const response = await axiosInstance.put(`/admin/plans/${planId}`, planDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
}