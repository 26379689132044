import axiosInstance from ".";
import noAuthInstance from "./noAuthInstance";

export const sendOTP = async (mobileNumberDetails) => {
    try {
        const response = await noAuthInstance.post('/admin/user/sendOtp', mobileNumberDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const resendOTP = async (mobileNumberDetails) => {
    try {
        const response = await noAuthInstance.post('/admin/user/sendOtp', mobileNumberDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const forgotPassword = async (passwordDetails) => {
    try {
        const response = await noAuthInstance.post('/admin/user/forgotPassword', passwordDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const changeUserPassword = async (passwordDetails) => {
    try {
        const response = await axiosInstance.post('/admin/user/changePassword', passwordDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const resetSuperadminPassword = async (passwordDetails) => {
    try {
        const response = await axiosInstance.post('/admin/superadmin/resetpassword', passwordDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const resetAdminPassword = async (passwordDetails) => {
    try {
        const response = await axiosInstance.post('/api/user/resetpassword', passwordDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
};