import React, { useState, useEffect } from 'react';
import { Box, Container, Drawer, Typography, IconButton, Grid, TextField, Button, Autocomplete } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear'
import palette from '../../theme/palette';
import { useClient } from '../../context/ClientProvider';
import { getBranchByClient } from '../../api/branch';

import * as Yup from 'yup';
import { useFormik } from 'formik';

const clientBranchValidatonSchema = Yup.object().shape({
    selectedClient: Yup.object().required('Client is required'),
    // selectedBranch: Yup.object(),
});

const ClientBranchModal = ({ modalOpen, onClose }) => {
    const localUserType = localStorage.getItem('userType');
    const userType = JSON.parse(localUserType);
    const { globalClientList, activeGlobalClient, setGlobalBranchList, setActiveGlobalBranch, setActiveGlobalClient } = useClient();
    const [selectedClient, setSelectedClient] = useState(null);
    const [branchList, setBranchList] = useState([]);

    const formik = useFormik({
        initialValues: {
            selectedClient: null,
            selectedBranch: null,
        },
        validationSchema: userType === 'super_admin' && clientBranchValidatonSchema,
        onSubmit: async (values) => {
            setGlobalBranchList(branchList);
            values.selectedClient && setActiveGlobalClient(values.selectedClient);
            values.selectedBranch ? setActiveGlobalBranch(values.selectedBranch) : setActiveGlobalBranch(branchList[0]);
            onClose();
        }
    });

    useEffect(() => {
        // Fetch branches when a client is selected
        const fetchBranches = async () => {
            if (selectedClient) {
                try {
                    const response = await getBranchByClient(selectedClient.id);
                    setBranchList(response);
                } catch (error) {
                    console.error('Error fetching branches: ', error);
                }
            }
        };

        // fetch branch for admin & supervisor

        const fetchAdminBranches = async () => {
            if (activeGlobalClient) {
                try {
                    const response = await getBranchByClient(activeGlobalClient.id);
                    setBranchList(response);
                } catch (error) {
                    console.error('Error fetching branches: ', error);
                }
            }
        };

        // fetchBranches();
        userType === 'super_admin' ? fetchBranches() : fetchAdminBranches();
    }, [selectedClient, activeGlobalClient]);

    return (
        <>
            <Drawer anchor='right' open={modalOpen} onClose={onClose}>
                <Container maxWidth='sm'>
                    <Box sx={{
                        bgcolor: 'background.paper',
                        width: 400,
                        marginY: 2,
                    }}>
                        <IconButton
                            sx={{ position: 'absolute', top: 5, right: 5 }}
                            color={palette.grey[500]}
                            onClick={onClose}
                        >
                            <ClearIcon />
                        </IconButton>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: 0,
                            }}
                        >
                            <Typography variant="h6" gutterBottom mb={0}>
                                Update Client & Branch
                            </Typography>
                        </Box>
                    </Box>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2} my={3}>
                            {userType === "super_admin" && <Grid item xs={12} sm={12}>
                                <Autocomplete
                                    options={globalClientList}
                                    getOptionLabel={(client) => client.name}
                                    onChange={(event, clientValue) => {
                                        formik.setFieldValue('selectedClient', clientValue);
                                        setSelectedClient(clientValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Client" />}
                                    noOptionsText="No clients"
                                />
                                {formik.touched.selectedClient && formik.errors.selectedClient ? (
                                    <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors.selectedClient}</Typography>
                                ) : null}
                            </Grid>}
                            <Grid item xs={12} sm={12}>
                                <Autocomplete
                                    options={branchList}
                                    getOptionLabel={(branch) => branch.name}
                                    onChange={(event, branchValue) => {
                                        formik.setFieldValue('selectedBranch', branchValue);
                                        // setSelectedBranch(branchValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Branch" />}
                                    noOptionsText="No branches"
                                />
                            </Grid>
                        </Grid>
                        <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="submit" variant="contained" color="primary">
                                Update
                            </Button>
                            {/* <Button type="button" variant="contained" color='secondary' sx={{
                                backgroundColor: palette.grey[500],
                                '&:hover': {
                                    backgroundColor: palette.grey[600],
                                },
                                marginLeft: 1,
                            }}
                                onClick={() => formik.resetForm()}
                            >
                                clear
                            </Button> */}
                        </Box>
                    </form>
                </Container>
            </Drawer>
        </>
    )
}

export default ClientBranchModal;