import DashboardIcon from "@mui/icons-material/Dashboard";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import FilterVintageIcon from "@mui/icons-material/FilterVintage";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import TaskIcon from "@mui/icons-material/Task";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessIcon from "@mui/icons-material/Business";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import SummarizeIcon from "@mui/icons-material/Summarize";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import ReceiptIcon from "@mui/icons-material/Receipt";
// components
import {
  Dashboard,
  Client,
  Branches,
  Floor,
  ServiceCategory,
  Rooms,
  Superadmin,
  Service,
  User,
  Team,
  Checkout,
  Customer,
  Taskreport,
} from "../../../pages";

const navConfigAdmin = [
  {
    title: "dashboard",
    path: "/app/dashboard",
    icon: <DashboardIcon />,
    element: <Dashboard />,
  },
  {
    title: "Customer",
    path: "/app/customer",
    icon: <SupportAgentIcon />,
    element: <Customer />,
  },
  {
    title: "Check-in",
    path: "/app/checkin",
    icon: <TransferWithinAStationIcon />,
    element: <Checkout />,
  },
  {
    title: "Setup",
    path: "/app/branch",
    element: <Branches />,
    icon: <RoomPreferencesIcon />,
    nested: [
      {
        title: "Branch",
        path: "/app/branch",
        icon: <FilterVintageIcon />,
        element: <Branches />,
      },
      {
        title: "Floor",
        path: "/app/floor",
        icon: <BusinessIcon />,
        element: <Floor />,
      },
      {
        title: "Room",
        path: "/app/room",
        icon: <BedroomParentIcon />,
        element: <Rooms />,
      },
      {
        title: "Service Category",
        path: "/app/service-category",
        icon: <TransferWithinAStationIcon />,
        element: <ServiceCategory />,
      },
      {
        title: "Service",
        path: "/app/service",
        icon: <MiscellaneousServicesIcon />,
        element: <Service />,
      },
      {
        title: "Team",
        path: "/app/team",
        icon: <GroupsIcon />,
        element: <Team />,
      },
    ],
  },
  {
    title: "User",
    path: "/app/user",
    icon: <PersonIcon />,
    element: <User />,
  },
  {
    title: "Service Request Report",
    path: "/app/taskreport",
    icon: <TaskIcon />,
    element: <Taskreport />,
  },
  {
    title: "Invoice",
    path: "/app/invoice",
    icon: <ReceiptIcon />,
  },
];

export default navConfigAdmin;
