import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [globalUser, setGlobalUser] = useState({
    status: false,
    user: null,
  });

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setGlobalUser({
        status: true,
        user: JSON.parse(user),
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ globalUser, setGlobalUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
};

export default AuthProvider;