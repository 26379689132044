import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Backdrop } from "@mui/material"


const ConfirmationModal = ({ open, onClose, onConfirm, iconName }) => {

    return (
        <Backdrop>
            <Dialog open={open}
                onClose={onClose}
                sx={{
                    boxShadow: 'none',
                    '& .MuiPaper-root': {
                        boxShadow: 'none',
                    },
                }}
                slotProps={{ backdrop: { sx: { background: 'rgba(33, 43, 54, 0.20)' } } }}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {/* Are you sure you want to delete this client? */}
                        Are you sure you want to delete this {iconName} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={onClose} color="error">
                        No
                    </Button>
                    <Button variant='contained' onClick={onConfirm} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Backdrop>
    )
}

ConfirmationModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
}

export default ConfirmationModal;