import {
  Box,
  Card,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
  alpha,
  TextField,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import palette from "../../theme/palette";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DateCalendar } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

// svgs
import trendUp from "../../assets/svgs/trend-up.svg";
import trendDown from "../../assets/svgs/trend-down.svg";
import ReactApexChart from "react-apexcharts";
import { getServiceRequestForDashboard } from "../../api/serviceRequest";
import moment from "moment";
import { useAuth } from "../../context/AuthProvider";
import { useClient } from "../../context/ClientProvider";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import LoaderBbl from "../../components/Loader/LoaderBbl";

function useChart(options) {
  const theme = useTheme();

  const LABEL_TOTAL = {
    show: true,
    label: "Total",
    color: theme.palette.text.secondary,
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
    lineHeight: theme.typography.subtitle2.lineHeight,
  };

  const LABEL_VALUE = {
    offsetY: 8,
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: theme.typography.h3.lineHeight,
  };

  const baseOptions = {
    // Colors
    colors: [
      theme.palette.primary.main,
      theme.palette.warning.main,
      theme.palette.info.main,
      theme.palette.error.main,
      theme.palette.success.main,
      theme.palette.warning.dark,
      theme.palette.success.darker,
      theme.palette.info.dark,
      theme.palette.info.darker,
    ],

    // Chart
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      // animations: { enabled: false },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily,
    },

    // States
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.04,
        },
      },
      active: {
        filter: {
          type: "darken",
          value: 0.88,
        },
      },
    },

    // Fill
    fill: {
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
      },
    },

    // Datalabels
    dataLabels: { enabled: false },

    // Stroke
    stroke: {
      width: 3,
      curve: "smooth",
      lineCap: "round",
    },

    // Grid
    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },

    // Xaxis
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
    },

    // Markers
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper,
    },

    // Tooltip
    tooltip: {
      x: {
        show: false,
      },
    },

    // Legend
    legend: {
      show: true,
      fontSize: String(13),
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 12,
      },
      fontWeight: 500,
      itemMargin: { horizontal: 12 },
      labels: {
        colors: theme.palette.text.primary,
      },
    },

    // plotOptions
    plotOptions: {
      // Bar
      bar: {
        borderRadius: 4,
        columnWidth: "28%",
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
      },

      // Pie + Donut
      pie: {
        donut: {
          labels: {
            show: true,
            value: LABEL_VALUE,
            total: LABEL_TOTAL,
          },
        },
      },

      // Radialbar
      radialBar: {
        track: {
          strokeWidth: "100%",
          background: alpha(theme.palette.grey[500], 0.16),
        },
        dataLabels: {
          value: LABEL_VALUE,
          total: LABEL_TOTAL,
        },
      },

      // Radar
      radar: {
        polygons: {
          fill: { colors: ["transparent"] },
          strokeColors: theme.palette.divider,
          connectorColors: theme.palette.divider,
        },
      },

      // polarArea
      polarArea: {
        rings: {
          strokeColor: theme.palette.divider,
        },
        spokes: {
          connectorColors: theme.palette.divider,
        },
      },
    },

    // Responsive
    responsive: [
      {
        // sm
        breakpoint: theme.breakpoints.values.sm,
        options: {
          plotOptions: { bar: { columnWidth: "40%" } },
        },
      },
      {
        // md
        breakpoint: theme.breakpoints.values.md,
        options: {
          plotOptions: { bar: { columnWidth: "32%" } },
        },
      },
    ],
  };

  const mergedOptions = {
    ...baseOptions,
    ...options,
    responsive: [...baseOptions.responsive, ...(options.responsive || [])],
  };

  return mergedOptions;
}

const LineChartExample = () => {
  const chartData = [
    {
      name: "Service Requests",
      type: "column",
      fill: "solid",
      data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
    },
    // {
    //   name: 'Team B',
    //   type: 'area',
    //   fill: 'gradient',
    //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
    // },
    // {
    //   name: 'Team C',
    //   type: 'line',
    //   fill: 'solid',
    //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
    // },
  ];

  const chartLabels = [
    "01/01/2003",
    "02/01/2003",
    "03/01/2003",
    "04/01/2003",
    "05/01/2003",
    "06/01/2003",
    "07/01/2003",
    "08/01/2003",
    "09/01/2003",
    "10/01/2003",
    "11/01/2003",
  ];

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} visits`;
          }
          return y;
        },
      },
    },
  });

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartData}
      type="line"
      height={364}
    />
  );
};

const RoundChartExample = () => {
  const chartData = [44, 55];
  const options = {
    labels: ["Team A", "Team B"],
    colors: ["#A94C9B", "#5F4699"],
    legend: { floating: false, horizontalAlign: "center" },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => seriesName,
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={chartData}
      type="donut"
      height={364}
      width={320}
    />
  );
};

const Dashboard = () => {
  const isSmallScreen = useIsSmallScreen();
  const { globalUser } = useAuth();
  const { activeGlobalBranch } = useClient();
  const currentTime = moment();
  const currentHour = currentTime.hour();
  const [task, setTask] = useState([]);
  const [taskDays, setTaskDays] = useState(7);
  const [isLoading, setIsLoading] = useState(false);

  let greeting;
  if (currentHour >= 5 && currentHour < 12) {
    greeting = "Good morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }
  const processesperpage = 5;
  const [taskopen, setTaskopen] = useState([]);
  const [taskprogress, setTaskrogress] = useState([]);
  const [taskcompleted, setTaskcompleted] = useState([]);
  const [pageOpen, setPageOpen] = useState(1);
  const [pageProgress, setPageProgress] = useState(1);
  const [pageCompleted, setPageCompleted] = useState(1);

  useEffect(() => {
    const getTask = async () => {
      setIsLoading(true);
      const response = await getServiceRequestForDashboard({
        branchId: activeGlobalBranch?.id,
        days: taskDays,
      });
      if (response) {
        setTask(response);
        setTaskopen(
          response?.taskdetails
            ?.filter((task) => task.status === "Assigned")
            ?.slice(0, pageOpen * processesperpage)
        );
        setTaskrogress(
          response?.taskdetails
            ?.filter((task) => task.status === "Progress")
            ?.slice(0, pageProgress * processesperpage)
        );
        setTaskcompleted(
          response?.taskdetails
            ?.filter((task) => task.status === "Completed")
            ?.slice(0, pageCompleted * processesperpage)
        );
        setIsLoading(false);
      }
    };

    activeGlobalBranch && getTask();
  }, [taskDays, activeGlobalBranch, pageOpen, pageProgress, pageCompleted]);
  return (
    <Container maxWidth="xl" sx={{ ...(isSmallScreen && { mt: 1 }) }}>
      <Typography variant="h5" sx={{ mb: 1, color: palette.primary.dark }}>
        Dashboard
      </Typography>
      <Typography variant="body1" sx={{ mb: 5, color: palette.primary.dark }}>
        Hello{" "}
        {globalUser?.user?.user?.firstName ||
          globalUser?.user?.superAdmin?.firstName}
        , {greeting} !
      </Typography>

      <Grid container spacing={3} justifyContent="space-between">
        <Stack
          // spacing={2}
          // ml={3}
          // mt={1}
          width="100%"
          // border="1px solid red"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pl: 2,
            // ...(isSmallScreen && { flexDirection: "column", justifyContent: "center", alignItems: "center" })
          }}
        >
          <Box
            sx={{
              height: "260px",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              border: "2px solid reds",
              boxShadow: "-9px 10px 16px 0px #00000029",
              borderRadius: "10px",
              ...(isSmallScreen && { width: "130px", height: "130px", mb: 1 })
            }}
          >
            <Typography variant="h6" sx={{ ...(isSmallScreen && { fontSize: "10px" }) }}>
              Today's <br /> Service Request
            </Typography>
            <Typography variant="h6" sx={{ my: 1, fontSize: "35px !important", ...(isSmallScreen && { fontSize: "20px" }) }}>
              {isLoading ? <LoaderBbl /> : task?.totaltaskCount?.todayTaskCount}
            </Typography>
            <Typography variant="body2" sx={{ color: "#807e7e", ...(isSmallScreen && { fontSize: "8px" }) }}>
              {moment(task?.dateRange?.today?.fromDate).format("DD.MM.YYYY")}-
              {moment(task?.dateRange?.today?.fromDate).format("DD.MM.YYYY")}
            </Typography>
            {/* <Box sx={{ display: "flex", width: "100%", mt: 1.5 }}>
              <Typography variant="body2" sx={{ color: "#03CC3B", mr: 1 }}>
                {" "}
                6.02%{" "}
              </Typography>
              <img src={trendUp} alt="" />
            </Box> */}
          </Box>

          <Box
            sx={{
              height: "260px",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              border: "2px solid reds",
              boxShadow: "-9px 10px 16px 0px #00000029",
              borderRadius: "10px",
              ...(isSmallScreen && { width: "130px", height: "130px", mb: 1 })
            }}
          >
            <Typography variant="h6" sx={{ ...(isSmallScreen && { fontSize: "10px" }) }}>
              7 Days <br /> Service Request
            </Typography>
            <Typography variant="h6" sx={{ my: 1, fontSize: "35px !important", ...(isSmallScreen && { fontSize: "20px" }) }}>
              {isLoading ? <LoaderBbl /> : task?.totaltaskCount?.lastSevenDays}
            </Typography>
            <Typography variant="body2" sx={{ color: "#807e7e", ...(isSmallScreen && { fontSize: "8px" }) }}>
              {moment(task?.dateRange?.lastSevenDays?.fromDate).format(
                "DD.MM.YYYY"
              )}
              -
              {moment(task?.dateRange?.lastSevenDays?.toDate).format(
                "DD.MM.YYYY"
              )}
            </Typography>
            {/* <Box sx={{ display: "flex", width: "100%", mt: 1.5 }}>
              <Typography variant="body2" sx={{ color: "#03CC3B", mr: 1 }}>
                {" "}
                6.02%{" "}
              </Typography>
              <img src={trendUp} alt="" />
            </Box> */}
          </Box>

          <Box
            sx={{
              height: "260px",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              border: "2px solid reds",
              boxShadow: "-9px 10px 16px 0px #00000029",
              borderRadius: "10px",
              ...(isSmallScreen && { width: "130px", height: "130px", mb: 1 })
            }}
          >
            <Typography variant="h6" sx={{ ...(isSmallScreen && { fontSize: "10px" }) }}>
              30 Days <br /> Service Request
            </Typography>
            <Typography variant="h6" sx={{ my: 1, fontSize: "35px !important", ...(isSmallScreen && { fontSize: "20px" }) }}>
              {isLoading ? <LoaderBbl /> : task?.totaltaskCount?.lastMonth}
            </Typography>
            <Typography variant="body2" sx={{ color: "#807e7e", ...(isSmallScreen && { fontSize: "8px" }) }}>
              {moment(task?.dateRange?.lastMonth?.fromDate).format(
                "DD.MM.YYYY"
              )}
              -{moment(task?.dateRange?.lastMonth?.toDate).format("DD.MM.YYYY")}{" "}
            </Typography>
            {/* <Box sx={{ display: "flex", width: "100%", mt: 1.5 }}>
              <Typography variant="body2" sx={{ color: "#E22222", mr: 1 }}>
                {" "}
                6.02%{" "}
              </Typography>
              <img src={trendDown} alt="" />
            </Box> */}
          </Box>

          <Stack
            border="2px solid redd"
            width={300}
            ml={3}
            sx={{ mt: "-85px !important", ...(isSmallScreen && { mt: 2, display: "none" }) }}
          >
            <Box
              sx={{
                padding: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                border: "2px solid reds",
                boxShadow: "-9px 10px 16px 0px #00000029",
                borderRadius: "10px",
              }}
            >
              {/* <Typography variant='h6'> Last 30 Days <br /> Service Request </Typography> */}
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateCalendar sx={{ width: 300 }} />
              </LocalizationProvider>
            </Box>
          </Stack>
        </Stack>
      </Grid>

      <Grid>
        <Box mt={4}>
          <Box
            className="customAutocomplete"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="h5"
              sx={{ mb: 0.2, color: palette.primary.dark }}
            >
              Task details
            </Typography>
            <TextField
              size="small"
              name="selectDays"
              label="Select day"
              select
              // InputLabelProps={{ shrink: false }}
              onChange={(e) => setTaskDays(e.target.value)}
              value={taskDays}
              sx={{ width: "20%", ...(isSmallScreen && { width: "40%" }) }}
            >
              <MenuItem value={1}>Today</MenuItem>
              <MenuItem selected value={7}>
                Last 7 days
              </MenuItem>
              <MenuItem value={30}>Last 30 days</MenuItem>
            </TextField>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FiberManualRecordIcon
              sx={{
                height: "15px",
                width: "15px",
                color: "#A94C9B",
                marginRight: 0.5,
              }}
            />
            <Typography fontSize={13} variant="subtitle2">
              New Requests
            </Typography>
          </Box>

          <Stack
            direction="row"
            spacing={4}
            mt={1}
            useFlexGap
            flexWrap="wrap"
            borderRadius="10px"
            border="1px solid reds"
            overflow="scroll"
            className="hide-scrollbar"
          >
            {taskopen &&
              taskopen.map((task) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px",
                    borderRadius: "10px",
                    border: "1px solid #e6e6e6",
                    boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      border: "1px solid reds",
                      width: 330,
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1">
                        {/* Service category */}
                        {task?.services?.serviceCategory?.name}
                      </Typography>
                      <Typography variant="subtitle2">
                        Service: {task?.services?.name}
                      </Typography>
                    </Box>

                    {/* <IconButton>
                  <MoreVertIcon />
                </IconButton> */}
                  </Box>

                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Typography variant="body1">
                      Room : #{task?.customerCheckout?.room?.name}
                    </Typography>
                    <Typography variant="body2" mr={2}>
                      Assign to : {task?.user?.firstName}{" "}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
                    <Typography variant="subtitle2" fontSize={12}>
                      Status :{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize={12}
                      mx={1}
                      color="#807e7e"
                    >
                      New Task{" "}
                    </Typography>
                    <FiberManualRecordIcon
                      sx={{ height: "20px", width: "20px", color: "#A94C9B" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 2,
                      color: "#807e7e",
                    }}
                  >
                    <Typography variant="body2" fontSize={12}>
                      TaskId : {task?.id}
                    </Typography>
                    <Typography variant="body2" fontSize={12}>
                      {moment(task?.createdAt).format("DD.MM.YYYY")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ position: "absolute", right: 0, top: 0, zIndex: -1 }}
                  >
                    {/* <img src={trendDown} alt="" /> */}
                    <svg
                      width="90"
                      height="80"
                      viewBox="0 0 107 98"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="64"
                        cy="34"
                        r="64"
                        fill="#A94C9B"
                        fill-opacity="0.25"
                      />
                    </svg>
                  </Box>
                </Box>
              ))}

            {taskopen && taskopen.length === 0 && (
              <Box>
                <Typography fontSize={15} variant="body2">
                  No Open requests found
                </Typography>
              </Box>
            )}
          </Stack>
        </Box>
      </Grid>
      {task?.taskdetails?.filter((task) => task.status === "Assigned").length > 5
        && taskopen.length !== task?.taskdetails?.filter((task) => task.status === "Assigned").length ? (
        <Button
          sx={{
            variant: "contained",
            justifyContent: "flex-end",
            font: "italic",
          }}
          onClick={() => setPageOpen(pageOpen + 1)}
        >
          More..
        </Button>
      ) : null}

      <Grid>
        <Box mt={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FiberManualRecordIcon
              sx={{
                height: "15px",
                width: "15px",
                color: "#FF5B5B",
                marginRight: 0.5,
              }}
            />
            <Typography fontSize={13} variant="subtitle2">
              On-Progress Task
            </Typography>
          </Box>

          <Stack
            direction="row"
            spacing={4}
            mt={1}
            useFlexGap
            flexWrap="wrap"
            borderRadius="10px"
            border="1px solid reds"
            overflow="scroll"
            className="hide-scrollbar"
          >
            {taskprogress &&
              taskprogress.map((task) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px",
                    borderRadius: "10px",
                    border: "1px solid #e6e6e6",
                    boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      border: "1px solid reds",
                      width: 330,
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1">
                        {/* Service category */}
                        {task?.services?.serviceCategory?.name}
                      </Typography>
                      <Typography variant="subtitle2">
                        Service: {task?.services?.name}
                      </Typography>
                    </Box>

                    {/* <IconButton>
                  <MoreVertIcon />
                </IconButton> */}
                  </Box>

                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Typography variant="body1">
                      Room : #{task?.customerCheckout?.room?.name}
                    </Typography>
                    <Typography variant="body2" mr={2}>
                      Assign to : {task?.user?.firstName}{" "}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
                    <Typography variant="subtitle2" fontSize={12}>
                      Status :{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize={12}
                      mx={1}
                      color="#807e7e"
                    >
                      New Task{" "}
                    </Typography>
                    <FiberManualRecordIcon
                      sx={{ height: "20px", width: "20px", color: "#FF5B5B" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 2,
                      color: "#807e7e",
                    }}
                  >
                    <Typography variant="body2" fontSize={12}>
                      TaskId : {task?.id}
                    </Typography>
                    <Typography variant="body2" fontSize={12}>
                      {moment(task?.createdAt).format("DD.MM.YYYY")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ position: "absolute", right: 0, top: 0, zIndex: -1 }}
                  >
                    {/* <img src={trendDown} alt="" /> */}
                    <svg
                      width="90"
                      height="80"
                      viewBox="0 0 107 98"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="64"
                        cy="34"
                        r="64"
                        fill="#FF5B5B"
                        fill-opacity="0.25"
                      />
                    </svg>
                  </Box>
                </Box>
              ))}

            {taskprogress && taskprogress.length === 0 && (
              <Box>
                <Typography fontSize={15} variant="body2">
                  No Progress requests found
                </Typography>
              </Box>
            )}
          </Stack>
        </Box>
      </Grid>
      {task?.taskdetails?.filter((task) => task.status === "Progress").length > 5
        && taskprogress.length !== task?.taskdetails?.filter((task) => task.status === "Progress").length
        ? (
          <Button
            sx={{ variant: "contained", justifyContent: "flex-end" }}
            onClick={() => setPageProgress(pageProgress + 1)}
          >
            More..
          </Button>
        ) : null}

      <Grid>
        <Box mt={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FiberManualRecordIcon
              sx={{
                height: "15px",
                width: "15px",
                color: "#03CC3B",
                marginRight: 0.5,
              }}
            />
            <Typography fontSize={13} variant="subtitle2">
              Finished Task
            </Typography>
          </Box>

          <Stack
            direction="row"
            spacing={4}
            mt={1}
            useFlexGap
            flexWrap="wrap"
            borderRadius="10px"
            border="1px solid reds"
            overflow="scroll"
            className="hide-scrollbar"
          >
            {taskcompleted &&
              taskcompleted.map((task) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px",
                    borderRadius: "10px",
                    border: "1px solid #e6e6e6",
                    boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      border: "1px solid reds",
                      width: 330,
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1">
                        {/* Service category */}
                        {task?.services?.serviceCategory?.name}
                      </Typography>
                      <Typography variant="subtitle2">
                        Service: {task?.services?.name}
                      </Typography>
                    </Box>

                    {/* <IconButton>
                  <MoreVertIcon />
                </IconButton> */}
                  </Box>

                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Typography variant="body1">
                      Room : #{task?.customerCheckout?.room?.name}
                    </Typography>
                    <Typography variant="body2" mr={2}>
                      Assign to : {task?.user?.firstName}{" "}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
                    <Typography variant="subtitle2" fontSize={12}>
                      Status :{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize={12}
                      mx={1}
                      color="#807e7e"
                    >
                      New Task{" "}
                    </Typography>
                    <FiberManualRecordIcon
                      sx={{ height: "20px", width: "20px", color: "#03CC3B" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 2,
                      color: "#807e7e",
                    }}
                  >
                    <Typography variant="body2" fontSize={12}>
                      TaskId : {task?.id}
                    </Typography>
                    <Typography variant="body2" fontSize={12}>
                      {moment(task?.createdAt).format("DD.MM.YYYY")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ position: "absolute", right: 0, top: 0, zIndex: -1 }}
                  >
                    {/* <img src={trendDown} alt="" /> */}
                    <svg
                      width="90"
                      height="80"
                      viewBox="0 0 107 98"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="64"
                        cy="34"
                        r="64"
                        fill="#03CC3B"
                        fill-opacity="0.25"
                      />
                    </svg>
                  </Box>
                </Box>
              ))}

            {taskcompleted && taskcompleted.length === 0 && (
              <Box>
                <Typography fontSize={15} variant="body2">
                  No Completed requests found
                </Typography>
              </Box>
            )}
          </Stack>
        </Box>
      </Grid>
      {task?.taskdetails?.filter((task) => task.status === "Completed").length > 5
        && taskcompleted.length !== task?.taskdetails?.filter((task) => task.status === "Completed").length
        ? (
          <Button
            sx={{ variant: "contained", justifyContent: "flex-end" }}
            onClick={() => setPageCompleted(pageCompleted + 1)}
          >
            More..
          </Button>
        ) : null}

      {/* <Typography variant='h5' sx={{ my: 5, color: palette.primary.dark }}>
        Hotel analytics
      </Typography> */}

      {/* <Grid container mt={5} spacing={3}>
        <Grid item xs={12} sm={8} md={8} lg={8} border="2px solid reds" overflow="hidden">
          <Box sx={{}}>
            <LineChartExample />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} border="2px solid greens">
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", padding:"30px", borderRadius: "15px", boxShadow: '-6px 6px 16px 0px #00000029'}}>
            <RoundChartExample />
          </Box>
        </Grid>
      </Grid> */}
    </Container>
  );
};

export default Dashboard;
