import React from 'react';
import { Box, Modal, Typography } from '@mui/material'

const OtpModal = (props) => {
    const { handleViewOTP, handleCloseOTPmodal, customerOtp } = props;
    return (
        <>
            <Modal
                open={handleViewOTP}
                onClose={handleCloseOTPmodal}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 1,
                        // maxHeight: "80vh",
                        // height: "50px",
                        width: "300px",
                        overflowY: "auto",
                    }}
                >
                    <Box>
                        <Typography
                            variant="subtitle1"
                        >
                            Customer OTP is : {customerOtp}
                        </Typography>
                    </Box>

                </Box>
            </Modal>
        </>
    )
}

export default OtpModal