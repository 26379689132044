import axiosInstance from ".";

export const getAllClients = async (clientDetails) => {
    try {
        const response = await axiosInstance.post('/admin/client/getall', clientDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addClient = async (clientDetails) => {
    try {
        const response = await axiosInstance.post('/admin/client/create', clientDetails, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data
    } catch (error) {
        throw error
    }
}

export const deleteClient = async (clientId) => {
    try {
        const response = await axiosInstance.delete(`/admin/client/${clientId}`)
        return response.data
    } catch (error) {
        throw error
    }
}

export const updateClient = async (clientId, clientDetails) => {
    try {
        const response = await axiosInstance.put(`/admin/client/${clientId}`, clientDetails, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data
    } catch (error) {
        throw error
    }
}

export const checkSubdomainAvailable = async (clientDetails) => {
    try {
        const response = await axiosInstance.post('/admin/client/subdomainavailability', clientDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
};