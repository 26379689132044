import axiosInstance from ".";

export const getBranchByClient = async (clientId) => {
    try {
        const response = await axiosInstance.get(`/admin/branch?clientId=${clientId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getBranchById = async (id) => {
    try {
        const response = await axiosInstance.get(`/admin/branch/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const addBranch = async (branchDetails) => {
    try {
        const response = await axiosInstance.post('/admin/branch/create', branchDetails, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data
    } catch (error) {
        throw error
    }
}

export const updateBranch = async (branchId, branchDetails) => {
    try {
        const response = await axiosInstance.put(`/admin/branch/${branchId}`, branchDetails, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data
    } catch (error) {
        throw error
    }
}

export const deleteBranch = async (branchId) => {
    try {
        const response = await axiosInstance.delete(`/admin/branch/${branchId}`)
        return response.data
    } catch (error) {
        throw error
    }
}