// component
import SvgColor from "../../../components/svg-color";
// mui icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import FilterVintageIcon from "@mui/icons-material/FilterVintage";
import TaskIcon from "@mui/icons-material/Task";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessIcon from "@mui/icons-material/Business";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentIcon from "@mui/icons-material/Payment";
import SettingsIcon from '@mui/icons-material/Settings';
import SuperadminSettings from "../../../pages/SuperadminSettings";
import {
  Dashboard,
  Client,
  Branches,
  Floor,
  ServiceCategory,
  Rooms,
  Superadmin,
  Service,
  User,
  Team,
  Checkout,
  Customer,
  Taskreport,
  Invoice,
  Plan,
} from "../../../pages";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/superadmin/app/dashboard",
    icon: <DashboardIcon />,
    element: <Dashboard />,
  },
  {
    title: "Customer",
    path: "/superadmin/app/customer",
    icon: <SupportAgentIcon />,
    element: <Customer />,
  },
  {
    title: "Check-in",
    path: "/superadmin/app/checkin",
    icon: <TransferWithinAStationIcon />,
    element: <Checkout />,
  },
  {
    title: "Setup",
    path: "/superadmin/app/branch",
    element: <Branches />,
    icon: <RoomPreferencesIcon />,
    nested: [
      {
        title: "Branch",
        path: "/superadmin/app/branch",
        icon: <FilterVintageIcon />,
        element: <Branches />,
      },
      {
        title: "Floor",
        path: "/superadmin/app/floor",
        icon: <BusinessIcon />,
        element: <Floor />,
      },
      {
        title: "Room",
        path: "/superadmin/app/room",
        icon: <BedroomParentIcon />,
        element: <Rooms />,
      },
      {
        title: "Service Category",
        path: "/superadmin/app/service-category",
        icon: <TransferWithinAStationIcon />,
        element: <ServiceCategory />,
      },
      {
        title: "Service",
        path: "/superadmin/app/service",
        icon: <MiscellaneousServicesIcon />,
        element: <Service />,
      },
      {
        title: "Team",
        path: "/superadmin/app/team",
        icon: <GroupsIcon />,
        element: <Team />,
      },
    ],
  },
  {
    title: "User",
    path: "/superadmin/app/user",
    icon: <PersonIcon />,
    element: <User />,
  },
  {
    title: "Service Request Report",
    path: "/superadmin/app/taskreport",
    icon: <TaskIcon />,
    element: <Taskreport />,
  },
  {
    title: "Invoice",
    path: "/superadmin/app/invoice",
    icon: <ReceiptIcon />,
  },
  {
    title: "Superadmin service",
    path: "/superadmin/app/client",
    element: <Client />,
    icon: <RoomPreferencesIcon />,
    nested: [
      {
        title: "Client",
        path: "/superadmin/app/client",
        icon: <CorporateFareIcon />,
        element: <Client />,
      },
      {
        title: "Superadmin",
        path: "/superadmin/app/superadmin",
        icon: <ManageAccountsIcon />,
        element: <Superadmin />,
      },
      {
        title: "Billing Plan",
        path: "/superadmin/app/plan",
        icon: <PaymentIcon />,
        element: <Plan />,
      },
      {
        title: "Settings",
        path: "/superadmin/app/superadminsettings",
        icon: <SettingsIcon />,
        element: <SuperadminSettings />,
      },
    ],
  },
];

export default navConfig;
