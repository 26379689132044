import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        localStorage.setItem("appPath",JSON.stringify(location.pathname));

        const user = localStorage.getItem('user');
        if (!user) {
            // navigate("/", {replace: true});
            navigate("/superadmin", {replace: true});
        }
    }, []);
    return (
        <div>
            {children}
        </div>
    )
}

export default ProtectedRoute;