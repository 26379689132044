import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Box,
  Button,
  Card,
  Link,
  Container,
  Grid,
  Input,
  Avatar,
  IconButton,
  Drawer,
  Stack,
  TextField,
  Tooltip,
  Typography,
  InputAdornment,
  Select,
} from "@mui/material";
import Iconify from "../../components/iconify/Iconify";
import { toast } from "react-hot-toast";
import { DataGrid } from "@mui/x-data-grid";
import palette from "../../theme/palette";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import SearchInput from "../../components/SearchInput";
import deleteIcon from "../../assets/icons/trash.png";
import EditIcon from "../../assets/icons/EditIcon";
import { loginUserType } from "../../utils/userType";
import { useClient } from "../../context/ClientProvider";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import * as Yup from "yup";

import {
  getUsersByBranch,
  addUser,
  deleteUser,
  updateUser,
} from "../../api/user";
import { useFormik } from "formik";
import { IconCross, IconPlus } from "../../assets/icons";
import ContactInfoPopover from "../../components/common/ContactInfoPopover";

const createUserValidationSchema = Yup.object().shape({
  userName: Yup.string().required("User name is required"),
  userFirstName: Yup.string().required("User First Name is required"),
  userLastName: Yup.string().required("User Last Name is required"),
  userContactNumber: Yup.string()
    .required("User Contact Number is required")
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
  userWhatsappNumber: Yup.string()
    .optional()
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
  userPassword: Yup.string().required("User password is required"),
  userType: Yup.string().required("User type is required"),
  userIsActive: Yup.boolean().required("User active status is required"),
  branchId: Yup.number().optional("BranchId is optional"),
  userEmail: Yup.string().email("Invalid email format").nullable().optional(),
  userImageFile: Yup.mixed().test(
    "file",
    "Please select a valid file",
    (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return true;
    }
  ),
});

const editUserValidationSchema = Yup.object().shape({
  userName: Yup.string().required("User name is required"),
  userFirstName: Yup.string().required("User First Name is required"),
  userLastName: Yup.string().required("User Last Name is required"),
  userContactNumber: Yup.string()
    .required("User Contact Number is required")
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
  userWhatsappNumber: Yup.string()
    .optional()
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
  userPassword: Yup.string().optional("User password"),
  userType: Yup.string().optional(),
  userIsActive: Yup.boolean().required("User active status is required"),
  userEmail: Yup.string().email("Invalid email format").nullable().optional(),
  userImageFile: Yup.mixed().test(
    "file",
    "Please select a valid file",
    (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return true;
    }
  ),
});

const User = () => {
  const isSmallScreen = useIsSmallScreen();
  //const { activeGlobalClient, activeGlobalBranch } = useClient();
  const [searchUserValue, setSearchUserValue] = useState("");
  const [modalMode, setModalmode] = useState("add");
  const [modalOpen, setModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [currentUserData, setCurrentUserData] = useState([]);
  const { activeGlobalClient, activeGlobalBranch } = useClient();
  const [showPassword, setShowPassword] = useState(false);
  const localUserType = localStorage.getItem("userType");
  const userType = JSON.parse(localUserType);

  // delete modal
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    status: false,
    deleteId: null,
  });
  const handleModalClose = () => setModalOpen(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [contactInfo, setContactInfo] = useState(null);
  const [rowClicked, setRowClicked] = useState(null);

  const showContacts = (event, row) => {
    setAnchorEl(event.currentTarget);
    setContactInfo(row);
    setRowClicked(row.id);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setRowClicked(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverProps = {
    id: openPopover ? "simple-popover" : undefined,
    open: openPopover,
    anchorEl: anchorEl,
    handleClose: handleClosePopover,
    contactInfo: contactInfo,
  };

  // users data fetched when a branch is selected
  const usersData = async () => {
    if (activeGlobalBranch) {
      try {
        const response = await getUsersByBranch(activeGlobalBranch?.id);
        setUsers(response);
      } catch (error) {
        console.log("Error in fetching users data for branch id ", error);
      }
    }
  };

  useEffect(() => {
    activeGlobalBranch && usersData();
  }, [activeGlobalBranch]);

  const formik = useFormik({
    initialValues: {
      userName: "",
      userFirstName: "",
      userLastName: "",
      userContactNumber: "",
      userWhatsappNumber: "",
      userPassword: "",
      userType:
        userType === loginUserType.SUPER_ADMIN
          ? "admin"
          : userType === loginUserType.ADMIN
          ? "supervisor"
          : "staff",
      userIsActive: true,
      branchId: "",
      userEmail: "",
      userImageFile: undefined,
    },
    validationSchema:
      modalMode === "add"
        ? createUserValidationSchema
        : editUserValidationSchema,
    onSubmit: async (values) => {
      const userDetails = {
        userName: values.userName,
        firstName: values.userFirstName,
        lastName: values.userLastName,
        contactNumber: values.userContactNumber,
        whatsappNumber: values.userWhatsappNumber,
        password: values.userPassword,
        type: values.userType,
        isActive: values.userIsActive,
        email: values.userEmail ? values.userEmail : null,
      };
      try {
        if (values.userImageFile) {
          userDetails.file = values.userImageFile;
        }
        if (modalMode === "add" ) {
          if (activeGlobalBranch) {
            userDetails.branchId = activeGlobalBranch.id;
          }
          if (
            userType === loginUserType.SUPER_ADMIN ||
            userType === loginUserType.ADMIN
          ) {
            userDetails.clientId = activeGlobalClient?.id;
          }
          const response = await addUser(userDetails);
          response && toast.success("New User added successfully");
          formik.resetForm();
          handleModalClose();
          usersData();
        } else {
          updateUsersData(userDetails);
        }
      } catch (error) {
        if(error?.response?.data?.statusCode === 409){
          toast.error(error?.response?.data?.message);
        } else if(error?.response?.data?.statusCode === 400){
          toast.error(error?.response?.data?.message?.message[0]);
        } else {
          toast.error(error.response?.data?.message);
        }
      }
    },
  });

  useEffect(() => {
    if (modalMode ==='add' && formik.values.userName) {
      formik.setFieldValue('userPassword', formik.values.userName +"@123");
    }
  }, [formik.values.userName, formik.setFieldValue]);
  

  const deleteUsersData = async () => {
    const { deleteId } = openDeleteDialog;
    try {
      const updatedUsers = users.filter((user) => user.id !== deleteId);
      const response = await deleteUser(deleteId);
      if (response) {
        setUsers(updatedUsers);
        toast.success("User deleted successfully");
      }
    } catch (error) {
      toast.error(error.response?.data?.message[0]);
    }
  };

  // update users data
  const updateUsersData = async (newUserDetails) => {
    const updatedUserDetails = {};
    updatedUserDetails.type = newUserDetails.type;
    if (newUserDetails.userName !== currentUserData.userName) {
      updatedUserDetails.userName = newUserDetails.userName;
    }
    if (newUserDetails.firstName !== currentUserData.firstName) {
      updatedUserDetails.firstName = newUserDetails.firstName;
    }
    if (newUserDetails.lastName !== currentUserData.lastName) {
      updatedUserDetails.lastName = newUserDetails.lastName;
    }
    if (newUserDetails.contactNumber !== currentUserData.contactNumber) {
      updatedUserDetails.contactNumber = newUserDetails.contactNumber;
    }
    if (newUserDetails.whatsappNumber !== currentUserData.whatsappNumber) {
      updatedUserDetails.whatsappNumber = newUserDetails.whatsappNumber;
    }
    if (newUserDetails.isActive !== currentUserData.isActive) {
      updatedUserDetails.isActive = newUserDetails.isActive;
    }
    if (newUserDetails.email !== currentUserData.email) {
      updatedUserDetails.email = newUserDetails.email;
    }
    if (newUserDetails.file) {
      updatedUserDetails.file = newUserDetails.file;
    }
    if (userType === loginUserType.SUPER_ADMIN && newUserDetails.password){
      updatedUserDetails.password = newUserDetails.password;
    }
    try {
      setIsUserLoading(true);
      const response = await updateUser(currentUserData.id, updatedUserDetails);
      if (response) {
        setIsUserLoading(false);
        setUsers((previousUsers) =>
          previousUsers.map((users) =>
            users.id === currentUserData.id
              ? {
                  ...users,
                  id: currentUserData.id,
                  userName: newUserDetails.userName,
                  firstName: newUserDetails.userFirstName,
                  lastName: newUserDetails.userLastName,
                  contactNumber: newUserDetails.userContactNumber,
                  whatsappNumber: newUserDetails.userWhatsappNumber,
                  type: newUserDetails.userType,
                  isActive: newUserDetails.isActive,
                  email: newUserDetails.email,
                  file: newUserDetails.file,
                  password: userType === loginUserType.SUPER_ADMIN ? newUserDetails.password : null 
                }
              : users
          )
        );
        toast.success("User updated successfully");
        usersData();
        handleModalClose();
      }
    } catch (error) {
      if (error.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message?.message[0]);
      } else if (error.response?.data?.statusCode === 409) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error(error.response?.data?.message);
      }
      setIsUserLoading(false);
    }
  };

  const handleModalOpen = (currentData) => {
    if (activeGlobalBranch) {
      setModalOpen(true);
      if (currentData.userName) {
        setModalmode("edit");
        setCurrentUserData(currentData);
        formik.setValues({
          userName: currentData.userName,
          userFirstName: currentData.firstName,
          userLastName: currentData.lastName,
          userContactNumber: currentData.contactNumber,
          userWhatsappNumber: currentData.whatsappNumber,
          userType: currentData.type,
          userIsActive: currentData.isActive,
          userEmail: currentData.email,
          userImageFile: currentData.userImageFile,
          // userPassword: userType === loginUserType.SUPER_ADMIN ? currentData.password : null,
        });
      } else {
        {
          setModalmode("add");
          formik.resetForm();
        }
      }
    } else {
      toast.error("Select Client and Branch");
    }
  };

  const columns = [
    {
      field: "image",
      headerName: "",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                alt="User Img"
                src={`${process.env.REACT_APP_API_BASE_IMAGE_URL}/user/${row?.imageUrl}`}
                sx={{ width: 30, height: 30 }}
              />
              <Tooltip title="Contact Info">
                <IconButton
                  aria-describedby={popoverProps.id}
                  variant="contained"
                  onClick={(e) => showContacts(e, row)}
                >
                  <img
                    height={18}
                    src={row.id === rowClicked ? IconCross : IconPlus}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        );
      },
    },
    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "type",
      headerName: "User Type",
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "isActive",
      headerName: "IsActive",
      flex: 0.4,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,

      renderCell: ({ row }) => {
        const handleDeleteDialog = (id) => {
          setOpenDeleteDialog({
            ...openDeleteDialog,
            status: true,
            deleteId: id,
          });
        };
        const handleDeleteClose = () => {
          setOpenDeleteDialog({ ...openDeleteDialog, status: false });
        };
        const handleConfirmDelete = () => {
          deleteUsersData();
          handleDeleteClose();
        };
        return (
          <Stack direction="row" spacing={0}>
            {/* Edit Icon */}
            <Tooltip title="Edit">
              <IconButton
                size="large"
                color="primary"
                onClick={() => handleModalOpen(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            {/* Delete Icon */}
            <Tooltip title="Delete">
              <IconButton
                size="large"
                color="error"
                onClick={() => handleDeleteDialog(row.id)}
              >
                <img height="22px" src={deleteIcon} />
              </IconButton>
            </Tooltip>

            {/* Confirmation Modal */}
            <ConfirmationModal
              open={openDeleteDialog.status}
              onClose={handleDeleteClose}
              onConfirm={handleConfirmDelete}
              iconName="user"
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <div>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          sx={{ border: "", ...(isSmallScreen && { marginTop: 2 }) }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            User
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleModalOpen}
          >
            New User
          </Button>
        </Stack>
        <Card sx={{ mt: 3, overflowX: "auto" }}>
          <SearchInput
            sx={{ ml: 1, mt: 1 }}
            label="user"
            value={searchUserValue}
            onChange={(e) => {
              setSearchUserValue(e.target.value);
            }}
          />
          <DataGrid
            rows={users?.filter((user) =>
              Object.values(user).some(
                (value) =>
                  value !== null &&
                  value
                    ?.toString()
                    .toLowerCase()
                    .includes(searchUserValue.toLowerCase())
              )
            )}
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 50]}
            disableRowSelectionOnClick
            rowHeight={55}
            autoHeight={true}
            sx={{
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row": {
                position: "relative",
              },
              // header-bottom
              "& .MuiDataGrid-row:first-child::before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // each-row-bottom
              "& .MuiDataGrid-row::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // footer-bottom
              "& .MuiDataGrid-row:last-child::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              minWidth: "678px",
              width: "100%",
              height: users.length === 0 ? "500px" : "auto",
              overflowX: "auto",
            }}
            localeText={{ noRowsLabel: "No user found" }}
          />
        </Card>
        <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "background.paper",
                width: 400,
                marginY: 2,
                ...(isSmallScreen && { width: 280 }),
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Typography variant="h6" gutterBottom mb={3}>
                  {modalMode === "add" ? `Add User` : `Edit User`}
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ textAlign: "center" }}>
                  <Avatar
                    alt="User Avatar"
                    src={
                      formik.values.userImageFile
                        ? URL.createObjectURL(formik.values.userImageFile)
                        : modalMode === "edit" &&
                          currentUserData &&
                          currentUserData.imageUrl
                        ? `${process.env.REACT_APP_API_BASE_IMAGE_URL}/user/${currentUserData.imageUrl}`
                        : "/default-avatar.png"
                    }
                    sx={{
                      width: 70,
                      height: 70,
                      borderRadius: "50%",
                      mx: "auto",
                      mb: 2,
                    }}
                    onClick={() => {
                      document.getElementById("userImageFile").click();
                    }}
                  />
                  <Input
                    type="file"
                    accept="image/*"
                    id="userImageFile"
                    name="userImageFile"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "userImageFile",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="User Name *"
                      name="userName"
                      type="text"
                      {...formik.getFieldProps("userName")}
                    ></TextField>
                    {formik.touched.userName && formik.errors.userName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.userName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="First Name *"
                      name="firstName"
                      type="text"
                      {...formik.getFieldProps("userFirstName")}
                    ></TextField>
                    {formik.touched.userFirstName &&
                    formik.errors.userFirstName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.userFirstName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Last Name *"
                      name="lastName"
                      type="text"
                      {...formik.getFieldProps("userLastName")}
                    ></TextField>
                    {formik.touched.userLastName &&
                    formik.errors.userLastName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.userLastName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      type="text"
                      {...formik.getFieldProps("userEmail")}
                    ></TextField>
                    {formik.touched.userEmail && formik.errors.userEmail ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.userEmail}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Contact Number *"
                      name="contactNumber"
                      type="text"
                      {...formik.getFieldProps("userContactNumber")}
                    ></TextField>
                    {formik.touched.userContactNumber &&
                    formik.errors.userContactNumber ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.userContactNumber}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Whatsapp Number"
                      name="whatsappNumber"
                      type="text"
                      {...formik.getFieldProps("userWhatsappNumber")}
                    ></TextField>
                    {formik.touched.userWhatsappNumber &&
                    formik.errors.userWhatsappNumber ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.userWhatsappNumber}
                      </Typography>
                    ) : null}
                  </Grid>
                  {modalMode === "add" || userType === loginUserType.SUPER_ADMIN ? (
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label="Password *"
                        name="password"
                        placeholder="Password"
                        type={!showPassword ? "text" : "password"}
                        {...formik.getFieldProps("userPassword")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!showPassword ? (
                                <Visibility onClick={handleTogglePassword} />
                              ) : (
                                <VisibilityOff onClick={handleTogglePassword} />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: "100%",
                        }}
                        inputProps={{ style: { height: "21px" } }}
                      ></TextField>
                      {formik.touched.userPassword &&
                      formik.errors.userPassword ? (
                        <Typography
                          sx={{
                            color: "red",
                            variant: "body2",
                            fontSize: "12px",
                          }}
                        >
                          {formik.errors.userPassword}
                        </Typography>
                      ) : null}
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="UserType"
                      name="userType"
                      select
                      value={
                        formik.values.userType
                          ? formik.values.userType
                          : "admin"
                      }
                      disabled={
                        (modalMode === "edit" &&
                          userType !== loginUserType.SUPER_ADMIN) ??
                        true
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {userType === loginUserType.SUPER_ADMIN ? (
                        <MenuItem value={"admin"}>Admin</MenuItem>
                      ) : null}
                      {userType === loginUserType.SUPER_ADMIN ||
                      userType === loginUserType.ADMIN ? (
                        <MenuItem value={"supervisor"}>Supervisor</MenuItem>
                      ) : null}
                      <MenuItem value={"staff"}>Staff</MenuItem>
                    </TextField>
                    {formik.touched.userType && formik.errors.userType ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.userType}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="IsActive"
                      name="userIsActive"
                      select
                      value={formik.values.userIsActive}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </TextField>
                    {formik.touched.userIsActive &&
                    formik.errors.userIsActive ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.userIsActive}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isUserLoading && true}
                  >
                    {modalMode === "add"
                      ? "Add"
                      : isUserLoading
                      ? "Updating..."
                      : "Update"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: palette.grey[500],
                      "&:hover": {
                        backgroundColor: palette.grey[600],
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>
        <ContactInfoPopover {...popoverProps} />
      </Container>
    </div>
  );
};

export default User;
