import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Icon, IconButton, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { ExpandLess } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other} sx={{ border: '1px solid redd' }}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, nested } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}
        exact
        sx={{
          '&.active': path ? {  // Check if path is defined
            color: '#fff',
            bgcolor: '#A94C9B',
            fontWeight: 'fontWeightBold',
          } : {},
          '&:hover':{
            bgcolor: '#AE74A5',
            color: '#fff',
          },
          // border: '1px solid red',
          // height: '48px',
          margin: '8px 0',
          boxShadow: '0px 4px 4px 0px #0000001F',

        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

        <ListItemText disableTypography primary={title} />
        {nested && (
          <IconButton onClick={handleClick} size="small">
            {open ? <ExpandLess /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </StyledNavItem>

      {nested && open && (
        <List sx={{ pl: 4 }}>
          {nested.map((subItem) => (
            <NavItem key={subItem.title} item={subItem} />
          ))}
        </List>
      )}
    </>
  );
}
