import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
// import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import navConfigAdmin from './adminConfig';
import SimpleBar from 'simplebar-react';
import NavSearch from '../../../components/nav-section/NavSearch';
import gcLogo from '../../../assets/images/Login_logo.png';
import { useAuth } from '../../../context/AuthProvider';
import navConfigSupervisor from './supervisorConfig';

// ----------------------------------------------------------------------

const NAV_WIDTH = 250;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const userType = JSON.parse(localStorage.getItem('userType'));
  const { pathname } = useLocation();

  const [searchResults, setSearchResults] = useState(userType === "admin" ? navConfigAdmin : userType === "supervisor" ? navConfigSupervisor : navConfig);

  const handleSearch = (query) => {
    // console.log('Search query:', query);
    const filteredResults = userType === "super_admin"
      ? navConfig.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      )
      : navConfigAdmin.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      );
    setSearchResults(filteredResults);
  };

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Box sx={{ border: '' }}>
      {/* sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        border: '2px solid red',
      }}
    > */}
      {/* <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box> */}

      <Box sx={{ mb: 2, mx: 0, border: '' }}>
        <Link underline="none">
          <StyledAccount sx={{ background: 'none' }}>
            <Box>
              <img src={gcLogo} />
            </Box>
          </StyledAccount>
        </Link>
        <NavSearch onSearch={handleSearch} />
      </Box>


      <NavSection data={searchResults} />

      <Box sx={{ flexGrow: 1 }} />


    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
        display: openNav ? 'none' : 'block',
        border: ''
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
          {/* <SimpleBar style={{ maxHeight: '100%', height: '100%' }}>{renderContent}</SimpleBar> */}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
          {/* <SimpleBar style={{ maxHeight: '100%', height: '100%' }}>{renderContent}</SimpleBar> */}
        </Drawer>
      )}
    </Box>
  );
}
