import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button, Tooltip } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import { useState } from 'react';
import ClientBranchModal from '../../../components/Modal/ClientBranchModal';
import HeaderInfo from '../../../components/HeaderInfo';
// import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
  navOpen: PropTypes.bool,
  toggleNav: PropTypes.func
};

export default function Header({ onOpenNav, navOpen, toggleNav }) {
  const localUserType = localStorage.getItem('userType');
  const userType = JSON.parse(localUserType);
  const [modal, setModal] = useState(false);

  const NAV_WIDTH = navOpen ? 0 : 250;

  const HEADER_MOBILE = 64;

  const HEADER_DESKTOP = 65;

  const StyledRoot = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
    borderBottom: '1px solid #ccc'
  }));

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: HEADER_DESKTOP,
      padding: theme.spacing(0, 1),
    },
    border: ''
  }));

  const openModal = () => {
    setModal(true);
  }

  const modalClose = () => {
    setModal(false);
  }

  return (
    <StyledRoot sx={{
      // width: navOpen ? `calc(100% - ${NAV_WIDTH + 1}px)` : '100%',
      // border: "2px solid orange"
    }}>
      <StyledToolbar>
        {/* <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton> */}

        <IconButton
          onClick={toggleNav}
          sx={{
            color: 'text.primary',
          }}
        >
          <Iconify icon='eva:menu-2-fill' />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 0, border: '2px solid redd' }} />

        <Box sx={{ flexGrow: 1, display: "flex" }}>
          <HeaderInfo />
          {/* {userType === 'super_admin' || userType === 'admin' ? <Box ml={1}>
            <Tooltip title="Update">
              <IconButton size='medium' color='primary' onClick={() => openModal()}>
                <Iconify icon={'eva:edit-fill'} />
              </IconButton>
            </Tooltip>
          </Box> : null}
          {
            modal && <ClientBranchModal modalOpen={modal} onClose={modalClose} />
          } */}
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
          sx={{ border: '1px solid redz' }}
        >
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
