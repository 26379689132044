import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  MenuItem,
  Avatar,
  Drawer,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Input,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Iconify from "../../components/iconify/Iconify";
import { toast } from "react-hot-toast";
import { DataGrid } from "@mui/x-data-grid";
import palette from "../../theme/palette";
import ClearIcon from "@mui/icons-material/Clear";
import SearchInput from "../../components/SearchInput";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { useClient } from "../../context/ClientProvider";
import deleteIcon from "../../assets/icons/trash.png";
import EditIcon from "../../assets/icons/EditIcon";
import { loginUserType } from "../../utils/userType";

// apis
import {
  getServiceCategoryByBranch,
  getServiceCategory,
} from "../../api/serviceCategory";
import {
  getServicesByServiceCategory,
  addService,
  deleteService,
  updateService,
  getServicesByBranch,
  getDefaultServicesByServiceCategory,
} from "../../api/services";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { blueGrey } from "@mui/material/colors";
import { useIsSmallScreen } from "../../hooks/useResponsive";

const serviceValidationSchema = Yup.object().shape({
  serviceName: Yup.string().required("Service Name is required"),
  serviceDescription: Yup.string().optional("Service Description"),
  serviceIsActive: Yup.boolean().required(
    "Service IsActive status is required"
  ),
  serviceBranchId: Yup.number().optional("Service Branch Id is optional"),
  serviceImageFile: Yup.mixed().test(
    "file",
    "Please select a valid file",
    (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return true;
    }
  ),
  serviceDuration: Yup.number().required("Service duration is required"),
});

const Service = () => {
  const isSmallScreen = useIsSmallScreen();
  const [openServiceCategory, setOpenServiceCategory] = useState(false);
  const [serviceCategoryList, setServiceCategoryList] = useState([]);
  const [selectedServiceCategory, setSelectedServiceCategory] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    status: false,
    deleteId: null,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [searchServiceValue, setSearchServiceValue] = useState("");
  const [modalMode, setModalMode] = useState("add");
  const [currentServicesData, setCurrentServicesData] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceCategoryType, setServiceCategoryType] = useState("");
  const [clicked, setClicked] = useState(false);

  const { activeGlobalBranch } = useClient();
  const localUserType = localStorage.getItem("userType");
  const userType = JSON.parse(localUserType);

  // Autocomplete
  const loadingServiceCategory =
    openServiceCategory && serviceCategoryList?.length === 0;

  const handleModalClose = () => {
    setModalOpen(false);
    setServiceCategoryType("");
  };

  const handleModalOpen = (serviceData) => {
    if (serviceData === "defaultService") {
      setServiceCategoryType("default");
    }
    if (serviceData.name) {
      setModalOpen(true);
      setModalMode("edit");
      setCurrentServicesData(serviceData);
      formik.setValues({
        serviceName: serviceData.name,
        serviceDescription: serviceData.description,
        serviceIsActive: serviceData.isActive,
        serviceImageFile: serviceData.serviceImageFile,
        serviceDuration: serviceData.serviceDuration,
      });
    } else {
      if (selectedServiceCategory) {
        setModalOpen(true);
        setModalMode("add");
        formik.resetForm();
      } else {
        toast.error("Please select a service category");
      }
    }
  };

  const serviceDataByBranch = async () => {
    try {
      if (activeGlobalBranch?.id) {
        const response = await getServicesByBranch(activeGlobalBranch?.id);
        setServices(response.sort((a, b) => a.name.localeCompare(b.name)));
      }
    } catch (error) {
      console.log("error in fetching services by branch", error);
    }
  };

  // get services data by serviceCategoryId
  const servicesDataByServiceCategory = async () => {
    try {
      setClicked(false);
      const response = await getServicesByServiceCategory(
        selectedServiceCategory.id
      );
      setServices(response.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      console.log("error in fetching services by service category", error);
    }
  };

  const defaultServicesDataByServiceCategory = async () => {
    try {
      const response = await getDefaultServicesByServiceCategory(
        selectedServiceCategory?.parentId
      );
      setServices(response.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      console.log(
        "error in fetching default services by service category",
        error
      );
    }
  };

  // update services data
  const updateServicesData = async (newServiceDetails) => {
    const updatedServiceDetails = {};
    if (newServiceDetails.name !== currentServicesData.name) {
      updatedServiceDetails.name = newServiceDetails.name;
    }
    if (newServiceDetails.description !== currentServicesData.description) {
      updatedServiceDetails.description = newServiceDetails.description;
    }
    if (newServiceDetails.isActive !== currentServicesData.isActive) {
      updatedServiceDetails.isActive = newServiceDetails.isActive;
    }
    if (newServiceDetails.file) {
      updatedServiceDetails.file = newServiceDetails.file;
    }
    if (
      newServiceDetails.serviceDuration !== currentServicesData.serviceDuration
    ) {
      updatedServiceDetails.serviceDuration = newServiceDetails.serviceDuration;
    }
    try {
      const isNotingToUpdate = Object.keys(updatedServiceDetails).length === 0;
      const response =
        !isNotingToUpdate &&
        (await updateService(currentServicesData.id, updatedServiceDetails));
      if (response) {
        // const response = await updateService(currentServicesData.id, updatedServiceDetails)
        //if (response) {
        setServices((previousServices) =>
          previousServices.map((services) =>
            services.id === currentServicesData.id
              ? {
                  ...services,
                  id: currentServicesData.id,
                  name: newServiceDetails.name,
                  desc: newServiceDetails.name,
                  isActive: newServiceDetails.isActive,
                  file: newServiceDetails.file,
                  serviceDuration: newServiceDetails.serviceDuration,
                }
              : services
          )
        );
        toast.success("Service updated successfully");
        clicked
          ? defaultServicesDataByServiceCategory()
          : servicesDataByServiceCategory();
        handleModalClose();
      } else {
        toast.success("Service updated successfully");
        handleModalClose();
      }
    } catch (error) {
      if (error.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message?.message[0]);
      } else if (error.response?.data?.statusCode === 409) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error(error.response?.data?.message);
      }
    }
  };

  // delete Service data
  const deleteServiceData = async () => {
    const { deleteId } = openDeleteDialog;
    try {
      const serviceAfterDelete = services?.filter(
        (service) => service.id !== deleteId
      );
      const response = await deleteService(deleteId);
      if (response) {
        setServices(serviceAfterDelete);
        toast.success("Service deleted succefully");
      }
    } catch (error) {
      toast.error("Error on deleting service");
    }
  };

  // create a new service
  const formik = useFormik({
    initialValues: {
      serviceName: "",
      serviceDescription: "",
      serviceIsActive: true,
      serviceImageFile: undefined,
      serviceDuration: "",
    },
    validationSchema: serviceValidationSchema,
    onSubmit: async (values) => {
      const serviceDetails = {
        name: values.serviceName,
        description: values.serviceDescription,
        isActive: values.serviceIsActive,
        serviceDuration: values.serviceDuration,
      };
      try {
        if (values.serviceImageFile) {
          serviceDetails.file = values.serviceImageFile;
        }
        if (modalMode === "add") {
          serviceDetails.serviceCategoryId = serviceCategoryType
            ? selectedServiceCategory.parentId
            : selectedServiceCategory.id;
          const response = await addService(serviceDetails);
          response && toast.success("Service added successfully");
          formik.resetForm();
          handleModalClose();
          servicesDataByServiceCategory();
        } else {
          updateServicesData(serviceDetails);
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
      }
    },
  });

  const loadDefaultServices = () => {
    defaultServicesDataByServiceCategory();
    setClicked(true);
  };

  // service category data when a branch is selected or not
  useEffect(() => {
    if (activeGlobalBranch) {
      (async () => {
        try {
          const response = await getServiceCategoryByBranch(
            activeGlobalBranch.id
          );
          setServiceCategoryList(response);
        } catch (error) {
          console.error("Error in fetching service category data: ", error);
        }
      })();
    } else {
      (async () => {
        try {
          const response = await getServiceCategory();
          setServiceCategoryList(response);
        } catch (error) {
          console.error("Error in fetching service category data: ", error);
        }
      })();
    }
    setSelectedServiceCategory(null);
    setServices([]);
  }, [activeGlobalBranch]);

  useEffect(() => {
    selectedServiceCategory && servicesDataByServiceCategory();
  }, [selectedServiceCategory]);

  // get service data initially by branch
  useEffect(() => {
    serviceDataByBranch();
  }, [activeGlobalBranch]);

  const columns = [
    {
      field: "image",
      headerName: "",
      width: 5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <>
            <Avatar
              alt="Service Image"
              src={`${process.env.REACT_APP_API_BASE_IMAGE_URL}/service/${row?.imageName}`}
              sx={{ width: 30, height: 30 }}
            />
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      filterable: false,
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: "serviceDuration",
      headerName: "Service Duration(min)",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1.2,
    },
    {
      field: "isActive",
      headerName: "IsActive",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "Actions",
      // flex: 1,
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const handleDeleteDialog = (id) => {
          setOpenDeleteDialog({
            ...openDeleteDialog,
            status: true,
            deleteId: id,
          });
        };
        const handleDeleteClose = () => {
          setOpenDeleteDialog({ ...openDeleteDialog, status: false });
        };
        const handleConfirmDelete = () => {
          deleteServiceData();
          handleDeleteClose();
        };
        return (
          <Stack direction="row" spacing={0}>
            {/* Edit Icon */}
            <Tooltip title="Edit">
              <IconButton
                size="large"
                color="primary"
                onClick={() => handleModalOpen(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            {/* Delete Icon */}
            <Tooltip title="Delete">
              <IconButton
                size="large"
                color="error"
                onClick={() => handleDeleteDialog(row.id)}
              >
                <img src={deleteIcon} />
              </IconButton>
            </Tooltip>
            {/* Confirmation Modal */}
            <ConfirmationModal
              open={openDeleteDialog.status}
              onClose={handleDeleteClose}
              onConfirm={handleConfirmDelete}
              iconName="service"
            />
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
        }}
      >
        <Stack
          mb={3}
          sx={{
            border: "",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            ...(isSmallScreen && { marginTop: 2, flexDirection: "column" }),
          }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            Service
          </Typography>
          <Stack direction="row" spacing={2}>
            {userType === loginUserType.SUPER_ADMIN &&
            selectedServiceCategory &&
            selectedServiceCategory?.parentId !== null ? (
              <>
                {clicked === false ? (
                  <Button
                    variant="contained"
                    startIcon={<Iconify icon="nonicons:loading-16" />}
                    onClick={loadDefaultServices}
                    sx={{
                      ...(isSmallScreen && {
                        fontSize: 8,
                        padding: "6px 10px",
                      }),
                    }}
                  >
                    Load Default Services
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    //endIcon={<Iconify icon="mdi:close" />}
                    startIcon={<Iconify icon="nonicons:loading-16" />}
                    onClick={servicesDataByServiceCategory}
                    color="secondary"
                    sx={{
                      ...(isSmallScreen && {
                        fontSize: 8,
                        padding: "6px 10px",
                      }),
                    }}
                  >
                    Load all Services
                  </Button>
                )}
              </>
            ) : null}
            {userType === loginUserType.SUPER_ADMIN &&
            selectedServiceCategory &&
            selectedServiceCategory?.parentId !== null ? (
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => handleModalOpen("defaultService")}
                sx={{
                  ...(isSmallScreen && { fontSize: 8, padding: "6px 10px" }),
                }}
              >
                New Default Service
              </Button>
            ) : null}
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleModalOpen}
              sx={{
                ...(isSmallScreen && { fontSize: 8, padding: "6px 10px" }),
              }}
            >
              New Service
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Autocomplete
            id="servicecategorylist"
            size="small"
            sx={{ width: 300 }}
            open={openServiceCategory}
            onOpen={() => {
              setOpenServiceCategory(true);
            }}
            onClose={() => {
              setOpenServiceCategory(false);
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <div
                {...props}
                style={{
                  color: option?.parentId === null ? "black" : "#A94C9B",
                }}
              >
                {option.name}
              </div>
            )}
            options={serviceCategoryList && serviceCategoryList}
            loading={loadingServiceCategory}
            onChange={(event, serviceCategoryValue, isClear) => {
              if (isClear === "clear") {
                serviceDataByBranch();
              }
              setSelectedServiceCategory(serviceCategoryValue);
            }}
            value={selectedServiceCategory}
            noOptionsText="No services"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Service Category"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingServiceCategory ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <Card sx={{ mt: 3, overflowX: "auto" }}>
          <SearchInput
            sx={{ ml: 1, mt: 1 }}
            label="service"
            value={searchServiceValue}
            onChange={(e) => {
              setSearchServiceValue(e.target.value);
            }}
          />
          <DataGrid
            rows={services?.filter((service) =>
              Object.values(service).some(
                (value) =>
                  value !== null &&
                  value
                    ?.toString()
                    .toLowerCase()
                    .includes(searchServiceValue.toLowerCase())
              )
            )}
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 50]}
            disableRowSelectionOnClick
            rowHeight={55}
            autoHeight={true}
            sx={{
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row": {
                position: "relative",
              },
              // header-bottom
              "& .MuiDataGrid-row:first-child::before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // each-row-bottom
              "& .MuiDataGrid-row::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // footer-bottom
              "& .MuiDataGrid-row:last-child::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              minWidth: "678px",
              width: "100%",
              overflowX: "auto",
              height: services?.length === 0 ? "500px" : "auto",
            }}
            localeText={{ noRowsLabel: "No service found" }}
          />
        </Card>
        <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "background.paper",
                width: 400,
                marginTop: "15px",
                ...(isSmallScreen && { width: 280 }),
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <Typography variant="h5" gutterBottom>
                  {modalMode === "add"
                    ? serviceCategoryType === "default"
                      ? `Add Default Service in ${selectedServiceCategory?.name}`
                      : `Add Service in ${selectedServiceCategory?.name}`
                    : "Edit Service"}
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ textAlign: "center" }}>
                  <Avatar
                    alt="Services Avatar"
                    src={
                      formik.values.serviceImageFile
                        ? URL.createObjectURL(formik.values.serviceImageFile)
                        : modalMode === "edit" &&
                          currentServicesData &&
                          currentServicesData.imageName
                        ? `${process.env.REACT_APP_API_BASE_IMAGE_URL}/service/${currentServicesData.imageName}`
                        : "/default-avatar.png"
                    }
                    sx={{
                      width: 70,
                      height: 70,
                      borderRadius: "50%",
                      mx: "auto",
                      mb: 2,
                    }}
                    onClick={() => {
                      document.getElementById("serviceImageFile").click();
                    }}
                  />
                  <Input
                    type="file"
                    accept="image/*"
                    id="serviceImageFile"
                    name="serviceImageFile"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "serviceImageFile",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Name *"
                      name="name"
                      type="text"
                      {...formik.getFieldProps("serviceName")}
                    />
                    {formik.touched.serviceName && formik.errors.serviceName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.serviceName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      type="text"
                      multiline
                      rows={3}
                      {...formik.getFieldProps("serviceDescription")}
                    />
                    {formik.touched.serviceDescription &&
                    formik.errors.serviceDescription ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.serviceDescription}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="IsActive"
                      name="serviceIsActive"
                      select
                      value={formik.values.serviceIsActive}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </TextField>
                    {formik.touched.serviceIsActive &&
                    formik.errors.serviceIsActive ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.serviceIsActive}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Service Time (min) *"
                      name="serviceDuration"
                      type="text"
                      {...formik.getFieldProps("serviceDuration")}
                    ></TextField>
                    {formik.touched.serviceDuration &&
                    formik.errors.serviceDuration ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.serviceDuration}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button type="submit" variant="contained" color="primary">
                    {modalMode === "add" ? "Add" : "Update"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: palette.grey[500],
                      "&:hover": {
                        backgroundColor: palette.grey[600],
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>
      </Container>
    </>
  );
};
export default Service;
