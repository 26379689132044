import React, { useState } from "react";
import AuthPageWrapper from "../../components/AuthPageWrapper";
import { Box } from "@mui/system";
import { TextField, Typography, Button } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import palette from "../../theme/palette";

import { sendOTP } from "../../api/resetpassword";

const mobileNumberValidationSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .required("Enter a valid mobileNumber")
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
});

const ForgotPassword = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
    },
    validationSchema: mobileNumberValidationSchema,
    onSubmit: async (values) => {
      const mobileNumberDetails = {
        contactNumber: values.mobileNumber,
      };

      try {
        const response = await sendOTP(mobileNumberDetails);
        if (response) {
          navigate("/validate-otp", {
            state: { mobileNumber: mobileNumberDetails.contactNumber },
          });
        } else {
          toast.error("Error in fetching mobile number");
        }
      } catch (error) {
        toast.error("Mobile Number Not Found");
      }
    },
  });

  return (
    <div>
      <AuthPageWrapper>
        <Box
          sx={{ flex: 1, display: "flex", flexDirection: "column", border: "" }}
        >
          <Box
            sx={{ flex: 1, display: "flex", flexDirection: "column", mb: 5 }}
          >
            <div style={{ textAlign: "left" }}>
              <Box sx={{ my: "1.5rem" }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 500,
                    fontSize: "1.625rem !important",
                    color: "rgba(47, 43, 61, 0.78)",
                    mb: "6px",
                  }}
                >
                  Forgot Password
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "rgba(47, 43, 61, 0.68)",
                    fontSize: "0.9375rem",
                  }}
                >
                  To proceed, please provide your mobile number for OTP
                  verification. We take your privacy seriously, and your mobile
                  number will only be used for the purpose of OTP verification.
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "rgba(47, 43, 61, 0.78)" }}
                  >
                    Please enter your mobileNumber
                  </Typography>
                  <TextField
                    id="outlined-userid-input"
                    type="text"
                    autoComplete="off"
                    {...formik.getFieldProps("mobileNumber")}
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                    inputProps={{ style: { height: "21px" } }}
                  />
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.mobileNumber}
                    </Typography>
                  ) : null}
                </Box>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ height: 40, fontSize: 14, width: "100%" }}
                    type="submit"
                  >
                    Send OTP
                  </Button>
                  <Box
                    display="flex"
                    justifyContent="center"
                    mt={2}
                    color={"rgba(47, 43, 61, 0.78)"}
                  >
                    <Typography variant="body1">
                      Back to sign-in page{" "}
                    </Typography>
                    <Link style={{ textDecoration: "none" }} to="/">
                      <Typography
                        variant="subtitle1"
                        ml={1}
                        sx={{ cursor: "pointer", color: palette.primary.main }}
                      >
                        Signin
                      </Typography>
                    </Link>
                  </Box>
                </div>
              </form>
            </div>
          </Box>
        </Box>
      </AuthPageWrapper>
    </div>
  );
};

export default ForgotPassword;
