import React, { useState } from 'react'
// mui
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


const NavSearch = ({ onSearch }) => {
    const [query, setQuery] = useState(null);
    const handleSearch = (e) => {
        const newQuery = e.target.value;
        onSearch(newQuery);
        setQuery(query)
    };
    return (
        <Box sx={{ paddingX: 1, mt: 1 }}>
            <TextField
                type="text"
                placeholder="Search menu..."
                value={query}
                onChange={handleSearch}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton 
                            // onClick={handleSearch}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={{
                    '& input': {
                        height: '7px',
                    },
                    '& button': {
                        height: '7px',
                    },
                }}
            />
        </Box>
    )
}

export default NavSearch