import axiosInstance from ".";

export const getFloorByBranch = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/admin/floors?branchId=${branchId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const addFloor = async (floorDetails) => {
    try {
        const response = await axiosInstance.post('/admin/floors', floorDetails);
        return response.data
    } catch (error) {
        throw error
    }
}

export const updateFloor = async (floorId, floorDetails) => {
    try {
        const response = await axiosInstance.put(`/admin/floors/${floorId}`, floorDetails);
        return response.data
    } catch (error) {
        throw error
    }
}

export const deleteFloor = async (floorId) => {
    try {
        const response = await axiosInstance.delete(`/admin/floors/${floorId}`)
        return response.data
    } catch (error) {
        throw error
    }
}