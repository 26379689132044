import React, { useEffect, useState } from "react";
import { getFinancialYear, getSettings, updateSettings } from "../../api/settings";
import toast from "react-hot-toast";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import FileOpenIcon from "@mui/icons-material/FileOpen";

import {
  Box,
  TextField,
  Button,
  IconButton,
  Container,
  Grid,
  Stack,
  Typography,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import palette from "../../theme/palette";
import moment from "moment";

const SuperadminSettings = () => {
  const [settings, setSettings] = useState({
    serviceProvicerName: "",
    gstNumber: "",
    address: "",
    contactNumber: "",
    email: "",
    declaration: "",
    termAndCondtion: "",
    financialYear: {},
  });

  const [settingsId, setSettingsId] = useState("");
  const [financialYear, setFinancialYear] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileKey, setFileKey] = useState(0);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file?.type !== "image/png" && file?.type !== "image/jpeg") {
      toast.error("Invalid file type");
    } else {
      setSelectedFile(file);
    }
  };

  const getSettingsData = async () => {
    try {
      const response = await getSettings();
      if (response) {
        setSettingsId(response[0].id);
        setSettings((prevValue) => ({
          ...prevValue,
          serviceProvicerName: response[0].serviceProvicerName,
          gstNumber: response[0].gstNumber,
          address: response[0].address,
          contactNumber: response[0].contactNumber,
          email: response[0].email,
          declaration: response[0].declaration,
          termAndCondtion: response[0].termAndCondtion,
          defaultImageUrl: response[0].defaultImageUrl,
          financialYear: response[0].financialYear,
        }));
      }
    } catch (error) {
      console.log("error in fetching settings", error);
    }
  };

  const getFinancialYearData = async () => {
    const response = await getFinancialYear();
    if (response) {
      setFinancialYear(response);
    }
  };

  useEffect(() => {
    getSettingsData();
    getFinancialYearData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const settingsDetail = {
      serviceProvicerName: settings.serviceProvicerName,
      gstNumber: settings.gstNumber,
      address: settings.address,
      contactNumber: settings.contactNumber,
      email: settings.email,
      declaration: settings.declaration,
      termAndCondtion: settings.termAndCondtion,
      financialYearId: settings.financialYear.id,
    };
    try {
      settings.type = "super_admin";
      if (selectedFile) {
        settingsDetail.file = selectedFile;
      }
      const response = await updateSettings(settingsId, settingsDetail);
      if (response) {
        setSettings((previoussettings) => ({
          ...previoussettings,
          serviceProvicerName: settings.serviceProvicerName,
          gstNumber: settings.gstNumber,
          address: settings.address,
          contactNumber: settings.contactNumber,
          email: settings.email,
          declaration: settings.declaration,
          termAndCondtion: settings.termAndCondtion,
          defaultImageUrl: settings.defaultImageUrl,
          financialYear: settings.financialYear,
        }));
        getSettingsData();
        setSelectedFile(null);
        toast.success(`settings updated successfully`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message?.message[0]);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "auto",
          justifyContent: "centre",
          alignItems: "centre",
          paddingLeft: "10px",
        }}
      >
        <Container maxWidth="m">
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                bgcolor: "background.paper",
                width: "auto",
                boxShadow: 10,
                padding: 5,
                marginRight: 5,
                borderRadius: 5,
              }}
            >
              <Stack>
                <Box
                  sx={{
                    bgcolor: "background.paper",
                    width: "auto",
                    padding: 2,
                    borderRadius: 5,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 2,
                      ml: 1,
                      textAlign: "left",
                      color: palette.primary.dark,
                    }}
                  >
                    Billing Info
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Service Provider"
                        name="serviceProvicerName"
                        value={settings.serviceProvicerName}
                        onChange={(e) =>
                          setSettings((prevState) => ({
                            ...prevState,
                            serviceProvicerName: e.target.value,
                          }))
                        }
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="GST Number"
                        name="gstNumber"
                        type="text"
                        value={settings.gstNumber}
                        onChange={(e) =>
                          setSettings((prevState) => ({
                            ...prevState,
                            gstNumber: e.target.value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        options={financialYear}
                        disableClearable
                        getOptionLabel={(option) => option.year || ""}
                        value={settings.financialYear}
                        onChange={(e, value) => {
                          // console.log("year is :", value);
                          setSettings((prevState) => ({
                            ...prevState,
                            financialYear: value,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Year *" />
                        )}
                      ></Autocomplete>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Financial Year "
                        name="financialYear"
                        type="text"
                        value={settings.financialYear.financialYear ? settings.financialYear.financialYear : ""}
                      // onChange={(e) =>
                      //   setSettings((prevState) => ({
                      //     ...prevState,
                      //     financialYear: e.target.value,
                      //   }))
                      // }
                      ></TextField>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    bgcolor: "background.paper",
                    width: "auto",
                    padding: 2,
                    borderRadius: 5,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 2,
                      ml: 1,
                      textAlign: "left",
                      color: palette.primary.dark,
                    }}
                  >
                    Default Branch Image
                  </Typography>
                  <Grid container spacing={2}>
                    {settings.defaultImageUrl ? (
                      <Grid item xs={12} sm={6}>
                        {/* <Button
                          component="label"
                          variant="outlined"
                          startIcon={<FileOpenIcon />}
                          sx={{ fontSize: 12 }}
                          onClick={() => {
                            const documentUrl = `${process.env.REACT_APP_API_BASE_IMAGE_URL}/defaultbranchimage/${settings.defaultImageUrl}`;
                            window.open(documentUrl, "_blank");
                          }}
                        >
                          View Image
                        </Button> */}
                        <Box>
                          <img
                            src={`${process.env.REACT_APP_API_BASE_IMAGE_URL}/defaultbranchimage/${settings.defaultImageUrl}`}
                            alt="default_image"
                            height={80}
                            style={{borderRadius: "5px", marginLeft: "10px"}}
                          />
                        </Box>
                      </Grid>
                    ) : null}

                    <Grid item xs={12} sm={6}>
                      <Button
                        component="label"
                        variant="outlined"
                        startIcon={<CloudUploadIcon />}
                        sx={{ fontSize: 12 }}
                      >
                        Upload Image
                        <input
                          key={fileKey}
                          className="customer-file-upload-input"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </Button>
                      {selectedFile && (
                        <IconButton
                          sx={{ position: "absolute" }}
                          size="small"
                          color={palette.grey[500]}
                          onClick={() => {
                            setSelectedFile(null);
                            setFileKey((prevKey) => prevKey + 1);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      {selectedFile && (
                        <Button
                          size="small"
                          variant="text"
                          sx={{ ml: 5 }}
                          onClick={() => {
                            window.open(
                              URL.createObjectURL(selectedFile),
                              "_blank"
                            );
                          }}
                        >
                          view
                        </Button>
                      )}
                      {selectedFile && (
                        <Typography sx={{ display: "inline-block", ml: 0.5 }}>
                          {selectedFile.name}
                        </Typography>
                      )}
                      <br />
                      <Typography variant="caption" color="red">
                        image should be in png/jpg/jpeg format
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    bgcolor: "background.paper",
                    width: "auto",
                    padding: 2,
                    borderRadius: 5,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 2,
                      ml: 1,
                      textAlign: "left",
                      color: palette.primary.dark,
                    }}
                  >
                    Billing Address
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Contact Number"
                        name="contactNumber"
                        type="number"
                        value={settings.contactNumber}
                        onChange={(e) =>
                          setSettings((prevState) => ({
                            ...prevState,
                            contactNumber: e.target.value,
                          }))
                        }
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        value={settings.email}
                        onChange={(e) =>
                          setSettings((prevState) => ({
                            ...prevState,
                            email: e.target.value,
                          }))
                        }
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label="Address"
                        name="address"
                        type="text"
                        multiline
                        rows={2}
                        value={settings.address}
                        onChange={(e) =>
                          setSettings((prevState) => ({
                            ...prevState,
                            address: e.target.value,
                          }))
                        }
                      ></TextField>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    bgcolor: "background.paper",
                    width: "auto",
                    padding: 2,
                    borderRadius: 5,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 2,
                      ml: 1,
                      textAlign: "left",
                      color: palette.primary.dark,
                    }}
                  >
                    Billing Discliamer
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        label="Declaration"
                        name="declaration"
                        type="text"
                        value={settings.declaration}
                        onChange={(e) =>
                          setSettings((prevState) => ({
                            ...prevState,
                            declaration: e.target.value,
                          }))
                        }
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        label="Terms & Conditions"
                        name="termAndCondtion"
                        type="text"
                        value={settings.termAndCondtion}
                        onChange={(e) =>
                          setSettings((prevState) => ({
                            ...prevState,
                            termAndCondtion: e.target.value,
                          }))
                        }
                      ></TextField>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>

              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: 1,
                  marginTop: 5,
                }}
              >
                <Button type="submit" variant="contained" color="primary">
                  Update settings
                </Button>
              </Box>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default SuperadminSettings;
