import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Drawer,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Toaster, toast } from "react-hot-toast";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import palette from "../../theme/palette";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import CsvDownloader from "react-csv-downloader";
import { useClient } from "../../context/ClientProvider";
import EditIcon from "../../assets/icons/EditIcon";
import CustomTooltip from "../../components/common/CustomTooltip";
import ServerPagination from "../../components/ServerPagination";
import Loader from "../../components/Loader";

// apis
import {
  getServiceRequestByBranch,
  findAllServiceRequestByBranch,
  updateServiceRequest,
} from "../../api/serviceRequest";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import { Link } from "react-router-dom";

const Taskreport = () => {
  const isSmallScreen = useIsSmallScreen();
  const [editServiceReq, setEditServiceReq] = useState(null);
  const [serviceRequest, setServiceRequest] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [dateValue, setDateValue] = useState("last7days");
  const [csvFileName, setCsvFileName] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const { activeGlobalBranch } = useClient();
  const handleModalClose = () => setModalOpen(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalSize, setTotalSize] = useState(1);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  // service-category by branchList
  const getServiceRequestByBranchData = async (isExport) => {
    setLoading(true);
    if (activeGlobalBranch) {
      try {
        const response = await findAllServiceRequestByBranch({
          currentPage: currentPage,
          pageSize: pageSize,
          startDate: startDate
            ? moment(startDate).format("YYYY-MM-DD 00:00:00")
            : startDate,
          endDate: endDate
            ? moment(endDate).format("YYYY-MM-DD 23:59:59")
            : endDate,
          isExport: isExport,
          branchId: activeGlobalBranch.id,
        });

        if (response) {
          const { data, totalPage, totalSize, key } = response;
          setServiceRequest(data);
          setTotalPages(totalPage);
          setTotalSize(totalSize);
          setLoading(false);
          if (isExport) {
            return key;
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.statusText);
      }
    } else {
      toast.error("Please select Client and Branch");
    }
  };

  useEffect(() => {
    activeGlobalBranch && getServiceRequestByBranchData();
  }, [currentPage, pageSize, activeGlobalBranch, startDate, endDate]);

  const handleExport = async () => {
    if (startDate && endDate) {
      try {
        const downloadUrl = await getServiceRequestByBranchData(true);
        setDownloadUrl(downloadUrl);
        console.log("download url:", downloadUrl);
        if (downloadUrl) {
          const link = document.createElement('a');
          link.href = `${process.env.REACT_APP_API_BASE_IMAGE_URL}/${downloadUrl}`;
          link.setAttribute('download', ''); // Force download attribute
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Please enter dates");
    }
  };

  const formik = useFormik({
    initialValues: {
      taskStatus: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      const taskdetails = {
        status: values.taskStatus,
      };
      try {
        const response = await updateServiceRequest(
          editServiceReq.id,
          taskdetails
        );
        if (response) {
          setServiceRequest((prevReq) =>
            prevReq.map((request) =>
              request.id === response.id
                ? {
                  ...response,
                }
                : request
            ));
          handleModalClose();
          setLoading(false);
          toast.success("Status updated successfully");
          // getServiceRequestByBranchData();
        }
      } catch (error) {
        toast.error(" Error in updating Service Request");
        setLoading(false);
      }
    },
  });

  const handleModalOpen = (serviceRequestData) => {
    setModalOpen(true);
    if (serviceRequestData.status) {
      setEditServiceReq(serviceRequestData);
      formik.setValues({
        taskStatus: serviceRequestData.status,
      });
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Task Id",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row.id}>
            {row.id}
          </CustomTooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      //filterable: false,
      //disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div>
            {row.status === "Completed" ? (
              <span style={{ color: "yellowgreen" }}>Completed</span>
            ) : row.status === "Progress" ? (
              <span style={{ color: "tan" }}>Progress</span>
            ) : row.status === "Open" ? (
              <span style={{ color: "lightskyblue" }}>Open</span>
            ) : row.status === "Assigned" ? (
              <span style={{ color: "slategray" }}>Assigned</span>
            ) : row.status === "Rejected" ? (
              <span style={{ color: "red" }}>Rejected</span>
            ) : (
              <span style={{ color: "orange" }}>Pending</span>
            )}
          </div>
        );
      },
    },
    {
      field: "services.serviceCategory",
      headerName: "ServiceCategory",
      sortable: false,
      // filterable: false,
      // disableColumnMenu: true,
      valueGetter: (params) => params.row?.services?.serviceCategory?.name,
      flex: 1,
      renderCell: ({ row }) => {
        return <div>{row.services?.serviceCategory?.name}</div>;
      },
    },
    {
      field: "services",
      headerName: "Service",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row.services?.name}>
            {row.services?.name}
          </CustomTooltip>
        );
      },
    },
    {
      field: "user",
      headerName: "User",
      sortable: false,
      // filterable: false,
      // disableColumnMenu: true,
      valueGetter: (params) => params.row?.user.userName,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row.user?.userName}>
            {row.user?.userName}
          </CustomTooltip>
        );
      },
    },
    {
      field: "room Name",
      headerName: "Room Name",
      filterable: false,
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: ({ row }) => row.customerCheckout?.room?.name,
    },
    {
      field: "Instructions",
      headerName: "Instructions",
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row?.specialInstruction}>
            {row?.specialInstruction}
          </CustomTooltip>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "CreatedAt",
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => (
        <CustomTooltip
          title={moment(row.createdAt, "YYYY-MM-DD HH:mm").format(
            "Do MMM YYYY, h:mm a"
          )}
        >
          {moment(row.createdAt, "YYYY-MM-DD HH:mm").format(
            "Do MMM YYYY, h:mm a"
          )}
        </CustomTooltip>
      ),
    },
    {
      field: "completedBy",
      headerName: "completedBy",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => (
        <CustomTooltip
          title={
            row.completedBy &&
            moment(row.completedBy, "YYYY-MM-DD HH:mm").format(
              "Do MMM YYYY, h:mm a"
            )
          }
        >
          {row.completedBy &&
            moment(row.completedBy, "YYYY-MM-DD HH:mm").format(
              "Do MMM YYYY, h:mm a"
            )}
        </CustomTooltip>
      ),
    },
    {
      field: "completedAt",
      headerName: "CompletedAt",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => (
        <CustomTooltip
          title={
            row.completedAt &&
            moment(row.completedAt).format("Do MMM YYYY, h:mm a")
          }
        >
          {row.completedAt &&
            moment(row.completedAt).format("Do MMM YYYY, h:mm a")}
        </CustomTooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      with: 50,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Tooltip title="Edit">
            <IconButton
              size="large"
              color="primary"
              onClick={() => handleModalOpen(row)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];
  return (
    <>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          sx={{ border: "" }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            Service Request Report
          </Typography>
          <Box
            margin={1}
            marginRight={2}
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ height: 30 }}
              onClick={handleExport}
            >
              Export
            </Button>
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction={isSmallScreen ? "column" : "row"} spacing={2} alignItems="center">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Start Date"
                inputProps={{ height: "2px" }}
                value={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setOpenEndDatePicker(true);
                  setDateValue("");
                }}
                slotProps={{ textField: { size: "small" } }}
                sx={{ width: 200 }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setDateValue("");
                }}
                open={openEndDatePicker}
                onOpen={() => setOpenEndDatePicker(true)}
                onClose={() => setOpenEndDatePicker(false)}
                slotProps={{ textField: { size: "small" } }}
                sx={{ width: 200 }}
              />
              <TextField
                size="small"
                label="Select Days"
                sx={{ width: "200px" }}
                value={dateValue}
                onChange={(e) => {
                  if (e.target.value === "today") {
                    setDateValue("today");
                    setStartDate(moment().startOf("day"));
                    setEndDate(moment().endOf("day"));
                  } else if (e.target.value === "yesterday") {
                    setDateValue("yesterday");
                    setStartDate(moment().subtract(1, "days").startOf("day"));
                    setEndDate(moment().subtract(1, "days").endOf("day"));
                  } else if (e.target.value === "last7days") {
                    setDateValue("last7days");
                    setStartDate(moment().subtract(7, "days").startOf("day"));
                    setEndDate(moment().endOf("day"));
                  } else if (e.target.value === "thismonth") {
                    setDateValue("thismonth");
                    setStartDate(moment().startOf("month"));
                    setEndDate(moment().endOf("day"));
                  } else if (e.target.value === "lastmonth") {
                    setDateValue("lastmonth");
                    setStartDate(moment().subtract(1, "month").startOf("month"));
                    setEndDate(moment().subtract(1, "month").endOf("month"));
                  }
                }}
                select
              >
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="yesterday">Yesterday</MenuItem>
                <MenuItem value="last7days">Last 7 days</MenuItem>
                <MenuItem value="thismonth">This Month</MenuItem>
                <MenuItem value="lastmonth">Last Month</MenuItem>
              </TextField>
              {/*  <Button
                variant="contained"
                color="primary"
                sx={{ height: 30 }}
                onClick={() => getServiceRequestByBranchData()}
              >
                Find
              </Button> */}
              <Button
                variant="contained"
                color="primary"
                sx={{ height: 30 }}
                onClick={() => {
                  setDateValue("last7days");
                  setStartDate(moment().subtract(7, "days").startOf("day"));
                  setEndDate(moment().endOf("day"));
                }}
              >
                Reset
              </Button>
            </LocalizationProvider>
          </Stack>
          <Box paddingRight={2}>
            <Button
              variant="contained"
              color="primary"
              sx={{ height: 30 }}
              onClick={() => {
                //window.location.reload();
                getServiceRequestByBranchData();
              }}
            >
              Refresh
            </Button>
          </Box>
        </Stack>

        {loading ? (
          <Loader />
        ) : (
          <>
            <Card sx={{ mt: 3 }}>
              <DataGrid
                rows={serviceRequest ? serviceRequest : []}
                columns={columns}
                getRowClassName={(row) => {
                  if (row?.row?.completedBy && row?.row?.completedAt) {
                    const completedBy = moment(row?.row?.completedBy);
                    const completedAt = moment(row?.row?.completedAt);
                    if (Math.abs(completedAt) > Math.abs(completedBy)) {
                      return "highlight-task-overdue";
                    } else if (Math.abs(completedAt) <= Math.abs(completedBy)) {
                      return "highlight-task-completed";
                    }
                  }
                }}
                getRowId={(row) => row.id}
                hideFooterPagination
                disableRowSelectionOnClick
                localeText={{
                  noRowsLabel: "No Service request data available",
                }}
                sx={{
                  "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus":
                  {
                    outline: "none",
                  },
                  "& .MuiDataGrid-row": {
                    position: "relative",
                  },
                  // header-bottom
                  "& .MuiDataGrid-row:first-child::before": {
                    content: "''",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#0000004D",
                  },
                  // each-row-bottom
                  "& .MuiDataGrid-row::after": {
                    content: "''",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#0000004D",
                  },
                  // footer-bottom
                  "& .MuiDataGrid-row:last-child::after": {
                    content: "''",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#0000004D",
                  },
                  minWidth: "678px",
                  width: "100%",
                  overflowX: "auto",
                }}
                autoHeight
              />
            </Card>
          </>
        )}
        {/* Server Pagination */}
        <ServerPagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          prevPage={prevPage}
          nextPage={nextPage}
          data={serviceRequest}
          totalSize={totalSize}
        />
        <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "background.paper",
                width: 400,
                marginTop: "40px",
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Change the Status :
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Status"
                      name="taskStatus"
                      select
                      value={formik.values.taskStatus}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={"Open"}>Open</MenuItem>
                      <MenuItem value={"Assigned"}>Assigned</MenuItem>
                      <MenuItem value={"Progress"}>Progress</MenuItem>
                      <MenuItem value={"Completed"}>Completed</MenuItem>
                      <MenuItem value={"Pending"}>Pending</MenuItem>
                      <MenuItem value={"Rejected"}>Rejected</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button disabled={loading} type="submit" variant="contained" color="primary">
                    { loading ? "Updating..." : "Update"}
                  </Button>
                  {/* <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: palette.grey[500],
                      "&:hover": {
                        backgroundColor: palette.grey[600],
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button> */}
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>
      </Container>
      <Toaster position="top-right" />
    </>
  );
};

export default Taskreport;
