import axiosInstance from ".";


export const getMasterTeamsDetails = async () => {
    try {
        const response = await axiosInstance.get('/admin/teams');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getTeamsByBranch = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/admin/teams?branchId=${branchId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const addTeam = async (newTeamsDetails) => {
    try {
        const response = await axiosInstance.post('/admin/teams/create', newTeamsDetails)
        //const response = await axiosInstance.post('/admin/teamservicecategory/create', newTeamsDetails)
        return response.data
    } catch (error) {
        throw error
    }
}

export const deleteTeam = async (teamId) => {
    try {
        const response = await axiosInstance.delete(`/admin/teams/${teamId}`)
        return response.data
    } catch (error) {
        throw error
    }
}

export const updateTeam = async (teamId, newTeamData) => {
    try {
        const response = await axiosInstance.put(`admin/teams/${teamId}`, newTeamData)
        return response.data
    } catch (error) {
        throw error
    }
}
