import React, { useState, useEffect } from 'react';
import { Container, Stack, Typography, Button, Autocomplete, TextField, Card, Drawer, Box, IconButton, Grid, MenuItem, Tooltip, tooltipClasses, styled, Icon, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Iconify from '../../components/iconify/Iconify';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import palette from '../../theme/palette';
import toast from 'react-hot-toast';
import { useClient } from '../../context/ClientProvider';
import deleteIcon from "../../assets/icons/trash.png";
// apis

import { createCheckout, findAllCheckoutByBranch, getCheckoutByBranch, updateCheckout } from '../../api/checkout';
import { getCustomerByBranch } from '../../api/customer';
import { getRoomAvailability } from '../../api/rooms';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';
import EditIcon from '../../assets/icons/EditIcon';
import ServerPagination from '../../components/ServerPagination';

const checkoutValidationSchema = Yup.object().shape({
  customerContactNumber: Yup.string().required('Contact is required')
    .matches(/^[0-9]+$/, 'Must be a valid number')
    .min(10, 'Must be at least 10 digits')
    .max(10, 'Must not exceed 10 digits'),
  customerFirstName: Yup.string().required('First Name is required'),
  customerLastName: Yup.string().required('Last Name is required'),
  // customerDocumentType: Yup.string().required('Document type is required'),
  // customerIdNumber: Yup.string().required('ID number is required'),
  // selectedRoom: Yup.object().required('Room is required'),
  // checkInDate: Yup.date().required('checkInDate is required'),
  // checkOutDate: Yup.date().required('checkoutDate is required'),
  rooms: Yup.array().of(
    Yup.object().shape({
      roomId: Yup.string().required('Room is required'),
      checkInDate: Yup.date().required('Check-in date is required'),
      checkOutDate: Yup.date().required('Check-out date is required')
    })
  ).min(1, 'At least one room must be added')
})

const Checkout = () => {
  const [rooms, setRooms] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [editedCustomer, setEditedCustomer] = useState(null);
  const [checkout, setCheckout] = useState([]);
  const [foundCustomerData, setFoundCustomerData] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').startOf('day'));
  const [endDate, setEndDate] = useState(moment().endOf('day'));
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
  const [inputSets, setInputSets] = useState(1);
  const { activeGlobalBranch } = useClient()

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalSize, setTotalSize] = useState(1);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const checkCustomerByNumber = (enteredNumber) => {
    const foundCustomer = customer.find((val) => val.contactNumber === enteredNumber);

    if (foundCustomer) {
      setFoundCustomerData(foundCustomer);
      formik.setFieldValue('customerFirstName', foundCustomer.firstName)
      formik.setFieldValue('customerLastName', foundCustomer.lastName)
      formik.setFieldValue('customerDocumentType', foundCustomer.documentType)
      // formik.setFieldValue('customerIdNumber', foundCustomer.idNumber)
      formik.setFieldValue('customerIsActive', foundCustomer.isActive)
    } else {
      setFoundCustomerData(null);
      formik.setFieldValue('customerFirstName', '')
      formik.setFieldValue('customerLastName', '')
      formik.setFieldValue('customerDocumentType', '')
      // formik.setFieldValue('customerIdNumber', '')
      formik.setFieldValue('customerIsActive', true)
    }
  };

  const roomsData = async () => {
    try {
      const response = await getRoomAvailability(activeGlobalBranch?.id);
      setRooms(response);
    } catch (error) {
      // console.log("get room by branch error", error);
    }
  };

  const customerData = async () => {
    try {
      const response = await getCustomerByBranch(activeGlobalBranch?.id);
      setCustomer(response);
    } catch (error) {
      // console.log("get customer by branch error", error);
    }
  };

  const checkoutData = async () => {
    // try {
    //     const response = await getCheckoutByBranch(activeGlobalBranch?.id);
    //     setCheckout(response);
    // } catch (error) {
    //     console.log("get checkout by branch error", error);
    // }

    try {
      const response = await findAllCheckoutByBranch({
        currentPage: currentPage,
        pageSize: pageSize,
        startDate: startDate,
        endDate: endDate,
        branchId: activeGlobalBranch.id,
      });
      if (response) {
        const { data, totalPage, totalSize } = response;
        setCheckout(data);
        setTotalPages(totalPage);
        setTotalSize(totalSize);
      }
    } catch (error) {
      console.log("get checkout by branch error", error);
    }
  };

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  // modal mode
  const [modalMode, setModalMode] = useState("add");
  // modal
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);

  // checkout modal open
  const handleModalOpen = (checkoutData) => {
    // !activeGlobalBranch ? toast.error('Please select client and branch') : setModalOpen(true);
    if (activeGlobalBranch) {
      // setModalOpen(true);
      setConfirmationOpen(true);
      if (checkoutData.checkOut) {
        setModalMode("edit");
        setEditedCustomer(checkoutData);
        formik.setValues({
          checkOutDate: moment(checkoutData.checkOut),
        })
      } else {
        setModalMode('add');
        formik.resetForm();
      }
    } else {
      toast.error('Please select client and branch')
    }
  };

  const handleConfirmAction = (confirmed) => {
    if (confirmed) {
      // Open the drawer if the user confirms
      setModalOpen(true);
      setConfirmationOpen(false);
    } else {
      // Close the confirmation dialog if the user cancels
      setConfirmationOpen(false);
    }
  };

  // handle document file
  const [selectedFile, setSelectedFile] = React.useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#A94C9B40",
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <LightTooltip title={row.customer?.firstName}>
            {row.customer?.firstName}
          </LightTooltip>
        );
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <LightTooltip title={row.customer?.lastName}>
            {row.customer?.lastName}
          </LightTooltip>
        );
      },
    },
    {
      field: 'contactNumber',
      headerName: 'Contact Number',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <LightTooltip title={row.customer?.contactNumber}>
            {row.customer?.contactNumber}
          </LightTooltip>
        );
      },
    },
    {
      field: 'room',
      headerName: 'Room Name',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <LightTooltip title={row.room?.name}>
            {row.room?.name}
          </LightTooltip>
        );
      },
    },
    {
      field: 'checkIn',
      headerName: 'CheckIn Date',
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <LightTooltip title={moment(row.checkIn, 'YYYY-MM-DD hh:mm:ss').format('Do MMM YYYY, hh:mm:ss A')}>
            {moment(row.checkIn, 'YYYY-MM-DD hh:mm:ss').format('Do MMM YYYY, hh:mm:ss A')}
          </LightTooltip>
        );
      },
    },
    {
      field: 'checkOut',
      headerName: 'CheckOut Date',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: ({ row }) => {
        return (
          <LightTooltip title={moment(row.checkOut, 'YYYY-MM-DD hh:mm:ss').format('Do MMM YYYY, hh:mm:ss A')}>
            {moment(row.checkOut, 'YYYY-MM-DD hh:mm:ss').format('Do MMM YYYY, hh:mm:ss A')}
          </LightTooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      // flex: 0.5,
      width: 90,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={0}>
            {/* Edit Icon */}
            <Tooltip title="Edit">
              <IconButton
                size="large"
                color="primary"
                // disabled={userType === "admin" && true}
                onClick={() => handleModalOpen(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    }
  ]

  // update checkout date
  const handleDateChange = (newDate) => {
    setEditedCustomer({
      ...editedCustomer,
      checkOut: newDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    });
  }

  // edit checkout
  const editCheckoutData = async () => {
    try {
      const response = await updateCheckout(editedCustomer.id, { checkOut: editedCustomer.checkOut });
      if (response) {
        setCheckout((prevCheckout) =>
          prevCheckout.map((chkout) =>
            chkout.id === editedCustomer.id ? { ...editedCustomer, checkOut: response?.checkOut } : chkout
          )
        ); 
        handleModalClose();
        toast.success("checkout updated successfully");
        checkoutData()
      }
    } catch (err) {

    }
  };

  // Function to handle adding a new set of inputs
  const handleAddInputSet = () => {
    formik.setValues(prevValues => ({
      ...prevValues,
      rooms: [
        ...prevValues.rooms,
        {
          roomId: null,
          checkInDate: null,
          checkOutDate: null
        }
      ]
    }));
    setInputSets(prevSets => prevSets + 1);
  };

  const handleDeleteRoom = (index) => {
    // Remove the room from the formik values
    formik.setValues((prevValues) => ({
      ...prevValues,
      rooms: prevValues.rooms.filter((_, i) => i !== index),
    }));
  };

  const renderInputSets = () => {
    return formik.values.rooms.map((room, index) => (
      <React.Fragment key={index}>
        <Box display="flex" alignItems="center">
          <Grid sx={{ marginLeft: 1, borderRadius: 1, marginTop: 1, paddingRight: 1, paddingBottom: 1, paddingLeft: "0px !important", paddingTop: "0px !important" }} item spacing={1} container border="1px solid #DC419C">
            <Grid item xs={12} sm={12}>
              <Autocomplete
                options={rooms}
                getOptionLabel={(option) => option.name}
                onChange={(event, roomValue) => {
                  const isRoomAlreadyAdded = formik.values.rooms.some(room => room?.roomId === roomValue?.id);

                  if (isRoomAlreadyAdded) {
                    toast.error("This room has already been added");
                    return;
                  } else {
                    formik.setFieldValue(`rooms[${index}].roomId`, roomValue ? roomValue.id : null);
                  }
                }}
                value={rooms.find(option => option.id === room.roomId) || null}
                renderInput={(params) => <TextField {...params} label="Rooms" />}
                renderOption={(props, option) => (
                  <div {...props} style={{ backgroundColor: option?.customercheckout?.length >= 1 && "#fcd4db" }}>
                    {option.name}
                  </div>
                )}
              />
              {formik.touched[`rooms[${index}].roomId`] && formik.errors[`rooms[${index}].roomId`] ? (
                <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors[`rooms[${index}].roomId`]}</Typography>
              ) : null}
            </Grid>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  label="CheckIn Date"
                  value={formik.values.rooms[index].checkInDate || null}
                  onChange={(date) => formik.setFieldValue(`rooms[${index}].checkInDate`, date)}
                  minDateTime={moment()}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
              {formik.touched[`rooms[${index}].checkInDate`] && formik.errors[`rooms[${index}].checkInDate`] ? (
                <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors[`rooms[${index}].checkInDate`]}</Typography>
              ) : null}
            </Grid>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  label="CheckOut Date"
                  value={formik.values.rooms[index].checkOutDate || null}
                  onChange={(date) => formik.setFieldValue(`rooms[${index}].checkOutDate`, date)}
                  minDateTime={moment()}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
              {formik.touched[`rooms[${index}].checkOutDate`] && formik.errors[`rooms[${index}].checkOutDate`] ? (
                <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors[`rooms[${index}].checkOutDate`]}</Typography>
              ) : null}
            </Grid>
          </Grid>
          <IconButton
            size="small"
            color="error"
            onClick={() => handleDeleteRoom(index)}
            sx={{ height: 35, width: 35 }}
          >
            <img src={deleteIcon} />
          </IconButton>
        </Box>
      </React.Fragment>
    ));
  };

  // create checkout
  const formik = useFormik({
    initialValues: {
      customerContactNumber: '',
      customerFirstName: '',
      customerLastName: '',
      customerContactNumber: '',
      customerDocumentType: '',
      customerIsActive: true,
      selectedCustomer: null,
      rooms: [],
    },
    validationSchema: modalMode === "add" ? checkoutValidationSchema : null,
    onSubmit: async (values) => {
      const checkoutDetailsWithExistingCustomer = {
        customerId: foundCustomerData?.id,
        roomIds: values?.rooms?.map(room => ({
          roomId: room.roomId,
          checkIn: room.checkInDate ? room.checkInDate.format('YYYY-MM-DD HH:mm:ss') : null,
          checkOut: room.checkOutDate ? room.checkOutDate.format('YYYY-MM-DD HH:mm:ss') : null,
        }))
      };

      const checkoutDetailsWithNewCustomer = {
        firstName: values.customerFirstName,
        lastName: values.customerLastName,
        contactNumber: values.customerContactNumber,
        photoIdProof: values.customerDocumentType,
        isActive: values.customerIsActive,
        roomIds: values?.rooms?.map(room => ({
          roomId: room.roomId,
          checkIn: room.checkInDate ? room.checkInDate.format('YYYY-MM-DD HH:mm:ss') : null,
          checkOut: room.checkOutDate ? room.checkOutDate.format('YYYY-MM-DD HH:mm:ss') : null,
        }))
      };

      const checkoutDetails = foundCustomerData ? checkoutDetailsWithExistingCustomer : checkoutDetailsWithNewCustomer;
      try {
        if (modalMode === "add") {
          const response = await createCheckout(checkoutDetails);
          setCheckout([...checkout, ...response]);
          toast.success("Checkout created successfully");
          formik.resetForm();
          handleModalClose();
          checkoutData();
        } else if (modalMode === "edit") {
          editCheckoutData();
          formik.resetForm();
        }
      } catch (error) {
        toast.error("Some error on create checkout");
        console.error('create checkout Error:', error);
      }
    },
  });

  useEffect(() => {
    activeGlobalBranch && roomsData() && customerData() && checkoutData();
  }, [currentPage, pageSize, activeGlobalBranch, startDate, endDate]);

  return (
    <>
      <Container sx={{ paddingX: "8px !important", marginX: 'auto !important', maxWidth: '100% !important' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3} sx={{ border: '' }}>
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            Checkout
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleModalOpen}>
            New Checkout
          </Button>
        </Stack>
        <Stack direction="row" spacing={2} alignItems='center' >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => { setStartDate(date); setOpenEndDatePicker(true); }}
              sx={{ width: 200 }}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              open={openEndDatePicker}
              onOpen={() => setOpenEndDatePicker(true)}
              onClose={() => setOpenEndDatePicker(false)}
              sx={{ width: 200 }}
            />
          </LocalizationProvider>
          {/* <Button sx={{ height: 30 }} variant='contained' onClick={() => { setStartDate(null); setEndDate(null) }}>Reset</Button> */}
          <Button
            variant="contained"
            color="primary"
            sx={{ height: 30 }}
            onClick={() => checkoutData()}
          >
            Find
          </Button>
          <Button
            sx={{ height: 30 }}
            variant="contained"
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
            }}
          >
            Reset
          </Button>
        </Stack>
        <Card sx={{ mt: 3, overflowX: "auto" }}>
          <DataGrid
            /* rows={checkout.filter((row) => {
              const checkInDate = moment(row?.checkIn);
              return (
                (!startDate || checkInDate.isSameOrAfter(startDate)) &&
                (!endDate || checkInDate.isSameOrBefore(endDate))
              ); 
            })} */
            rows={checkout}
            columns={columns}
            // initialState={{
            //   pagination: {
            //     paginationModel: { page: 0, pageSize: 10 },
            //   },
            // }}
            // pageSizeOptions={[5, 10, 50]}
            disableRowSelectionOnClick
            localeText={{ noRowsLabel: 'No checkout found' }}
            autoHeight={true}
            hideFooterPagination
            sx={{
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus":
              {
                outline: "none",
              },
              "& .MuiDataGrid-row": {
                position: "relative",
              },
              // header-bottom
              "& .MuiDataGrid-row:first-child::before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // each-row-bottom
              "& .MuiDataGrid-row::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // footer-bottom
              "& .MuiDataGrid-row:last-child::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              minWidth: '678px',
              width: '100%',
              height: checkout.length !== 0 ? '400px' : 'auto',
              overflowX: "auto"
            }}
          />
        </Card>
        {/* Server Pagination */}
        <ServerPagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          prevPage={prevPage}
          nextPage={nextPage}
          data={checkout}
          totalSize={totalSize}
        />
        {/* for add checkout */}
        <Drawer anchor='right' open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth='sm'>
            <Box sx={{
              bgcolor: 'background.paper',
              width: 400,
              marginY: 2
            }}>
              <IconButton
                sx={{ position: 'absolute', top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 0,
                }}
              >
                <Typography variant="h6" gutterBottom mb={3}>
                  {modalMode === 'add' ? `Add Checkout` : `Edit Checkout`}
                </Typography>
              </Box>

              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  {modalMode === "add" &&
                    <>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          label="Contact Number"
                          name='customerContactNumber'
                          onChange={(e) => {
                            formik.handleChange(e);
                            const enteredNumber = e.target.value;
                            if (enteredNumber.length === 10) {
                              checkCustomerByNumber(enteredNumber);
                            }
                          }}
                        />
                        {formik.touched.customerContactNumber && formik.errors.customerContactNumber ? (
                          <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors.customerContactNumber}</Typography>
                        ) : null}
                      </Grid>
                      <Grid item spacing={2} container>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            fullWidth
                            label="First Name"
                            name="customerFirstName"
                            type='text'
                            {...formik.getFieldProps('customerFirstName')}
                          />
                          {formik.touched.customerFirstName && formik.errors.customerFirstName ? (
                            <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors.customerFirstName}</Typography>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            fullWidth
                            label="Last Name"
                            name="customerLastName"
                            type='text'
                            {...formik.getFieldProps('customerLastName')}
                          />
                          {formik.touched.customerLastName && formik.errors.customerLastName ? (
                            <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors.customerLastName}</Typography>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            fullWidth
                            label="Document Type"
                            name="customerDocumentType"
                            select
                            value={formik.values.customerDocumentType}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <MenuItem value={"addhar"}>Adhar</MenuItem>
                            <MenuItem value={"votor"}>Votor</MenuItem>
                          </TextField>
                          {formik.touched.customerDocumentType && formik.errors.customerDocumentType ? (
                            <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors.customerDocumentType}</Typography>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={12} display="flex">
                          <Button component="label" variant="outlined" startIcon={<CloudUploadIcon />} sx={{ fontSize: 12 }}>
                            {`Upload${" "}Document`}
                            <input className='customer-file-upload-input' type="file" onChange={handleFileChange} />
                          </Button>
                          {selectedFile && <Typography sx={{ display: 'inline-block', ml: 0.5 }}>{selectedFile.name}</Typography>}
                        </Grid>
                      </Grid>
                      {renderInputSets()}
                      <Button
                        variant="outlined"
                        size='small'
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={handleAddInputSet}
                        sx={{ ml: 2, mt: 2 }}
                      >
                        Add Room
                      </Button>
                    </>
                  }

                  {modalMode === "edit" && <Grid item xs={12} sm={12}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        label="CheckOut Date"
                        value={editedCustomer && moment.utc(editedCustomer?.checkOut)}
                        onChange={(newDate) => handleDateChange(newDate)}
                        minDateTime={moment()}
                        sx={{ width: '100%' }}
                      />
                    </LocalizationProvider>
                    {/* {formik.touched.checkOutDate && formik.errors.checkOutDate ? (
                                            <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors.checkOutDate}</Typography>
                                        ) : null} */}
                  </Grid>}
                </Grid>
                <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="submit" variant="contained" color="primary"
                  >
                    {modalMode === 'add' ? 'Add' : 'Update'}
                  </Button>
                  <Button type="button" variant="contained" color='secondary' sx={{
                    backgroundColor: palette.grey[500],
                    '&:hover': {
                      backgroundColor: palette.grey[600],
                    },
                    marginLeft: 1,
                  }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>
      </Container>

      <Dialog open={confirmationOpen} onClose={() => handleConfirmAction(false)}>
        <DialogTitle>{modalMode === 'edit' ? 'Edit Checkout' : 'Add Checkout'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {modalMode === 'edit' ? 'edit this' : 'create a'} checkout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmAction(false)} color="secondary">
            No
          </Button>
          <Button onClick={() => handleConfirmAction(true)} variant="contained" color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Checkout;