import axiosInstance from ".";

export const getRoomByFloor = async (floorId) => {
    try {
        const response = await axiosInstance.get(`/admin/rooms?floorId=${floorId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getRoomAvailability = async (branchId, checkIn, checkOut) => {
    try {
        const response = await axiosInstance.get(`/admin/rooms/availablity?branchId=${branchId}&checkIn=${checkIn}&checkOut=${checkOut}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getRoomByBranch = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/admin/rooms?branchId=${branchId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getAllRoomsPdf = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/admin/rooms/downloadqrpdf?branchId=${branchId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const addRoom = async (roomDetails) => {
    try {
        const response = await axiosInstance.post('/admin/rooms', roomDetails);
        return response.data
    } catch (error) {
        throw error
    }
}

export const updateRoom = async (roomId, roomDetails) => {
    try {
        const response = await axiosInstance.put(`/admin/rooms/${roomId}`, roomDetails);
        return response.data
    } catch (error) {
        throw error
    }
}

export const deleteRoom = async (roomId) => {
    try {
        const response = await axiosInstance.delete(`/admin/rooms/${roomId}`);
        return response.data
    } catch (error) {
        throw error
    }
}

export const bulkRoomCreate = async (roomDetails) => {
    try {
        const response = await axiosInstance.post('/admin/rooms/bulk-room-create', roomDetails, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data
    } catch (error) {
        throw error
    }
}