import axiosInstance from ".";

export const getServiceRequestByBranch = async (branchId, startdate, enddate, isExport) => {
    try {
        if (startdate && enddate) {
            const response = await axiosInstance.get(`admin/servicerequest?branchId=${branchId}&startDate=${startdate}&endDate=${enddate}&isExport=${isExport}`);
            return response.data;
        }
        else {
            const response = await axiosInstance.get(`admin/servicerequest?branchId=${branchId}`);
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export const findAllServiceRequestByBranch = async (requestDetails) => {
    try {
        const response = await axiosInstance.post(`/admin/servicerequest/findall`, requestDetails);
            return response.data;
        }
    catch (error) {
        throw error;
    }
}

export const updateServiceRequest = async (serviceRequestId, serviceReqDetails) => {
    try {
        const response = await axiosInstance.put(`admin/servicerequest/${serviceRequestId}`, serviceReqDetails)
        return response.data
    } catch (error) {
        throw error
    }
}

export const getServiceRequestForDashboard = async (serviceRequest) => {
    try {
        const response = await axiosInstance.post(`admin/servicerequest/getByBranch?branchId=${serviceRequest.branchId}&days=${serviceRequest.days}`);
        return response.data
    } catch (error) {
        throw error
    }
}