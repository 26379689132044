import React from 'react';
import LoadingScreenLogo from "../../assets/images/gc_loading_screen_logo.png";

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            <h1 className='loading-screen-header'>WELCOME TO GUESTCARE365</h1>
            <div className='loading-screen-content'>
                <img src={LoadingScreenLogo} alt='loading_img_logo' />
                <p className='loading-screen-header'>Where convenience meets luxury on every tap!</p>
            </div>
        </div>
    )
}

export default LoadingScreen;