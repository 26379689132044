import React, { useEffect, useState } from "react";
import palette from "../../theme/palette";
// mui
import {
  Card,
  Stack,
  MenuItem,
  Avatar,
  Input,
  Link,
  Button,
  Container,
  Drawer,
  Typography,
  IconButton,
  TablePagination,
  Box,
  Tooltip,
  TextField,
  Grid,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Iconify from "../../components/iconify/Iconify";
import { toast } from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "../../components/Loader";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import deleteIcon from "../../assets/icons/trash.png";
import EditIcon from "../../assets/icons/EditIcon";
import { IconCross, IconPlus } from "../../assets/icons";

// apis
import {
  addSuperadmin,
  deleteSuperadmin,
  getAllSuperadmins,
  updateSuperadmin,
} from "../../api/superadmin";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DataGrid } from "@mui/x-data-grid";
import ContactInfoPopover from "../../components/common/ContactInfoPopover";
import { Clear } from "@mui/icons-material";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const createSuperadminValidationSchema = Yup.object().shape({
  superadminName: Yup.string().required("Superadmin name is required"),
  superadminFirstName: Yup.string().required(
    "Superadmin first name is required"
  ),
  superadminLastName: Yup.string().required("Superadmin last name is required"),
  superadminContactNumber: Yup.string()
    .required("Superadmin contact number is required")
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
  superadminIsActive: Yup.boolean().required(
    "Superadmin IsActive status is required"
  ),
  superadminPassword: Yup.string().required("Superadmin Password is required"),
  superadminEmail: Yup.string()
    .email("Invalid email format")
    .nullable()
    .optional(),
  superadminImageFile: Yup.mixed().test(
    "file",
    "Please select a valid file",
    (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return true;
    }
  ),
});

const editSuperadminValidationSchema = Yup.object().shape({
  superadminName: Yup.string().required("Superadmin name is required"),
  superadminFirstName: Yup.string().required(
    "Superadmin first name is required"
  ),
  superadminLastName: Yup.string().required("Superadmin last name is required"),
  superadminContactNumber: Yup.string()
    .required("Superadmin contact number is required")
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
  superadminIsActive: Yup.boolean().required(
    "Superadmin IsActive status is required"
  ),
  superadminEmail: Yup.string()
    .email("Invalid email format")
    .nullable()
    .optional(),
  superadminImageFile: Yup.mixed().test(
    "file",
    "Please select a valid file",
    (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return true;
    }
  ),
});

const Superadmin = () => {
  const isSmallScreen = useIsSmallScreen();
  const [superadmin, setSuperadmin] = useState([]);
  const [searchSuperadminValue, setSearchSuperadminValue] = useState(null);
  const [searchedValue, setSearchedValue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [editedSuperadmin, setEditedSuperadmin] = useState(null);
  const [modalMode, setModalMode] = useState("add");
  const [showPassword, setShowPassword] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    status: false,
    deleteId: null,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [contactInfo, setContactInfo] = useState(null);
  const [rowClicked, setRowClicked] = useState(null);

  const showContacts = (event, row) => {
    setAnchorEl(event.currentTarget);
    setContactInfo(row);
    setRowClicked(row.id);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setRowClicked(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverProps = {
    id: openPopover ? "simple-popover" : undefined,
    open: openPopover,
    anchorEl: anchorEl,
    handleClose: handleClosePopover,
    contactInfo: contactInfo,
  };

  const handleModalClose = () => setModalOpen(false);
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  // open modal
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = (superadminData) => {
    setModalOpen(true);
    if (superadminData.userName) {
      setModalMode("edit");
      setEditedSuperadmin(superadminData);
      formik.setValues({
        superadminName: superadminData.userName,
        superadminFirstName: superadminData.firstName,
        superadminLastName: superadminData.lastName,
        superadminContactNumber: superadminData.contactNumber,
        superadminIsActive: superadminData.isActive,
        superadminEmail: superadminData.email,
        superadminImageFile: superadminData.superadminImageFile,
      });
    } else {
      setModalMode("add");
      formik.resetForm();
    }
  };

  //get superadmin data
  const superadminData = async () => {
    try {
      const response = await getAllSuperadmins({
        currentPage: page,
        pageSize: rowsPerPage,
        search: searchedValue ? searchedValue : null,
      });
      if (response) {
        setSuperadmin(response.data);
        setTotalItems(response.totalPage);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in getting super admin details:", error);
    }
  };

  // editSuperadminData
  const editSuperadminData = async (superadminDetails) => {
    const updatedSuperadminDetails = {};
    if (superadminDetails.userName !== editedSuperadmin.userName) {
      updatedSuperadminDetails.userName = superadminDetails.userName;
    }
    if (superadminDetails.firstName !== editedSuperadmin.firstName) {
      updatedSuperadminDetails.firstName = superadminDetails.firstName;
    }
    if (superadminDetails.lastName !== editedSuperadmin.lastName) {
      updatedSuperadminDetails.lastName = superadminDetails.lastName;
    }
    if (superadminDetails.contactNumber !== editedSuperadmin.contactNumber) {
      updatedSuperadminDetails.contactNumber = superadminDetails.contactNumber;
    }
    if (superadminDetails.isActive !== editedSuperadmin.isActive) {
      updatedSuperadminDetails.isActive = superadminDetails.isActive;
    }
    if (superadminDetails.email !== editedSuperadmin.email) {
      updatedSuperadminDetails.email = superadminDetails.email;
    }
    if (superadminDetails.file) {
      updatedSuperadminDetails.file = superadminDetails.file;
    }
    try {
      const isNotingToUpdate =
        Object.keys(updatedSuperadminDetails).length === 0;
      const response =
        !isNotingToUpdate &&
        (await updateSuperadmin(editedSuperadmin.id, updatedSuperadminDetails));
      if (response) {
        setSuperadmin((prevSuperadmin) =>
          prevSuperadmin.map((supadmin) =>
            supadmin.id === editedSuperadmin.id
              ? {
                ...superadmin,
                id: editedSuperadmin.id,
                userName: superadminDetails.userName,
                firstName: superadminDetails.firstName,
                lastName: superadminDetails.lastName,
                contactNumber: superadminDetails.contactNumber,
                isActive: superadminDetails.isActive,
                email: superadminDetails.email,
                file: superadminDetails.file,
              }
              : supadmin
          )
        );
        toast.success("Superadmin updated successfully");
        handleModalClose();
        superadminData();
      } else {
        toast.success("Superadmin updated successfully");
        handleModalClose();
        superadminData();
      }
    } catch (error) {
      if (error.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message?.message[0]);
      } else if (error.response?.data?.statusCode === 409) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error(error.response?.data?.message);
      }
    }
  };

  // deleteSuperadminData
  const deleteSuperadminData = async () => {
    const { deleteId } = openDeleteDialog;
    try {
      const updatedSuperadminValue = superadmin.filter(
        (val) => val.id !== deleteId
      );
      const response = await deleteSuperadmin(deleteId);
      if (response) {
        setSuperadmin(updatedSuperadminValue);
        toast.success("Superadmin deleted successfully");
      }
    } catch (error) {
      toast.error("Error on deleting Superadmin");
    }
  };

  // searchSuperadminData
  const handleSuperadminSearch = () => {
    setSearchedValue(searchSuperadminValue);
  };

  const handleClearSearch = () => {
    setSearchSuperadminValue("");
    setSearchedValue(null);
  };

  // create superadmin
  const formik = useFormik({
    initialValues: {
      superadminName: "",
      superadminFirstName: "",
      superadminLastName: "",
      superadminContactNumber: "",
      superadminIsActive: true,
      superadminPassword: "",
      superadminEmail: "",
      superadminImageFile: undefined,
    },
    validationSchema:
      modalMode === "add"
        ? createSuperadminValidationSchema
        : editSuperadminValidationSchema,
    onSubmit: async (values) => {
      const superadminDetails = {
        userName: values.superadminName,
        contactNumber: values.superadminContactNumber,
        firstName: values.superadminFirstName,
        lastName: values.superadminLastName,
        isActive: values.superadminIsActive,
      };
      if (values.superadminEmail) {
        superadminDetails.email = values.superadminEmail;
      }
      if (values.superadminImageFile) {
        superadminDetails.file = values.superadminImageFile;
      }
      try {
        if (modalMode === "add") {
          superadminDetails.password = values.superadminPassword;
          const response = await addSuperadmin(superadminDetails);
          response && toast.success("Superadmin added successfully");
          setSuperadmin(response);
          formik.resetForm();
          handleModalClose();
          superadminData();
        } else {
          editSuperadminData(superadminDetails);
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
      }
    },
  });

  useEffect(() => {
    superadminData();
  }, [page, rowsPerPage, searchedValue]);

  const columns = [
    {
      field: "image",
      headerName: "",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                alt="Superadmin Img"
                src={`${process.env.REACT_APP_API_BASE_IMAGE_URL}/superAdmin/${row?.imageUrl}`}
                sx={{ width: 30, height: 30 }}
              />
              <Tooltip title="Contact Info">
                <IconButton
                  aria-describedby={popoverProps.id}
                  variant="contained"
                  onClick={(e) => showContacts(e, row)}
                >
                  <img
                    height={18}
                    src={row.id === rowClicked ? IconCross : IconPlus}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        );
      },
    },
    {
      field: "userName",
      headerName: "UserName",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "firstName",
      headerName: "FirstName",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "lastName",
      headerName: "LastName",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "isActive",
      headerName: "IsActive",
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      with: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const handleDeleteDialog = (id) => {
          setOpenDeleteDialog({
            ...openDeleteDialog,
            status: true,
            deleteId: id,
          });
        };
        const handleDeleteClose = () => {
          setOpenDeleteDialog({ ...openDeleteDialog, status: false });
        };
        const handleConfirmDelete = () => {
          deleteSuperadminData();
          handleDeleteClose();
        };

        return (
          <Stack direction="row" spacing={0}>
            {/* Edit Icon */}
            <Tooltip title="Edit">
              <IconButton
                size="large"
                color="primary"
                onClick={() => handleModalOpen(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {/* Delete Icon */}
            <Tooltip title="Delete">
              <IconButton
                size="large"
                color="error"
                onClick={() => handleDeleteDialog(row.id)}
              >
                <img src={deleteIcon} />
              </IconButton>
            </Tooltip>
            {/* Confirmation Modal */}
            <ConfirmationModal
              open={openDeleteDialog.status}
              onClose={handleDeleteClose}
              onConfirm={handleConfirmDelete}
              iconName="superadmin"
            />
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
          position: "relative",
          minHeight: "500px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
          sx={{ border: "", ...(isSmallScreen && { marginTop: 2 }) }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            Superadmin
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleModalOpen}
          >
            New Superadmin
          </Button>
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Card
              sx={{
                height: "auto",
                overflowX: "auto",
                width: "100%",
                "& .row": {},
              }}
            >
              <TextField
                label="Search Superadmin"
                variant="outlined"
                size="small"
                value={searchSuperadminValue}
                onChange={(e) => setSearchSuperadminValue(e.target.value)}
                sx={{ ml: 1, mt: 1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchSuperadminValue ? (
                        <IconButton
                          onClick={handleClearSearch}
                          edge="end"
                          aria-label="clear-search"
                        >
                          <Clear />
                        </IconButton>
                      ) : null}
                      <IconButton
                        onClick={handleSuperadminSearch}
                        edge="end"
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <DataGrid
                rows={superadmin ? superadmin : []}
                columns={columns}
                hideFooterPagination
                hideFooter
                localeText={{ noRowsLabel: "No superadmin found" }}
                disableRowSelectionOnClick
                autoHeight
                sx={{
                  "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus":
                  {
                    outline: "none",
                  },
                  "& .MuiDataGrid-row": {
                    position: "relative",
                  },
                  // header-bottom
                  "& .MuiDataGrid-row:first-child::before": {
                    content: "''",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#0000004D",
                  },
                  // each-row-bottom
                  "& .MuiDataGrid-row::after": {
                    content: "''",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#0000004D",
                  },
                  // footer-bottom
                  "& .MuiDataGrid-row:last-child::after": {
                    content: "''",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#0000004D",
                  },
                  minWidth: "678px",
                  width: "100%",
                  overflowX: "auto",
                }}
              />
              {/* server side pagination */}
              <TablePagination
                component="div"
                count={totalItems}
                page={page - 1}
                rowsPerPage={[rowsPerPage]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
        )}
        <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "background.paper",
                width: 400,
                marginTop: "15px",
                ...(isSmallScreen && { width: 280 }),
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <Typography variant="h5" gutterBottom>
                  {modalMode === "add" ? "Add Superadmin" : "Edit Superadmin"}
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ textAlign: "center" }}>
                  <Avatar
                    alt="Superadmin Avatar"
                    src={
                      formik.values.superadminImageFile
                        ? URL.createObjectURL(formik.values.superadminImageFile)
                        : modalMode === "edit" &&
                          editedSuperadmin &&
                          editedSuperadmin.imageUrl
                          ? `${process.env.REACT_APP_API_BASE_IMAGE_URL}/superAdmin/${editedSuperadmin.imageUrl}`
                          : "/default-avatar.png"
                    }
                    sx={{
                      width: 70,
                      height: 70,
                      borderRadius: "50%",
                      mx: "auto",
                      mb: 2,
                    }}
                    onClick={() => {
                      document.getElementById("superadminImageFile").click();
                    }}
                  />
                  <Input
                    type="file"
                    accept="image/*"
                    id="superadminImageFile"
                    name="superadminImageFile"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "superadminImageFile",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="UserName *"
                      name="userName"
                      type="text"
                      {...formik.getFieldProps("superadminName")}
                    />
                    {formik.touched.superadminName &&
                      formik.errors.superadminName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.superadminName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="FirstName *"
                      name="firstName"
                      multiline
                      rows={2}
                      {...formik.getFieldProps("superadminFirstName")}
                    />
                    {formik.touched.superadminFirstName &&
                      formik.errors.superadminFirstName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.superadminFirstName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="LastName *"
                      name="lastName"
                      multiline
                      rows={2}
                      {...formik.getFieldProps("superadminLastName")}
                    />
                    {formik.touched.superadminLastName &&
                      formik.errors.superadminLastName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.superadminLastName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="ContactNumber *"
                      name="contactNumber"
                      {...formik.getFieldProps("superadminContactNumber")}
                    />
                    {formik.touched.superadminContactNumber &&
                      formik.errors.superadminContactNumber ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.superadminContactNumber}
                      </Typography>
                    ) : null}
                  </Grid>
                  {modalMode === "add" ? (
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label="Password *"
                        name="password"
                        placeholder="Password"
                        type={!showPassword ? "text" : "password"}
                        //{...formik.getFieldProps("userPassword")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!showPassword ? (
                                <Visibility onClick={handleTogglePassword} />
                              ) : (
                                <VisibilityOff onClick={handleTogglePassword} />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: "100%",
                        }}
                        inputProps={{ style: { height: "21px" } }}
                        {...formik.getFieldProps("superadminPassword")}
                      />
                      {formik.touched.superadminPassword &&
                        formik.errors.superadminPassword ? (
                        <Typography
                          sx={{
                            color: "red",
                            variant: "body2",
                            fontSize: "12px",
                          }}
                        >
                          {formik.errors.superadminPassword}
                        </Typography>
                      ) : null}
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="IsActive"
                      name="superadminIsActive"
                      select
                      value={formik.values.superadminIsActive}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </TextField>
                    {formik.touched.superadminIsActive &&
                      formik.errors.superadminIsActive ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.superadminIsActive}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Email *"
                      name="email"
                      {...formik.getFieldProps("superadminEmail")}
                    />
                    {formik.touched.superadminEmail &&
                      formik.errors.superadminEmail ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.superadminEmail}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button type="submit" variant="contained" color="primary">
                    {modalMode === "add" ? "Add" : "Update"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: palette.grey[500],
                      "&:hover": {
                        backgroundColor: palette.grey[600],
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>
        <ContactInfoPopover {...popoverProps} />
      </Container>
    </>
  );
};

export default Superadmin;
