import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Clear } from '@mui/icons-material';

const SearchInput = ({ value, onChange, onClear, label, size, sx }) => {

    return (
        <TextField
            label={`Search ${label}`}
            variant="outlined"
            size={size || 'small'}
            value={value}
            onChange={onChange}
            sx={sx}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {/* <IconButton
                            onClick={onClear}
                            edge="end"
                            aria-label="clear-search"
                        >
                            <Clear />
                        </IconButton> */}
                        <IconButton
                            edge="end"
                            aria-label="search"
                        >
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default SearchInput;