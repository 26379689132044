import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import palette from '../../theme/palette';
import axios from 'axios';


const InvoiceDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    // console.log("id is ", id);

    // useEffect(()=>{
    //     const res = axios.post();
    // },[]);
    
    return (
        <Box sx={{ background: '#fad9ed', height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ color: '#575757', boxShadow: '0px 5px 14px 0px #00000029', background: "#fff", padding: '20px 30px', borderRadius: '7px' }}>
                <Typography color="#08a62a" variant='h5' mb={2}>Payment Successful!</Typography>
                <Typography variant='subtitle2'>Amount Paid: 5000</Typography>
                <Typography variant='subtitle2'>Invoice Number: PGTESTPAYUAT</Typography>
                <Typography variant='subtitle2'>Transaction ID: MT7850590068188104</Typography>
                <Link to='/app/invoice'>
                    <Button variant='outlined' sx={{ float: 'right', mt: 2 }}>Go Back</Button>
                </Link>
            </Box>
        </Box>
    )
}

export default InvoiceDetail