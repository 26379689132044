import './App.css';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import ThemeProvider from './theme';
import AuthProvider from './context/AuthProvider';
import ClientProvider from './context/ClientProvider';
import DashboardLayout from './layouts/dashboard/DashboardLayout';
import { AdminLogin, Branches, Checkout, Client, Customer, Dashboard, Floor, ForgotPassword, Invoice, InvoiceDetail, Login, NotFound, Rooms, Service, ServiceCategory, Superadmin, Taskreport, Team, User, Profile, ChangePassword, TermAndCondition, ValidateOTP, Settings, Plan, CustomerCheckout, SuperadminSettings } from './pages';
import ProtectedRoute from './components/ProtectedRoute';
import { Toaster } from 'react-hot-toast';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import SubdomainCheck from './components/SubdomainCheck';

const router = createBrowserRouter(createRoutesFromElements(
  <Route path='/'>
    {/* Admin routes */}
    <Route index element={<SubdomainCheck value="admin" />} />
    <Route path='forgot-password' element={<ForgotPassword />} />
    <Route path='validate-otp' element={<ValidateOTP />} />
    <Route path='app' element={<AdminProtectedRoute><DashboardLayout /></AdminProtectedRoute>}>
      <Route index element={<Dashboard />} />
      <Route path='dashboard' element={<Dashboard />} />
      {/* <Route path='client' element={<Client />} /> */}
      {/* <Route path='superadmin' element={<Superadmin />} /> */}
      <Route path='branch' element={<Branches />} />
      <Route path='floor' element={<Floor />} />
      <Route path='room' element={<Rooms />} />
      <Route path='checkin' element={<CustomerCheckout />} />
      <Route path='user' element={<User />} />
      <Route path='service-category' element={<ServiceCategory />} />
      <Route path='service' element={<Service />} />
      <Route path='team' element={<Team />} />
      <Route path='customer' element={<Customer />} />
      <Route path='taskreport' element={<Taskreport />} />
      <Route path='invoice' element={<Invoice />} />
      <Route path='profile' element={<Profile />} />
      <Route path='change-password' element={<ChangePassword />} />
      {/* <Route path='settings' element={<Settings />} /> */}
    </Route>
    <Route path='invoice-detail/:id' element={<InvoiceDetail />} />
    <Route path='termandcondition' element={<TermAndCondition />} />

    {/* Superadmin routes */}
    <Route path='superadmin'>
      <Route index element={<SubdomainCheck value="superadmin" />} />
      <Route index element={<Login />} />
      <Route path='app' element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>}>
        <Route index element={<Dashboard />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='client' element={<Client />} />
        <Route path='superadmin' element={<Superadmin />} />
        <Route path='branch' element={<Branches />} />
        <Route path='floor' element={<Floor />} />
        <Route path='room' element={<Rooms />} />
        <Route path='checkin' element={<CustomerCheckout />} />
        <Route path='user' element={<User />} />
        <Route path='service-category' element={<ServiceCategory />} />
        <Route path='service' element={<Service />} />
        <Route path='team' element={<Team />} />
        <Route path='customer' element={<Customer />} />
        <Route path='taskreport' element={<Taskreport />} />
        <Route path='invoice' element={<Invoice />} />
        <Route path='profile' element={<Profile />} />
        <Route path='plan' element={<Plan />} />
        <Route path='change-password' element={<ChangePassword />} />
        <Route path='superadminsettings' element={<SuperadminSettings />} />
      </Route>
      <Route path='termandcondition' element={<TermAndCondition />} />
    </Route>
    <Route path='*' element={<NotFound />} />
  </Route>
));

function App() {
  return (
    <>
      <ThemeProvider>
        <AuthProvider>
          <ClientProvider>
            <RouterProvider router={router} />
          </ClientProvider>
        </AuthProvider>
      </ThemeProvider>

      <Toaster position='top-right' />
    </>
  );
}

export default App;
