import axiosInstance from ".";

export const getTeamMembersByBranch = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/admin/user/getteammembers?branchId=${branchId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getUsersByBranch = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/admin/user?branchId=${branchId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};




export const addUser = async (newUserDetails) => {
    try {
        const response = await axiosInstance.post('/admin/user/register', newUserDetails, 
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data
    } catch (error) {
        throw error
    }
}


export const deleteUser = async (userId) => {
    try {
        const response = await axiosInstance.delete(`/admin/user/${userId}`)
        return response.data
    } catch (error) {
        throw error
    }
}

export const updateUser = async (userId, userData) => {
    try {
        const response = await axiosInstance.put(`/admin/user/${userId}`, userData, 
        { 
            headers: {
                "Content-Type": "multipart/form-data",
            },
        } )
        return response.data
    } catch (error) {
        throw error
    }
}

/* export const updateSuperAdmin = async (superAdminId, superAdminData) => {
    try {
        const response = await axiosInstance.put(`/admin/superadmin/${superAdminId}`, superAdminData)
        return response.data
    } catch (error) {
        throw error
    }
}
 */



export const getUser = async (userId) => {
    try {
        const response = await axiosInstance.get(`/admin/user?id=${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const viewadmindocumentpassword = async (adminDetails) => {
    try {
        const response = await axiosInstance.post('/admin/user/viewdocumentpassword', adminDetails);
        return response.data
    } catch (error) {
        throw error
    }
}

export const verifyadmindocumentpassword = async (adminDetails) => {
    try {
        const response = await axiosInstance.post('/admin/user/verifydocumentpassword', adminDetails);
        return response.data
    } catch (error) {
        throw error
    }
}