import axiosInstance from ".";

export const getSettings = async () => {
    try {
        const response = await axiosInstance.get('admin/setting');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateSettings = async (settingsId, settingsDetails ) => {
    try {
        const response = await axiosInstance.put(`/admin/setting/${settingsId}`, settingsDetails, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data
    } catch (error) {
        throw error
    }
}

export const getFinancialYear = async () => {
    try {
        const response = await axiosInstance.get('admin/financialyear');
        return response.data;
    } catch (error) {
        throw error;
    }
};
