import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';

const AdminProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        localStorage.setItem("adminAppPath",JSON.stringify(location.pathname));
        const user = localStorage.getItem('user');
        const userType = JSON.parse(localStorage.getItem('userType'));
        if (!user) {
            navigate("/", {replace: true});
        }
        if (userType === 'supervisor' && location.pathname === '/app/branch') {
            navigate("/404");
        }
    })
    return (
        <Box sx={{height: "100%"}}>
            {children}
        </Box>
    )
}

export default AdminProtectedRoute;