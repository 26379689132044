import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import ClearIcon from "@mui/icons-material/Clear";
import palette from '../../theme/palette';
import OtpInput from '../CustomerComponent/OtpInput';
import { useAuth } from '../../context/AuthProvider';
import { verifysuperadmindocumentpassword, viewsuperadmindocumentpassword } from '../../api/superadmin';
import { verifyadmindocumentpassword, viewadmindocumentpassword } from '../../api/user';
import toast from 'react-hot-toast';

const ShowDocumentPasswordModal = ({ viewDocumentOtpModal, handleCloseDocumentOTPmodal, documentData }) => {
    const { globalUser } = useAuth();
    const [documentPassword, setDocumentPassword] = useState(null);
    const [otp, setOtp] = useState("");
    const [otpResent, setOtpResent] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);

    const handleValidateOTP = async () => {
        if (globalUser.user.userType === "super_admin") {
            try {
                const response = await verifysuperadmindocumentpassword({
                    superAdminId: globalUser.user?.userId,
                    otp: otp,
                    customerId: documentData?.id,
                });
                if (response.documentOtp) {
                    setDocumentPassword(response.documentOtp);
                    setOtp("")
                }
            } catch (error) {
                toast.error(error.response.data.message);
                setOtp("");
            }
        } else if (globalUser.user.userType === "admin") {
            try {
                const response = await verifyadmindocumentpassword({
                    adminId: globalUser.user?.userId,
                    otp: otp,
                    customerId: documentData?.id,
                });
                if (response.documentOtp) {
                    setDocumentPassword(response.documentOtp);
                    setOtp("")
                }
            } catch (error) {
                toast.error(error.response.data.message);
                setOtp("");
            }
        }
    };

    const handleResendOTP = async () => {
        try {
            if (globalUser.user.userType === "super_admin") {
                const resentOtpResponse = await viewsuperadmindocumentpassword({
                    superAdminId: globalUser.user?.userId
                });
                if (resentOtpResponse) {
                    setOtpResent(true);
                    setResendTimer(120); // 2 minutes timer
                    toast.success("New OTP sent successfully!");
                }
            } else if (globalUser.user.userType === "admin") {
                const resentOtpResponse = await viewadmindocumentpassword({
                    userId: globalUser.user?.userId
                });
                if (resentOtpResponse) {
                    setOtpResent(true);
                    setResendTimer(120); // 2 minutes timer
                    toast.success("New OTP sent successfully!");
                }
            }

        } catch (error) {
            toast.error("Failed to resend OTP");
        }
    };

    useEffect(() => {
        let timerId;
        if (otpResent && resendTimer > 0) {
            timerId = setTimeout(() => setResendTimer(prevTimer => prevTimer - 1), 1000);
        }
        return () => clearTimeout(timerId);
    }, [otpResent, resendTimer]);

    return (
        <>
            <Modal
                open={viewDocumentOtpModal}
                onClose={() => {
                    handleCloseDocumentOTPmodal();
                    setOtp("");
                    setDocumentPassword(null);
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 2,
                        borderRadius: 1,
                        // maxHeight: "80vh",
                        // height: "50px",
                        width: "300px",
                        overflowY: "auto",
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                        <Typography variant='subtitle1'>Verify your OTP</Typography>
                        <IconButton
                            color={palette.grey[500]}
                            onClick={() => {
                                handleCloseDocumentOTPmodal();
                                setOtp("");
                                setDocumentPassword(null);
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Box>

                    <Box mb={2}>
                        <OtpInput separator={<span>-</span>} otp={otp} setOtp={setOtp} length={4} />
                        {otpResent && resendTimer > 0 ? (
                            <Typography textAlign="center" variant='body2' color="#FF83E4" mt={1.5}>
                                Resend OTP in {Math.floor(resendTimer / 60)}:{(resendTimer % 60).toString().padStart(2, '0')}
                            </Typography>
                        ) : (
                            <Box display="flex" justifyContent="center" mt={1.5}>
                                <Typography variant='body2'>Didn't get the otp?</Typography>
                                <Typography
                                    variant='body2'
                                    color="#FF83E4"
                                    mx={1}
                                    sx={{ cursor: "pointer" }}
                                    onClick={handleResendOTP}
                                >
                                    Resend
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    {documentPassword && <Typography variant='body1'>Document Password is :{documentPassword}</Typography>}
                    <Box textAlign="center" mt={5}>
                        <Button variant='contained'
                            onClick={handleValidateOTP}
                            disabled={otp.length !== 4}
                        >
                            Verify
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default ShowDocumentPasswordModal