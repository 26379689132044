import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Typography,
  Link as MUILink,
} from "@mui/material";
import palette from "../../theme/palette";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { SHA256 } from "crypto-js";
import { Link } from "react-router-dom";
import { getAllInvoice, payInvoice } from "../../api/invoice";
import { useClient } from "../../context/ClientProvider";
import moment from "moment";
import CustomTooltip from "../../components/common/CustomTooltip";
import Loader from "../../components/Loader";
import { useIsSmallScreen } from "../../hooks/useResponsive";

const Invoice = () => {
  const isSmallScreen = useIsSmallScreen();
  const { activeGlobalBranch } = useClient();
  const localUserType = localStorage.getItem("userType");
  const userType = JSON.parse(localUserType);
  const activeBranches = [
    {
      id: 1,
      invoiceFor: "Test Branch_1",
      invoiceAmount: "200",
      invoiceMonth: "February",
      generatedAt: "29-02-2014",
      totalAmount: 236,
    },
  ];

  const [allActiveBranch, setAllActiveBranch] = useState(activeBranches);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [loading, setLoading] = useState(true);

  const getInvoice = async () => {
    setLoading(true);
    try {
      const response = await getAllInvoice({
        branchId: activeGlobalBranch.id,
        currentPage: 1,
        pageSize: 10,
      });

      if (response) {
        setInvoiceDetail(response.data);
        setLoading(false);
        // setTotalItems(response.totalPage);
        // setLoading(false);
      }
    } catch (error) {
      console.error("get all invoice Error:", error);
    }
  };

  useEffect(() => {
    getInvoice();
  }, [activeGlobalBranch]);

  const columns = [
    // {
    //   field: "branch.client.name",
    //   headerName: "Client",
    //   flex: 1,
    //   disableColumnMenu: true,
    //   renderCell: ({ row }) => (
    //     <CustomTooltip title={row.branch?.client?.name}>{row.branch?.client?.name || 'N/A'}</CustomTooltip>
    //   ),
    // },
    // {
    //   field: "branch.name",
    //   headerName: "Branch",
    //   flex: 1,
    //   disableColumnMenu: true,
    //   renderCell: ({ row }) => (
    //     <CustomTooltip title={row.branch?.name}>{row.branch?.name || 'N/A'}</CustomTooltip>
    //   ),
    // },
    {
      field: "invoiceName",
      headerName: "Name",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "invoiceMonth",
      headerName: "Month",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "Generated At",
      flex: 1.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <CustomTooltip
          title={
            row.createdAt &&
            moment(row.createdAt).format("Do MMM YYYY, h:mm a")
          }
        >
          {row.createdAt &&
            moment(row.createdAt).format("Do MMM YYYY, h:mm a")}
        </CustomTooltip>
      ),
    },
    {
      field: "invoiceAmount",
      headerName: "Amount",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Payment Status",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "actionInvoice",
      headerName: "",
      flex: 1.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack width="100%" direction="row" justifyContent="center" spacing={1}>
            <Link
              target="_blank"
              to={`${process.env.REACT_APP_API_BASE_IMAGE_URL}/${row?.invoice_url}`}
            >
              <Button variant="contained">Get Invoice</Button>
            </Link>
          </Stack>
        );
      },
    },
  ];

  if (userType === "admin" || userType === "supervisor") {
    columns.push({
      field: "actionPay",
      headerName: "",
      // width: 230,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const handlePayNow = async () => {
          try {
            const response = await payInvoice(row.id);
            // console.log("invoice response", response);
            if (response) {
              // redirect(response.data.instrumentResponse.redirectInfo.url)
              window.location.href =
                response.data.instrumentResponse.redirectInfo.url;
            }
          } catch (err) {
            console.log("payment error", err);
          }
        };

        return (
          <Stack direction="row" spacing={1}>
            {(userType === "admin" || userType === "supervisor") && (
              <Button variant="contained" onClick={handlePayNow}>
                Pay Now
              </Button>
            )}
          </Stack>
        );
      },
    });
  }

  return (
    <Stack height="100%">
      <Container
        sx={{
          paddingX: "15px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          sx={{ border: "", ...(isSmallScreen && { marginTop: 2 }) }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            Invoice
          </Typography>
          {/* <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        disabled={userType === "supervisor" && true}
                        onClick={handleModalOpen}
                    >
                        Download Invoice
                    </Button> */}
          <Box>
            <Button
              variant="contained"
              color="primary"
              sx={{ height: 30 }}
              onClick={() => {
                //window.location.reload()
                getInvoice();
              }}
            >
              Refresh
            </Button>
          </Box>
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Card sx={{ mt: 3, overflowX: "auto" }}>
              <DataGrid
                rows={invoiceDetail}
                columns={columns}
                // hideFooterPagination
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 25, 50]}
                disableRowSelectionOnClick
                localeText={{ noRowsLabel: "No Invoice found" }}
                autoHeight
                sx={{
                  "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-row": {
                    position: "relative",
                  },
                  // header-bottom
                  "& .MuiDataGrid-row:first-child::before": {
                    content: "''",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#0000004D",
                  },
                  // each-row-bottom
                  "& .MuiDataGrid-row::after": {
                    content: "''",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#0000004D",
                  },
                  // footer-bottom
                  "& .MuiDataGrid-row:last-child::after": {
                    content: "''",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#0000004D",
                  },
                  minWidth: "678px",
                  width: "100%",
                  overflowX: "auto",
                }}
              />
            </Card>
          </>
        )}
      </Container>

      {userType === "admin" && (
        <Box
          sx={{ marginTop: "auto", marginBottom: "-50px", textAlign: "center" }}
        >
          {/* <Link to="https://guestcare365.com/?page_id=1706#gc-about-section" target='_blank'>
                    <Typography variant='body2' sx={{ cursor: "pointer" }}>Payment Terms and Conditions </Typography>
                </Link> */}

          <Link
            to="/termandcondition"
            target="_blank"
            style={{ textDecoration: "none", fontSize: "14px" }}
          >
            {"Payment Terms and Conditions"}
          </Link>
        </Box>
      )}
    </Stack>
  );
};

export default Invoice;
