import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import palette from '../../theme/palette'
import Iconify from '../iconify/Iconify'
import { useIsSmallScreen } from '../../hooks/useResponsive'

const PageHeader = ({headerName, handleModalOpen}) => {
    const isSmallScreen = useIsSmallScreen();
    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3} sx={{ border: '', ...(isSmallScreen && { marginTop: 2 }) }}>
                <Typography marginBottom={0} variant="h5" gutterBottom color={palette.primary.light}>
                    {headerName}
                </Typography>
                <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={handleModalOpen}
                >
                    New {headerName}
                </Button>
            </Stack></>
    )
}

export default PageHeader