import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { getBranchsetting, updateBranchsetting } from "../../api/branchSetting";
import toast from "react-hot-toast";
import palette from "../../theme/palette";
import { useIsSmallScreen } from "../../hooks/useResponsive";

const BranchSettingModal = ({ openBranchSeting, setOpenBranchSetting }) => {
  const isSmallScreen = useIsSmallScreen();
  const [initialSetting, setInitialSetting] = useState(null);
  const [setting, setSetting] = useState(null);
  const handleModalClose = () =>
    setOpenBranchSetting((preValue) => ({
      ...preValue,
      status: false,
      branchId: null,
    }));

  const getBranchSettingData = async () => {
    try {
      const response = await getBranchsetting(openBranchSeting?.branchId);
      setSetting(response);
      setInitialSetting(response); // Store initial settings
    } catch (error) {
      // console.log("get branchsetting by branch error", error);
      throw error;
    }
  };

  const handleSettingChange = (settingFor) => {
    setSetting((prevSetting) => ({
      ...prevSetting,
      [settingFor]: !prevSetting[settingFor],
    }));
  };

  const handleSaveSetting = async () => {
    try {
      await updateBranchsetting(setting?.id, setting);
      toast.success("Settings updated successfully!");
      handleModalClose();
    } catch (error) {
      toast.error("Something went wrong");
      // console.log("Error updating settings:", error);
    }
  };

  const handleResetSetting = () => {
    setSetting(initialSetting);
  };

  useEffect(() => {
    getBranchSettingData();
  }, []);
  return (
    <>
      <Drawer
        anchor="right"
        open={openBranchSeting.status}
        onClose={handleModalClose}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              bgcolor: "background.paper",
              width: 400,
              overflowY: "auto",
              marginY: 2,
              ...(isSmallScreen && { width: 280 }),
            }}
          >
            <IconButton
              sx={{ position: "absolute", top: 5, right: 5 }}
              color={palette.grey[500]}
              onClick={handleModalClose}
            >
              <ClearIcon />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0,
              }}
            >
              <Typography variant="h6" gutterBottom mb={0}>
                Branch Setting
              </Typography>
            </Box>
          </Box>
          <Box
            marginTop={5}
            width={400}
            sx={{ ...(isSmallScreen && { width: 280 }) }}
          >
            <Box sx={{ mb: 3 }}>
              <Box
                width="70%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle2">User Prefix</Typography>
                <Switch
                  checked={setting?.isUserPrefixActive || false}
                  onChange={() => handleSettingChange("isUserPrefixActive")}
                />
              </Box>
              <Typography color={{ color: "#807e7e" }} variant="body2">
                This feature allows users to add the client name before their
                usernames.
              </Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
              <Box
                width="70%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle2">
                  OTP Verification enabled
                </Typography>
                <Switch
                  checked={setting?.isOTPVerificationEnabled || false}
                  onChange={() =>
                    handleSettingChange("isOTPVerificationEnabled")
                  }
                />
              </Box>
              <Typography color={{ color: "#807e7e" }} variant="body2">
                This feature allows users to verify the OTP.
              </Typography>
            </Box>

            <Box>
              <Box
                width="70%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle2">
                  Assign supervisor as Manager
                </Typography>
                <Switch
                  checked={setting?.supervisorAsManager || false}
                  onChange={() => handleSettingChange("supervisorAsManager")}
                />
              </Box>
              <Typography color={{ color: "#807e7e" }} variant="body2">
                This feature assigns the the manager role to the Supervisor.
              </Typography>
            </Box>

            <Box display="flex" mt={5}>
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleSaveSetting}
                >
                  Save
                </Button>
              </Box>
              <Box ml={2}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleResetSetting}
                >
                  Reset
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Drawer>
    </>
  );
};

export default BranchSettingModal;
