import axiosInstance from ".";

export const getCheckoutByBranch = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/admin/customercheckout?branchId=${branchId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const findAllCheckoutByBranch = async (checkoutDetails) => {
    try {
        const response = await axiosInstance.post('admin/customercheckout/findall', checkoutDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const createCheckout = async (checkoutData) => {
    try {
        const response = await axiosInstance.post('/admin/customercheckout/multicreate', checkoutData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const updateCheckout = async (checkoutId, checkoutData) => {
    try {
        const response = await axiosInstance.put(`/admin/customercheckout/${checkoutId}`, checkoutData);
        return response.data;
    } catch (error) {
        throw error;
    }
}