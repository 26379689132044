import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import palette from "../../theme/palette";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  DateTimePicker,
  MobileDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Iconify from "../iconify/Iconify";
import deleteIcon from "../../assets/icons/trash.png";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { getRoomAvailability } from "../../api/rooms";

const CheckoutDrawer = ({
  checkoutDrawerOpen,
  handleCheckoutDrawerClose,
  formik,
  getExistingCustomer,
  handleCheckoutDateChange,
  editCustomer,
  actionMode,
  selectedFiles,
  setSelectedFiles,
  fileKey,
  setFileKey,
  handleFileChange,
  removeFile,
  viewFile,
  customerDocument,
  activeGlobalBranch
}) => {
  const isSmallScreen = useIsSmallScreen();
  const [allAvailableRooms, setAllAvailableRooms] = useState([]);
  const [open, setOpen] = useState(false);

  const getAllAvailableRooms = async (checkInDate, checkOutDate) => {
    if (checkInDate && checkOutDate
      && moment(checkInDate).isValid() && moment(checkOutDate).isValid()
      // && moment(checkInDate).format('HH:mm') !== '00:00'
      // && moment(checkOutDate).format('HH:mm') !== '00:00'
      // && moment(checkInDate).format('mm') !== '00'
      // && moment(checkOutDate).format('mm') !== '00'
    ) {

      const response = await getRoomAvailability(
        activeGlobalBranch?.id,
        moment(checkInDate, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DDTHH:mm[Z]'),
        moment(checkOutDate, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DDTHH:mm[Z]')
      );
      setAllAvailableRooms(response);
    }
  }

  const handleAddRoom = () => {
    const newRoom = {
      roomId: "",
      checkInDate: moment(),
      checkOutDate: moment(),
    };
    formik.setFieldValue("rooms", [...formik.values.rooms, newRoom]);
  };
  const handleRemoveRoom = (index) => {
    // Exclude the default input at index 0
    if (index !== 0) {
      const updatedRooms = [...formik.values.rooms];
      updatedRooms.splice(index, 1);
      formik.setFieldValue("rooms", updatedRooms);
    }
    // const rooms = formik.values.rooms.filter((_, idx) => idx !== index);
    // formik.setFieldValue('rooms', rooms);
  };

  useEffect(() => {
    getAllAvailableRooms(formik.values.rooms[0]?.checkInDate, formik.values.rooms[0]?.checkOutDate);

  }, [formik.values.rooms[0]?.checkInDate, formik.values.rooms[0]?.checkOutDate]);

  return (
    <>
      <Drawer
        anchor="right"
        open={checkoutDrawerOpen}
        onClose={handleCheckoutDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: 500,
            ...(isSmallScreen && { width: 320 }),
          },
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              bgcolor: "background.paper",
              // width: 400,
              marginY: 2,
            }}
          >
            <IconButton
              sx={{ position: "absolute", top: 5, right: 5 }}
              color={palette.grey[500]}
              onClick={handleCheckoutDrawerClose}
            >
              <ClearIcon />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0,
              }}
            >
              <Typography variant="h6" gutterBottom mb={2}>
                {actionMode === "add" ? `Add Check-in` : `Edit Check-out`}
              </Typography>
            </Box>
          </Box>

          <form onSubmit={formik.handleSubmit}>
            {actionMode === "add" && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Contact Number *"
                    name="customerContactNumber"
                    onChange={(e) => {
                      formik.handleChange(e);
                      const enteredNumber = e.target.value;
                      if (enteredNumber.length === 10) {
                        getExistingCustomer(enteredNumber);
                      }
                    }}
                  />
                  {formik.touched.customerContactNumber &&
                    formik.errors.customerContactNumber ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerContactNumber}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="First Name *"
                    name="customerFirstName"
                    type="text"
                    {...formik.getFieldProps("customerFirstName")}
                  />
                  {formik.touched.customerFirstName &&
                    formik.errors.customerFirstName ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerFirstName}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Last Name *"
                    name="customerLastName"
                    type="text"
                    {...formik.getFieldProps("customerLastName")}
                  />
                  {formik.touched.customerLastName &&
                    formik.errors.customerLastName ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerLastName}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Document Type"
                    name="customerDocumentType"
                    select
                    value={formik.values.customerDocumentType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value={"addhar"}>Adhar</MenuItem>
                    <MenuItem value={"votor"}>Votor</MenuItem>
                  </TextField>
                  {formik.touched.customerDocumentType &&
                    formik.errors.customerDocumentType ? (
                    <Typography
                      sx={{ color: "red", variant: "body2", fontSize: "12px" }}
                    >
                      {formik.errors.customerDocumentType}
                    </Typography>
                  ) : null}
                </Grid>

                {customerDocument ? (
                  <Grid item xs={12} sm={6}>
                    <Button
                      component="label"
                      variant="outlined"
                      startIcon={<FileOpenIcon />}
                      sx={{ fontSize: 12 }}
                      onClick={() => {
                        const documentUrl = `${process.env.REACT_APP_API_BASE_IMAGE_URL}/customer/${customerDocument}`;
                        window.open(documentUrl, "_blank");
                      }}
                    >
                      View Document
                    </Button>
                  </Grid>
                ) : null}

                <Grid item xs={12} sm={12} display="flex" direction="column">
                  <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                    <Button
                      component="label"
                      variant="outlined"
                      startIcon={<CloudUploadIcon />}
                      sx={{ fontSize: 12 }}
                    >
                      {`Upload${" "}Document`}
                      <input
                        key={fileKey}
                        className="customer-file-upload-input"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                      />
                    </Button>
                    {selectedFiles.length > 0 && (
                      <div style={{ marginTop: 10 }}>
                        {selectedFiles.map((file, index) => (
                          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                            <IconButton
                              sx={{ position: 'relative' }}
                              size="small"
                              color="error"
                              onClick={() => { removeFile(file); setFileKey(Date.now()) }}
                            >
                              <ClearIcon />
                            </IconButton>
                            <Button
                              size="small"
                              variant="text"
                              sx={{ ml: 2 }}
                              onClick={() => viewFile(file)}
                            >
                              View
                            </Button>
                            <Typography sx={{ display: 'inline-block', ml: 1 }}>
                              {file.name}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    )}
                  </Box>
                  <br />
                  <Typography variant="caption" color="primary">document should be only in png/jpg/jpeg/gif format</Typography>
                </Grid>

                {/* Default input */}
                <Box
                  key={0}
                  display="flex"
                  alignItems="center"
                  width={"100%"}
                  mb={0}
                  mt={2}
                >
                  <Grid
                    sx={{
                      marginLeft: 2,
                      borderRadius: 1,
                      paddingRight: 1,
                      paddingBottom: 1,
                      paddingLeft: "0px !important",
                      paddingTop: "0px !important",
                    }}
                    item
                    container
                    spacing={1}
                    border="1px solid #DC419C"
                  >
                    <Grid item xs={6} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDateTimePicker
                          label="Check-in *"
                          value={
                            formik.values.rooms.length > 0
                              ? formik.values.rooms[0].checkInDate
                              : null
                          }
                          onChange={(value) =>
                            formik.setFieldValue(`rooms[0].checkInDate`, value)
                          }
                          minDateTime={moment().startOf("day")}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                      {formik.touched.rooms &&
                        formik.errors.rooms?.[0]?.checkInDate && (
                          <Typography
                            sx={{
                              color: "red",
                              variant: "body2",
                              fontSize: "12px",
                            }}
                          >
                            {formik.errors.rooms[0].checkInDate}
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDateTimePicker
                          label="Check-out *"
                          value={
                            formik.values.rooms.length > 0
                              ? formik.values.rooms[0].checkOutDate
                              : null
                          }
                          onChange={(value) =>
                            formik.setFieldValue(`rooms[0].checkOutDate`, value)
                          }
                          minDateTime={moment().startOf("day")}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                      {formik.touched.rooms &&
                        formik.errors.rooms?.[0]?.checkOutDate && (
                          <Typography
                            sx={{
                              color: "red",
                              variant: "body2",
                              fontSize: "12px",
                            }}
                          >
                            {formik.errors.rooms[0].checkOutDate}
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Autocomplete
                        options={allAvailableRooms}
                        getOptionLabel={(option) => option.name}
                        value={
                          allAvailableRooms?.find(
                            (option) =>
                              option.id === formik.values.rooms[0]?.roomId
                          ) || null
                        }
                        onChange={(event, newValue) =>
                          formik.setFieldValue(
                            `rooms[0].roomId`,
                            newValue?.id || ""
                          )
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Rooms *" />
                        )}
                        renderOption={(props, option) => (
                          <div
                            {...props}
                            style={{
                              backgroundColor:
                                option?.customercheckout?.length >= 1 &&
                                "#fcd4db",
                            }}
                          >
                            {option.name}
                          </div>
                        )}
                      />
                      {formik.touched.rooms &&
                        formik.errors.rooms?.[0]?.roomId && (
                          <Typography
                            sx={{
                              color: "red",
                              variant: "body2",
                              fontSize: "12px",
                            }}
                          >
                            {formik.errors.rooms[0].roomId}
                          </Typography>
                        )}
                    </Grid>
                  </Grid>
                </Box>

                {/* Additional inputs */}
                {formik.values.rooms.slice(1).map((room, index) => (
                  <Box
                    key={index + 1}
                    display="flex"
                    alignItems="center"
                    width={"100%"}
                    mt={2}
                  >
                    <Grid
                      sx={{
                        marginLeft: 2,
                        borderRadius: 1,
                        paddingRight: 1,
                        paddingBottom: 1,
                        paddingLeft: "0px !important",
                        paddingTop: "0px !important",
                      }}
                      item
                      container
                      spacing={1}
                      border="1px solid #DC419C"
                    >
                      {/* DateTimePicker for check-in */}
                      <Grid item xs={6} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MobileDateTimePicker
                            label="Check-in *"
                            value={room.checkInDate}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `rooms[${index + 1}].checkInDate`,
                                value
                              )
                            }
                            minDateTime={moment().startOf("day")}
                            sx={{ width: "100%" }}
                          />
                        </LocalizationProvider>
                        {/* Error message for check-in date */}
                        {formik.touched.rooms &&
                          formik.errors.rooms?.[index + 1]?.checkInDate && (
                            <Typography
                              sx={{
                                color: "red",
                                variant: "body2",
                                fontSize: "12px",
                              }}
                            >
                              {formik.errors.rooms[index + 1].checkInDate}
                            </Typography>
                          )}
                      </Grid>
                      {/* DateTimePicker for check-out */}
                      <Grid item xs={6} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MobileDateTimePicker
                            label="Check-out *"
                            value={room.checkOutDate}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `rooms[${index + 1}].checkOutDate`,
                                value
                              )
                            }
                            minDateTime={moment().startOf("day")}
                            sx={{ width: "100%" }}
                          />
                        </LocalizationProvider>
                        {/* Error message for check-out date */}
                        {formik.touched.rooms &&
                          formik.errors.rooms?.[index + 1]?.checkOutDate && (
                            <Typography
                              sx={{
                                color: "red",
                                variant: "body2",
                                fontSize: "12px",
                              }}
                            >
                              {formik.errors.rooms[index + 1].checkOutDate}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {/* Autocomplete for room selection */}
                        <Autocomplete
                          options={allAvailableRooms}
                          getOptionLabel={(option) => option.name}
                          value={
                            allAvailableRooms.find(
                              (option) => option.id === room.roomId
                            ) || null
                          }
                          onChange={(event, newValue) =>
                            formik.setFieldValue(
                              `rooms[${index + 1}].roomId`,
                              newValue?.id || ""
                            )
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Rooms *" />
                          )}
                          renderOption={(props, option) => (
                            <div
                              {...props}
                              style={{
                                backgroundColor:
                                  option?.customercheckout?.length >= 1 &&
                                  "#fcd4db",
                              }}
                            >
                              {option.name}
                            </div>
                          )}
                        />
                        
                        {/* Error message for room selection */}
                        {formik.touched.rooms &&
                          formik.errors.rooms?.[index + 1]?.roomId && (
                            <Typography
                              sx={{
                                color: "red",
                                variant: "body2",
                                fontSize: "12px",
                              }}
                            >
                              {formik.errors.rooms[index + 1].roomId}
                            </Typography>
                          )}
                      </Grid>
                      {/* Remove Room button */}
                      {index !== 0 > 0 && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          display="flex"
                          justifyContent="flex-end"
                        >
                          {/* <Button
                                                    variant="outlined"
                                                    size='small'
                                                    color="error"
                                                    onClick={() => handleRemoveRoom(index + 1)}
                                                    sx={{ ml: 1, mt: 1 }}
                                                >
                                                    Remove Room
                                                </Button> */}

                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => handleRemoveRoom(index + 1)}
                            sx={{ height: 35, width: 35 }}
                          >
                            <img src={deleteIcon} />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                ))}

                <Grid item xs={12} sm={12}>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={handleAddRoom}
                    sx={{ ml: 1 }}
                  >
                    Add Room
                  </Button>
                </Grid>
              </Grid>
            )}

            {actionMode === "edit" && (
              <Grid item xs={12} sm={12}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDateTimePicker
                    label="CheckOut"
                    value={moment.utc(editCustomer?.checkOut)}
                    onChange={(newDate) => handleCheckoutDateChange(newDate)}
                    minDateTime={moment().startOf("day")}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
                {/* {formik.touched.checkOutDate && formik.errors.checkOutDate ? (
                                            <Typography sx={{ color: 'red', variant: 'body2', fontSize: '12px' }}>{formik.errors.checkOutDate}</Typography>
                                        ) : null} */}
              </Grid>
            )}

            <Box my={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained" color="primary">
                {actionMode === "add" ? "Add Check-in" : "Update Check-out"}
              </Button>
              {actionMode === "add" && (
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  sx={{
                    backgroundColor: palette.grey[500],
                    "&:hover": {
                      backgroundColor: palette.grey[600],
                    },
                    marginLeft: 1,
                  }}
                  onClick={() => formik.resetForm()}
                >
                  clear
                </Button>
              )}
            </Box>
          </form>
        </Container>
      </Drawer>
    </>
  );
};

export default CheckoutDrawer;
