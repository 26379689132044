import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Drawer,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Iconify from "../../components/iconify/Iconify";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import palette from "../../theme/palette";
import { Toaster, toast } from "react-hot-toast";
import SearchInput from "../../components/SearchInput";
import { useClient } from "../../context/ClientProvider";
import deleteIcon from "../../assets/icons/trash.png";
import EditIcon from "../../assets/icons/EditIcon";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import * as Yup from "yup";
import { useFormik } from "formik";

// apis
import { getFloorByBranch } from "../../api/floor";
import {
  addRoom,
  bulkRoomCreate,
  deleteRoom,
  getAllRoomsPdf,
  getRoomByBranch,
  getRoomByFloor,
  updateRoom,
} from "../../api/rooms";
import { Link } from "react-router-dom";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import CreateBulkRoomModal from "../../components/RoomComponent/CreateBulkRoomModal";

const roomsValidationSchema = (rooms, currentRoomName) =>
  Yup.object().shape({
    roomName: Yup.string().required("Name is required"),
    /* .test('unique', 'Room already exists', function (value) {
        if (!rooms || !Array.isArray(rooms)) return true;
        return !rooms.some(room => room.name.toLowerCase() !== currentRoomName.toLowerCase() && room.name.toLowerCase() === value.toLowerCase());
    }) */ roomDescription: Yup.string().optional("Room description"),
    roomIsActive: Yup.boolean().required("Active is required"),
  });

const Rooms = () => {
  const isSmallScreen = useIsSmallScreen();
  const [openFloor, setOpenFloor] = useState(false);
  const [floorList, setFloorList] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [editedRoom, setEditedRoom] = useState(null);
  const [searchRooms, setSearchRooms] = useState("");

  const { activeGlobalBranch, activeGlobalClient } = useClient();

  // modal mode
  const [modalMode, setModalMode] = useState("add");
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState({
    status: false,
    qrCode: null,
  });

  // delete modal
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    status: false,
    deleteId: null,
  });

  // modal
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = (roomData) => {
    if (roomData.name) {
      setModalOpen(true);
      setModalMode("edit");
      setEditedRoom(roomData);
      formik.setValues({
        roomName: roomData.name,
        roomDescription: roomData.description,
        roomIsActive: roomData.isActive,
      });
    } else {
      if (selectedFloor) {
        setModalOpen(true);
        setModalMode("add");
        setEditedRoom(null);
        formik.resetForm();
      } else {
        toast.error("Please select a floor");
      }
    }
  };

  const [bulkRoomModalOpen, setBulkRoomModalOpen] = useState(false);
  const handleCreateBulkRoomModalOpen = () => {
    setBulkRoomModalOpen(true);
  }
  const createBulkRoom = async (floorId, file) => {
    const roomDetails = {
      branchId: activeGlobalBranch?.id,
      floorId: floorId,
      file: file
    };
    const response = await bulkRoomCreate(roomDetails);
    if (response === "Successfully created") {
      toast.success("Rooms created successfully");
      roomsDataByBranch();
      setBulkRoomModalOpen(false);
    }
  };

  // Autocomplete
  const loadingFloor = openFloor && floorList.length === 0;

  const roomsDataByBranch = async () => {
    try {
      if (activeGlobalBranch?.id) {
        const response = await getRoomByBranch(activeGlobalBranch?.id);
        setRooms(response);
      }
    } catch (error) {
      // console.log("get room by branch error", error);
    }
  };

  // download all rooms qrCode pdf
  const downloadAllRoomPdf = async () => {
    try {
      const response = await getAllRoomsPdf(activeGlobalBranch?.id);
      if (response) {
        window.open(response, "_blank");
      } else {
        toast.error("something went wrong!");
      }
    } catch (error) {
      // console.log("get room by branch error", error);
    }
  };

  // get rooms by floorId
  const roomsData = async () => {
    try {
      const response = await getRoomByFloor(selectedFloor.id);
      setRooms(response);
    } catch (error) {
      // console.log("get branch by client error", error);
    }
  };

  // editRoomData
  const editRoomData = async (roomDetails) => {
    if (
      rooms.find((room) => room.name === roomDetails.name) &&
      editedRoom.name !== roomDetails.name
    ) {
      toast.error("Room name already exists");
    } else {
      try {
        const response = await updateRoom(editedRoom.id, roomDetails);
        if (response) {
          setRooms((prevRooms) =>
            prevRooms.map((room) =>
              // room.id === editedRoom.id ? { ...rooms, id: editedRoom.id, description: roomDetails.description, isActive: roomDetails.isActive, name: roomDetails.name } : room
              room.id === editedRoom.id ? { ...room, ...response } : room
            )
          );
          toast.success("Room updated successfully");
          handleModalClose();
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
        // console.log("edit floor error", error);
      }
    }
  };

  // deleteRoomData
  const deleteRoomData = async () => {
    const { deleteId } = openDeleteDialog;
    try {
      const updatedRoom = rooms.filter((room) => room.id !== deleteId);
      const response = await deleteRoom(deleteId);
      if (response) {
        setRooms(updatedRoom);
        toast.success("Room deleted successfully");
      }
    } catch (error) {
      toast.error("Error on deleting room");
      // console.error('delete room Error:', error);
    }
  };

  // floor data when a branch is selected
  useEffect(() => {
    if (activeGlobalBranch) {
      (async () => {
        try {
          const response = await getFloorByBranch(activeGlobalBranch?.id);
          setFloorList(response);
        } catch (error) {
          console.error("Error fetching branch data: ", error);
        }
      })();
    }
    setSelectedFloor(null);
    setRooms([]);
  }, [activeGlobalBranch]);

  // get room initially by branch
  useEffect(() => {
    roomsDataByBranch();
    // downloadAllRoomPdf();
  }, [activeGlobalBranch]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: "floor",
      headerName: "Floor",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.5,
      renderCell: ({ row }) => <>{row.floor?.name}</>,
    },
    {
      field: "uniqueCode",
      headerName: "Unique Code",
      flex: 0.6,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.uniqueCode}</>,
    },
    {
      field: "qrCode",
      headerName: "QR Code",
      // width: 200,
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={0}>
            <Tooltip title="QR Code">
              <IconButton
                size="large"
                color="primary"
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Avatar
                  alt="QR image"
                  src={`https://d2gea25jcnjbo8.cloudfront.net/qr/${row?.qrcodeUrl}`}
                  variant="square"
                  onClick={() =>
                    setQrCodeModalOpen({
                      ...qrCodeModalOpen,
                      status: true,
                      qrCode: `https://d2gea25jcnjbo8.cloudfront.net/qr/${row?.qrcodeUrl}`,
                    })
                  }
                />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "isActive",
      headerName: "isActive",
      // width: 200,
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      with: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const handleDeleteDialog = (id) => {
          setOpenDeleteDialog({
            ...openDeleteDialog,
            status: true,
            deleteId: id,
          });
        };

        const handleDeleteClose = () => {
          setOpenDeleteDialog({ ...openDeleteDialog, status: false });
        };

        const handleConfirmDelete = () => {
          deleteRoomData();
          handleDeleteClose();
        };

        return (
          <Stack direction="row" spacing={0}>
            {/* Edit Icon */}
            <Tooltip title="Edit">
              <IconButton
                size="medium"
                color="primary"
                onClick={() => handleModalOpen(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            {/* Delete Icon */}
            <Tooltip title="Delete">
              <IconButton
                size="medium"
                color="error"
                onClick={() => handleDeleteDialog(row.id)}
              >
                <img src={deleteIcon} />
              </IconButton>
            </Tooltip>
            {/* Confirmation Modal */}
            <ConfirmationModal
              open={openDeleteDialog.status}
              onClose={handleDeleteClose}
              onConfirm={handleConfirmDelete}
              iconName="room"
            />
          </Stack>
        );
      },
    },
  ];

  // create room
  const formik = useFormik({
    initialValues: {
      roomName: "",
      roomDescription: "",
      roomIsActive: true,
    },
    validationSchema: roomsValidationSchema(rooms, editedRoom?.name || ""),
    onSubmit: async (values) => {
      const roomsDetails = {
        name: values.roomName,
        description: values.roomDescription,
        isActive: values.roomIsActive,
      };
      if (modalMode === "add") {
        roomsDetails.floorId = selectedFloor.id;
        roomsDetails.clientId = activeGlobalClient.id;
      }
      try {
        if (modalMode === "add") {
          if (rooms.find((room) => room.name === roomsDetails.name)) {
            toast.error("Room name already exists");
          } else {
            const response = await addRoom(roomsDetails);
            response && toast.success("Room added successfully");
            // roomsData();
            setRooms(prevRoomData => [
              ...prevRoomData,
              response,
            ]);
            handleModalClose();
          }
        } else if (modalMode === "edit") {
          editRoomData(roomsDetails);
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
      }
    },
  });

  useEffect(() => {
    selectedFloor && roomsData();
  }, [selectedFloor]);

  const printQR = () => {
    window.print();
  };

  const handleRoomTemplateDownload = () => {
    const fileUrl = `${process.env.REACT_APP_API_BASE_IMAGE_URL}/roomtemplate/bulk-room-upload.csv`
    window.open(fileUrl);
  };

  return (
    <>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          sx={{ border: "", ...(isSmallScreen && { marginTop: 2 }) }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            Rooms
          </Typography>
          <Stack direction="row" gap={1.5}>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleModalOpen}
            >
              New Room
            </Button>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleCreateBulkRoomModalOpen}
            >
              Bulk Room Upload
            </Button>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          <Autocomplete
            id="floor"
            size="small"
            sx={{ width: 300 }}
            open={openFloor}
            onOpen={() => {
              setOpenFloor(true);
            }}
            onClose={() => {
              setOpenFloor(false);
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={floorList}
            // loading={loadingFloor}
            onChange={(event, floorValue, isClear) => {
              if (isClear === "clear") {
                roomsDataByBranch();
              }
              setSelectedFloor(floorValue);
            }}
            value={selectedFloor}
            noOptionsText="No rooms"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Floor"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingFloor ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <Button variant="contained" size="small" onClick={downloadAllRoomPdf} sx={{ ...(isSmallScreen && { fontSize: 9 }) }}>
            Download all QR code
          </Button>
          {/* <Button sx={{ height: 30 }} variant='contained' onClick={() => { setStartDate(null); setEndDate(null) }}>Reset</Button> */}
          <Tooltip title="Download Template">
            <IconButton
              size="medium"
              color="primary"
              onClick={handleRoomTemplateDownload}
            >
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </Stack>

        <Card sx={{ mt: 3, overflowX: "auto" }}>
          {/* search */}
          <SearchInput
            sx={{ ml: 1, mt: 1 }}
            label="room"
            value={searchRooms}
            onChange={(e) => {
              setSearchRooms(e.target.value);
            }}
          />
          <DataGrid
            rows={
              rooms &&
              rooms?.filter((room) =>
                Object?.values(room)?.some(
                  (value) =>
                    value !== null &&
                    value
                      ?.toString()
                      .toLowerCase()
                      .includes(searchRooms.toLowerCase())
                )
              )
            }
            // only for name search
            // rows={rooms && rooms?.filter((room) => {
            //     const searchValue = room?.name?.toLowerCase();
            //     return searchValue && searchValue.includes(searchRooms.toLowerCase());
            // })}
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 50]}
            disableRowSelectionOnClick
            autoHeight
            sx={{
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row": {
                position: "relative",
              },
              // header-bottom
              "& .MuiDataGrid-row:first-child::before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // each-row-bottom
              "& .MuiDataGrid-row::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // footer-bottom
              "& .MuiDataGrid-row:last-child::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              minWidth: "678px",
              width: "100%",
              overflowX: "auto",
            }}
            localeText={{ noRowsLabel: "No room found" }}
          />
        </Card>

        <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "background.paper",
                width: 400,
                marginTop: "15px",
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Typography variant="h6" gutterBottom mb={3}>
                  {modalMode === "add"
                    ? `Add Room on ${selectedFloor?.name}`
                    : `Edit Room ${editedRoom?.name}`}
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Name *"
                      name="name"
                      type="text"
                      {...formik.getFieldProps("roomName")}
                    />
                    {formik.touched.roomName && formik.errors.roomName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.roomName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      multiline
                      rows={2}
                      {...formik.getFieldProps("roomDescription")}
                    />
                    {formik.touched.roomDescription &&
                      formik.errors.roomDescription ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.roomDescription}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="isActive"
                      name="roomIsActive"
                      select
                      value={formik.values.roomIsActive}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </TextField>
                    {formik.touched.roomIsActive &&
                      formik.errors.roomIsActive ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.roomIsActive}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  // disabled={!formik.dirty}
                  >
                    {modalMode === "add" ? "Add" : "Update"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: palette.grey[500],
                      "&:hover": {
                        backgroundColor: palette.grey[600],
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>

        {/* modal for QR code */}
        <Modal
          open={qrCodeModalOpen.status}
          onClose={() =>
            setQrCodeModalOpen({ ...qrCodeModalOpen, status: false })
          }
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 1,
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: 0,
                right: 0,
                px: 1,
                background: palette.primary.lighter,
              }}
              onClick={printQR}
            >
              Print
            </Typography>
            {/* Display the QR code image here */}
            <div className="printable-qr">
              <img src={qrCodeModalOpen.qrCode} alt="QR image" />
            </div>
          </Box>
        </Modal>
      </Container>

      <CreateBulkRoomModal
        floorList={floorList}
        bulkRoomModalOpen={bulkRoomModalOpen}
        setBulkRoomModalOpen={setBulkRoomModalOpen}
        createBulkRoom={createBulkRoom}
      />
    </>

  );
};

export default Rooms;
