import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Drawer,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Autocomplete,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Iconify from "../../components/iconify";
import ClearIcon from "@mui/icons-material/Clear";
import toast from "react-hot-toast";
import palette from "../../theme/palette";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import SearchInput from "../../components/SearchInput";
import { useClient } from "../../context/ClientProvider";
import deleteIcon from "../../assets/icons/trash.png";
import EditIcon from "../../assets/icons/EditIcon";
import { loginUserType } from "../../utils/userType";

import * as Yup from "yup";
import { useFormik } from "formik";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// apis
import {
  addServiceCategory,
  deleteServiceCategory,
  getServiceCategory,
  getServiceCategoryByBranch,
  updateServiceCategory,
} from "../../api/serviceCategory";
import { getTeamsByBranch } from "../../api/team";
import { useIsSmallScreen } from "../../hooks/useResponsive";

const serviceCategoryValidationSchema = Yup.object().shape({
  serviceCategoryName: Yup.string().required("Name is required"),
  serviceCategoryDescription: Yup.string().optional(
    "ServiceCategory description"
  ),
  serviceCategoryIsActive: Yup.boolean().required("Active is required"),
  serviceCategoryTeamValue: Yup.array().optional(),
  // .min(1, "Minimum One team is required"),
  serviceCategoryFile: Yup.mixed().test(
    "file",
    "Please select a valid file",
    (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return true;
    }
  ),
});

const ServiceCategory = () => {
  const isSmallScreen = useIsSmallScreen();
  const [serviceCategory, setServiceCategory] = useState([]);
  const [defaultBranchId, setDefaultBranchId] = useState(false);
  const [editedserviceCategory, setEditedserviceCategory] = useState(null);
  const [searchServiceCategory, setSearchServiceCategory] = useState("");
  const [teamList, setTeamList] = useState([]);
  const { activeGlobalBranch } = useClient();
  const localUserType = localStorage.getItem("userType");
  const userType = JSON.parse(localUserType);
  const [clicked, setClicked] = useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // delete modal
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    status: false,
    deleteId: null,
  });
  // modal mode
  const [modalMode, setModalMode] = useState("add");

  // modal for Service category
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => {
    setModalOpen(false);
    setDefaultBranchId(false);
  };

  const handleModalOpen = (serviceCategoryData) => {
    if (serviceCategoryData === "defaultService" && modalMode !== "edit") {
      setModalOpen(true);
      //setDefaultBranchId(true);
    } else if (activeGlobalBranch) {
      setModalOpen(true);
      if (serviceCategoryData.name) {
        setModalMode("edit");
        setEditedserviceCategory(serviceCategoryData);
        formik.setValues({
          serviceCategoryName: serviceCategoryData.name,
          serviceCategoryDescription: serviceCategoryData.description,
          serviceCategoryIsActive: serviceCategoryData.isActive,
          //serviceCategoryTeamValue: serviceCategoryData.team
          serviceCategoryTeamValue:
            serviceCategoryData.teamServiceCategory?.map((item) => item?.team),
        });
      } else {
        setModalMode("add");
        formik.resetForm();
      }
    } else {
      toast.error("Please select client and branch");
    }
  };

  const handleModalOpenNew = (serviceCategoryData) => {
    if (serviceCategoryData === "defaultService") {
      setModalOpen(true);
      setDefaultBranchId(true);
    }

    /* if (serviceCategoryData === "defaultService"){
            setModalOpen(true);
            setDefaultBranchId(true)
            }
            else if(activeGlobalBranch){
                setModalOpen(true);
                if (serviceCategoryData.name) {
                    setModalMode("edit");
                    setEditedserviceCategory(serviceCategoryData);
                    formik.setValues({
                        serviceCategoryName: serviceCategoryData.name,
                        serviceCategoryDescription: serviceCategoryData.description,
                        serviceCategoryIsActive: serviceCategoryData.isActive,
                    });
                } else {
                    setModalMode("add");
                    formik.resetForm();
                }
            }
            else{
                toast.error('Please select client and branch')
            }
        */
  };

  // master service-category-data
  const getServiceCategoryData = async () => {
    try {
      const response = await getServiceCategory();
      // console.log('master service category data response is :', response)
      if (response) {
        setServiceCategory(response);
        setServiceCategory(
          response.sort((a, b) => a.name.localeCompare(b.name))
        );
      }
    } catch (error) {
      toast.error(error.response?.data?.message[0]);
    }
  };

  // editServiceCategoryData
  const editServicCategoryData = async (serviceCategoryDetails) => {
    const updatedServiceCategoryDetails = {};
    if (serviceCategoryDetails.name !== editedserviceCategory.name) {
      updatedServiceCategoryDetails.name = serviceCategoryDetails.name;
    }
    if (
      serviceCategoryDetails.description !== editedserviceCategory.description
    ) {
      updatedServiceCategoryDetails.description =
        serviceCategoryDetails.description;
    }
    if (serviceCategoryDetails.isActive !== editedserviceCategory.isActive) {
      updatedServiceCategoryDetails.isActive = serviceCategoryDetails.isActive;
    }
    if (serviceCategoryDetails.teamIds) {
      updatedServiceCategoryDetails.teamIds = serviceCategoryDetails.teamIds;
    }
    if (serviceCategoryDetails.file) {
      updatedServiceCategoryDetails.file = serviceCategoryDetails.file;
    }
    if (Object.keys(updatedServiceCategoryDetails).length === 0) {
      updatedServiceCategoryDetails.name = editedserviceCategory.name;
    }
    // console.log("edit details :", serviceCategoryDetails);
    try {
      const response = await updateServiceCategory(
        editedserviceCategory.id,
        serviceCategoryDetails
      );
      if (response) {
        setServiceCategory((prevServiceCategory) =>
          prevServiceCategory.map((category) =>
            category.id === editedserviceCategory.id
              ? {
                  ...serviceCategory,
                  id: editedserviceCategory.id,
                  name: serviceCategoryDetails.name,
                  description: serviceCategoryDetails.description,
                  isActive: serviceCategoryDetails.isActive,
                  file: serviceCategoryDetails.file,
                  //serviceCategoryId: newTeamDetails.serviceCategoryId?.id,
                  teamId: serviceCategoryDetails.teamId?.id,
                }
              : category
          )
        );
        toast.success("Service Category updated successfully");
        formik.resetForm();
        //getServiceCategoryData();
        clicked ? getServiceCategoryData() : getServiceCategoryByBranchData();
        handleModalClose();
      }
    } catch (error) {
      if (error.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message?.message[0]);
      } else if (error.response?.data?.statusCode === 409) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error(error.response?.data?.message);
      }
    }
  };

  // deleteServiceCategoryData
  const deleteServiceCategoryData = async () => {
    const { deleteId } = openDeleteDialog;
    try {
      const updatedServiceCategory = serviceCategory.filter(
        (category) => category.id !== deleteId
      );
      const response = await deleteServiceCategory(deleteId);
      if (response) {
        setServiceCategory(updatedServiceCategory);
        toast.success("ServiceCategory deleted successfully");
      }
    } catch (error) {
      toast.error("Error on deleting service category");
    }
  };

  // service category data when a branch is selected or not
  const getServiceCategoryByBranchData = async () => {
    setClicked(false);
    if (activeGlobalBranch) {
      (async () => {
        try {
          const response = await getServiceCategoryByBranch(
            activeGlobalBranch.id
          );
          // console.log(
          //   `service category for selected branch id ${activeGlobalBranch.id} :`,
          //   response
          // );
          setServiceCategory(
            response.sort((a, b) => a.name.localeCompare(b.name))
          );
        } catch (error) {
          console.error("Error in fetching service category data: ", error);
        }

        try {
          const response = await getTeamsByBranch(activeGlobalBranch.id);
          setTeamList(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
          console.log("Error in fetching teams data for branch id ", error);
        }
      })();
    }
  };

  useEffect(() => {
    activeGlobalBranch && getServiceCategoryByBranchData();
  }, [activeGlobalBranch]);

  const loadDefaultServiceCategory = () => {
    getServiceCategoryData();
    setClicked(true);
  };

  // create serviceCategory
  const formik = useFormik({
    initialValues: {
      serviceCategoryName: "",
      serviceCategoryDescription: "",
      serviceCategoryIsActive: true,
      serviceCategoryFile: undefined,
      serviceCategoryTeamValue: [],
    },
    validationSchema: serviceCategoryValidationSchema,
    onSubmit: async (values) => {
      const serviceCategoryDetails = {
        name: values.serviceCategoryName,
        description: values.serviceCategoryDescription,
        isActive: values.serviceCategoryIsActive,
        teamIds: JSON.stringify(
          values?.serviceCategoryTeamValue?.map((item) => item.id)
        ),
      };
      if (values.serviceCategoryFile) {
        serviceCategoryDetails.file = values.serviceCategoryFile;
      }
      if (modalMode === "add" && activeGlobalBranch && !defaultBranchId) {
        serviceCategoryDetails.branchId = activeGlobalBranch.id;
      } else {
        serviceCategoryDetails.branchId = null;
      }

      try {
        if (modalMode === "add") {
          // console.log("new service category details :", serviceCategoryDetails);
          //serviceCategoryDetails.teamIds = serviceCategoryDetails.teamIds.toString();
          //const response = true
          const response = await addServiceCategory(serviceCategoryDetails);
          response && toast.success("Service Category added successfully");
          formik.resetForm();
          setServiceCategory((prevServiceCategory) => [
            ...prevServiceCategory,
            response,
          ]);
          handleModalClose();
          getServiceCategoryByBranchData();
          return response;
        } else if (modalMode === "edit") {
          // console.log(
          //   "service category edit details :",
          //   serviceCategoryDetails
          // );
          editServicCategoryData(serviceCategoryDetails);
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
      }
    },
  });

  const columns = [
    {
      field: "image",
      headerName: "",
      width: 5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <>
            <Avatar
              alt="ServiceCategory Img"
              src={`${process.env.REACT_APP_API_BASE_IMAGE_URL}/servicecategory/${row?.imageName}`}
              sx={{ width: 30, height: 30 }}
            />
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      filterable: false,
      disableColumnMenu: true,
      flex: 2,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: "team",
      headerName: "Team",
      flex: 2,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <div>
            {row?.teamServiceCategory
              ?.sort((a, b) => a.team?.name?.localeCompare(b?.team?.name))
              .map((element) => (
                <div title={element?.team?.name}>{element?.team?.name}</div>
              ))}
          </div>
        );
      },
    },
    {
      field: "isActive",
      headerName: "isActive",
      flex: 0.6,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const handleDeleteDialog = (id) => {
          setOpenDeleteDialog({
            ...openDeleteDialog,
            status: true,
            deleteId: id,
          });
        };

        const handleDeleteClose = () => {
          setOpenDeleteDialog({ ...openDeleteDialog, status: false });
        };

        const handleConfirmDelete = () => {
          deleteServiceCategoryData();
          handleDeleteClose();
        };

        return (
          <Stack direction="row" spacing={0}>
            {/* Edit Icon */}
            <Tooltip title="Edit">
              <IconButton
                size="large"
                color="primary"
                onClick={() => handleModalOpen(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            {/* Delete Icon */}
            <Tooltip title="Delete">
              <IconButton
                size="large"
                color="error"
                onClick={() => handleDeleteDialog(row.id)}
              >
                <img height="22px" src={deleteIcon} />
              </IconButton>
            </Tooltip>
            {/* Confirmation Modal */}
            <ConfirmationModal
              open={openDeleteDialog.status}
              onClose={handleDeleteClose}
              onConfirm={handleConfirmDelete}
              iconName="servicecategory"
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
          position: "relative",
          minHeight: "500px",
        }}
      >
        <Stack
          mb={3}
          sx={{
            border: "",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            ...(isSmallScreen && { marginTop: 2, flexDirection: "column" }),
          }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            Service Category
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={{ border: "" }}
          >
            {userType === loginUserType.SUPER_ADMIN ? (
              /*{ <Button
                variant="contained"
                startIcon={<Iconify icon="nonicons:loading-16" />}
                onClick={loadDefaultServiceCategory}
              >
                Load Default Category
              </Button> }*/
              <>
                {clicked === false ? (
                  <Button
                    variant="contained"
                    startIcon={<Iconify icon="nonicons:loading-16" />}
                    onClick={loadDefaultServiceCategory}
                    sx={{
                      ...(isSmallScreen && {
                        fontSize: 8,
                        padding: "6px 10px",
                      }),
                    }}
                  >
                    Load Default Categories
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    //endIcon={<Iconify icon="mdi:close" />}
                    startIcon={<Iconify icon="nonicons:loading-16" />}
                    onClick={getServiceCategoryByBranchData}
                    color="secondary"
                    sx={{
                      ...(isSmallScreen && {
                        fontSize: 8,
                        padding: "6px 10px",
                      }),
                    }}
                  >
                    Load all Categories
                  </Button>
                )}
              </>
            ) : null}
            {userType === loginUserType.SUPER_ADMIN ? (
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => {
                  setDefaultBranchId(true);
                  handleModalOpen("defaultService");
                }}
                sx={{
                  ...(isSmallScreen && { fontSize: 8, padding: "6px 10px" }),
                }}
              >
                Add New Default Category
              </Button>
            ) : null}
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleModalOpen}
              sx={{
                ...(isSmallScreen && { fontSize: 8, padding: "6px 10px" }),
              }}
            >
              New Service Category
            </Button>
          </Stack>
        </Stack>

        <Card sx={{ mt: 3, overflowX: "auto" }}>
          {/* search */}
          <SearchInput
            sx={{ ml: 1, mt: 1 }}
            label="service category"
            value={searchServiceCategory}
            onChange={(e) => {
              setSearchServiceCategory(e.target.value);
            }}
          />
          <DataGrid
            rows={serviceCategory?.filter((category) =>
              Object.values(category).some(
                (value) =>
                  value !== null &&
                  value
                    ?.toString()
                    .toLowerCase()
                    .includes(searchServiceCategory.toLowerCase())
              )
            )}
            columns={columns}
            getRowClassName={(row) => {
              if (
                row?.row?.teamServiceCategory &&
                Object?.keys(row?.row?.teamServiceCategory)?.length === 0
              ) {
                return "highlighted-row";
              }
              return "";
            }}
            getRowId={(row) => row.id}
            getRowHeight={() => "auto"}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 50]}
            disableRowSelectionOnClick
            localeText={{ noRowsLabel: "No Service Category data available" }}
            rowHeight={55}
            autoHeight
            sx={{
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row": {
                position: "relative",
              },
              // header-bottom
              "& .MuiDataGrid-row:first-child::before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // each-row-bottom
              "& .MuiDataGrid-row::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // footer-bottom
              "& .MuiDataGrid-row:last-child::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              minWidth: "678px",
              width: "100%",
              overflowX: "auto",
            }}
          />
        </Card>

        <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "background.paper",
                width: 400,
                overflowY: "auto",
                marginY: 2,
                ...(isSmallScreen && { width: 280 }),
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Typography variant="h6" gutterBottom mb={3}>
                  {modalMode === "add"
                    ? defaultBranchId
                      ? `Add Default Service Category`
                      : `Add Service Category`
                    : `Edit Service Category`}
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ textAlign: "center" }}>
                  <Avatar
                    alt="ServiceCategory Avatar"
                    src={
                      formik.values.serviceCategoryFile
                        ? URL.createObjectURL(formik.values.serviceCategoryFile)
                        : modalMode === "edit" &&
                          editedserviceCategory &&
                          editedserviceCategory.imageName
                        ? `${process.env.REACT_APP_API_BASE_IMAGE_URL}/servicecategory/${editedserviceCategory.imageName}`
                        : "/default-avatar.png"
                    }
                    sx={{
                      width: 70,
                      height: 70,
                      borderRadius: "50%",
                      mx: "auto",
                      mb: 2,
                    }}
                    onClick={() => {
                      document.getElementById("serviceCategoryFile").click();
                    }}
                  />
                  <Input
                    type="file"
                    accept="image/*"
                    id="serviceCategoryFile"
                    name="serviceCategoryFile"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "serviceCategoryFile",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Name *"
                      name="name"
                      type="text"
                      {...formik.getFieldProps("serviceCategoryName")}
                    />
                    {formik.touched.serviceCategoryName &&
                    formik.errors.serviceCategoryName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.serviceCategoryName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      multiline
                      rows={2}
                      {...formik.getFieldProps("serviceCategoryDescription")}
                    />
                    {formik.touched.serviceCategoryDescription &&
                    formik.errors.serviceCategoryDescription ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.serviceCategoryDescription}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="isActive"
                      name="serviceCategoryIsActive"
                      select
                      value={formik.values.serviceCategoryIsActive}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </TextField>
                    {formik.touched.serviceCategoryIsActive &&
                    formik.errors.serviceCategoryIsActive ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.serviceCategoryIsActive}
                      </Typography>
                    ) : null}
                  </Grid>

                  {(modalMode === "edit" && !defaultBranchId && !clicked) || (modalMode === "add" && !defaultBranchId) ? (
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={teamList}
                        value={formik.values.serviceCategoryTeamValue}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option?.name}
                        onChange={(event, checked) => {
                          formik.setFieldValue(
                            "serviceCategoryTeamValue",
                            checked
                          );
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginLeft: 0 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        // style={{ width: 400 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Team"
                            placeholder="Team"
                          />
                        )}
                      />
                      {formik.touched.serviceCategoryTeamValue &&
                      formik.errors.serviceCategoryTeamValue ? (
                        <Typography
                          sx={{
                            color: "red",
                            variant: "body2",
                            fontSize: "12px",
                          }}
                        >
                          {formik.errors.serviceCategoryTeamValue}
                        </Typography>
                      ) : null}
                    </Grid>
                  ) : null}

                  {/* <Grid item xs={12} sm={12}>
                    <Autocomplete
                      options={teamList}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, teamValue) => {
                        formik.setFieldValue("serviceCategoryTeamValue", teamValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Teams" />
                      )}
                    />
                    {formik.touched.serviceCategoryTeamValue &&
                    formik.errors.serviceCategoryTeamValue ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.serviceCategoryTeamValue}
                      </Typography>
                    ) : null}
                  </Grid> */}
                </Grid>
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button type="submit" variant="contained" color="primary">
                    {modalMode === "add" ? "Add" : "Update"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: palette.grey[500],
                      "&:hover": {
                        backgroundColor: palette.grey[600],
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>
      </Container>
    </>
  );
};

export default ServiceCategory;
