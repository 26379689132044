import axiosInstance from ".";

export const updateTeamMember = async (teamMembersDetails) => {
    try {
        const response = await axiosInstance.put(`/admin/teammembers/updatemembers`, teamMembersDetails)
        return response.data
    } catch (error) {
        throw error
    }
}


export const deleteTeamMember = async (userId, teamId) => {
    try {
        const response = await axiosInstance.delete(`/admin/teammembers?teamId=${teamId}&userId=${userId}`)
        return response.data
    } catch (error) {
        throw error
    }
}


