import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Drawer,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Iconify from "../../components/iconify/Iconify";
import { toast } from "react-hot-toast";
import { DataGrid } from "@mui/x-data-grid";
import palette from "../../theme/palette";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import deleteIcon from "../../assets/icons/trash.png";
import EditIcon from "../../assets/icons/EditIcon";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import * as Yup from "yup";
import { useFormik } from "formik";
// apis
import {
  addBranch,
  deleteBranch,
  getBranchByClient,
  updateBranch,
} from "../../api/branch";
import { useClient } from "../../context/ClientProvider";
import { left } from "@popperjs/core";
import ContactInfoPopover from "../../components/common/ContactInfoPopover";
import { IconCross, IconPlus } from "../../assets/icons";
import CustomTooltip from "../../components/common/CustomTooltip";
import BranchSettingModal from "../../components/Modal/BranchSettingModal";
import { useIsSmallScreen } from "../../hooks/useResponsive";
import { getCities, getStates } from "../../api/statecity";
import { getSettings } from "../../api/settings";
import ImageDialog from "../../components/common/ImageDialog";

const createBranchValidationSchema = Yup.object().shape({
  branchName: Yup.string().required("Name is required"),
  branchAddress: Yup.string().required("Address is required"),
  branchDescription: Yup.string().optional("Branch description"),
  //branchPerRoomPrice: Yup.number().required("Room price is required"),
  branchContactNumber: Yup.string()
    .required("Contact is required")
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
  branchWhatsapp: Yup.string()
    .optional()
    .matches(/^[0-9]+$/, "Must be a valid number")
    .min(10, "Must be at least 10 digits")
    .max(10, "Must not exceed 10 digits"),
  defaultServiceCategory: Yup.boolean().optional(),
  branchFbUrl: Yup.string().optional(),
  branchTwitterUrl: Yup.string().optional(),
  branchInstagramUrl: Yup.string().optional(),
  // branchGoogleBusinessUrl: Yup.string().optional(),
  branchFile: Yup.mixed().test(
    "file",
    "Please select a valid file",
    (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return true;
    }
  ),
  branchEmail: Yup.string().email("Invalid email format").nullable().optional(),
  branchStateId: Yup.object().required("State is required"),
  branchCityId: Yup.object().required("City is required"),
  branchGst: Yup.string().required("GST is required"),
});

const Branches = () => {
  const isSmallScreen = useIsSmallScreen();
  const localUserType = localStorage.getItem("userType");
  const userType = JSON.parse(localUserType);
  const {
    activeGlobalClient,
    activeGlobalBranch,
    globalBranchList,
    setGlobalBranchList,
  } = useClient();
  const [isLoading, setIsLoading] = useState(true);
  // branch
  const [branches, setBranches] = useState([]);
  const [setting, setSetting] = useState(null);
  const [openBranchSeting, setOpenBranchSetting] = useState({
    status: false,
    branchId: null,
  });
  const [searchBranchValue, setSearchBranchValue] = useState("");
  // state & city
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  // delete modal
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    status: false,
    deleteId: null,
  });
  const [editedBranch, setEditedBranch] = useState(null);
  // modal mode
  const [modalMode, setModalMode] = useState("add");
  // modal
  const [modalOpen, setModalOpen] = useState(false);
  const [rowClicked, setRowClicked] = useState(null);

  // Image dialog
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState("");

  const handleImageClick = (imageUrl) => {
    setCurrentImageUrl(imageUrl);
    setOpenImageDialog(true);
  };

  // state for contactInfo popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [contactInfo, setContactInfo] = useState(null);

  // handle to show popover
  const showContacts = (event, row) => {
    setAnchorEl(event.currentTarget);
    setContactInfo(row);
    setRowClicked(row.id);
  };

  // handle to close popover
  const handleClosePopover = () => {
    setAnchorEl(null);
    setRowClicked(null);
  };

  const openPopover = Boolean(anchorEl);

  const popoverProps = {
    id: openPopover ? "simple-popover" : undefined,
    open: openPopover,
    anchorEl: anchorEl,
    handleClose: handleClosePopover,
    contactInfo: contactInfo,
    socialLinkFor: "branch",
  };

  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = (branchData) => {
    if (activeGlobalClient) {
      setModalOpen(true);
      if (branchData.name) {
        setModalMode("edit");
        setEditedBranch(branchData);
        formik.setValues({
          branchName: branchData.name,
          branchAddress: branchData?.address,
          branchDescription: branchData?.description,
          //branchPerRoomPrice: branchData.perRoomPrice,
          branchContactNumber: branchData?.contactNumber,
          branchWhatsapp: branchData?.whatsappNumber,
          branchFbUrl: branchData?.fbUrl,
          branchTwitterUrl: branchData?.twitterUrl,
          branchInstagramUrl: branchData?.instagramUrl,
          branchGoogleBusinessUrl: branchData?.googleBusinessUrl,
          branchFile: branchData?.branchFile,
          branchEmail: branchData?.email,
          branchStateId: branchData?.city?.state,
          branchCityId: branchData?.city,
          branchGst: branchData?.gstNumber,
        });
      } else {
        setModalMode("add");
        formik.resetForm();
      }
    } else {
      toast.error("Please select a client");
    }
  };

  const columns = [
    {
      field: "image",
      headerName: "",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const imageUrl = row?.imageUrl
          ? `${process.env.REACT_APP_API_BASE_IMAGE_URL}/branch/${row?.imageUrl}`
          : `${process.env.REACT_APP_API_BASE_IMAGE_URL}/defaultbranchimage/${setting?.defaultImageUrl}`;
        return (
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                alt="Branch Img"
                src={imageUrl}
                sx={{ width: 30, height: 30 }}
                onClick={() => handleImageClick(imageUrl)}
              />
              <CustomTooltip title="Contact Info">
                <IconButton
                  aria-describedby={popoverProps.id}
                  variant="contained"
                  onClick={(e) => showContacts(e, row)}
                >
                  <img
                    height={18}
                    src={row.id === rowClicked ? IconCross : IconPlus}
                  />
                </IconButton>
              </CustomTooltip>
            </Stack>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <CustomTooltip title={row.name}>{row.name}</CustomTooltip>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <CustomTooltip title={row.description}>{row.description}</CustomTooltip>
      ),
    },
    {
      field: "client.plan.name",
      headerName: "Plan",
      flex: 1.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <CustomTooltip title={row.client?.plan?.name}>
          {row.client?.plan?.name || "N/A"}
        </CustomTooltip>
      ),
    },
    {
      field: "client.plan.planFeature.perRoomPrice",
      headerName: "Price",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <CustomTooltip title={row.client?.plan?.planFeature?.perRoomPrice}>
          {row.client?.plan?.planFeature?.perRoomPrice || "N/A"}
        </CustomTooltip>
      ),
    },
    {
      field: "uniqueCode",
      headerName: "Unique Code",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.uniqueCode}</>,
    },
    {
      field: "actions",
      headerName: "Actions",
      // flex: 2,
      width: 160,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const handleDeleteDialog = (id) => {
          setOpenDeleteDialog({
            ...openDeleteDialog,
            status: true,
            deleteId: id,
          });
        };

        const handleDeleteClose = () => {
          setOpenDeleteDialog({ ...openDeleteDialog, status: false });
        };
        const handleConfirmDelete = () => {
          deleteBranchData();
          handleDeleteClose();
        };
        return (
          <Stack direction="row" spacing={0}>
            {/* Edit Icon */}
            <CustomTooltip title="Edit">
              <IconButton
                size="large"
                color="primary"
                disabled={userType === "supervisor" && true}
                onClick={() => handleModalOpen(row)}
              >
                <EditIcon />
              </IconButton>
            </CustomTooltip>

            {/* Delete Icon */}
            <CustomTooltip title="Delete">
              <IconButton
                size="large"
                color="error"
                disabled={
                  userType === "admin" || (userType === "supervisor" && true)
                }
                onClick={() => handleDeleteDialog(row.id)}
              >
                <img src={deleteIcon} />
              </IconButton>
            </CustomTooltip>
            {/* Setting Icon */}
            <CustomTooltip title="Setting">
              <IconButton
                size="large"
                color="primary"
                disabled={userType === "supervisor" && true}
                onClick={() =>
                  setOpenBranchSetting((preValue) => ({
                    ...preValue,
                    status: true,
                    branchId: row.id,
                  }))
                }
              >
                <SettingsOutlinedIcon />
              </IconButton>
            </CustomTooltip>

            {/* Confirmation Modal */}
            <ConfirmationModal
              open={openDeleteDialog.status}
              onClose={handleDeleteClose}
              onConfirm={handleConfirmDelete}
              iconName="branch"
            />
          </Stack>
        );
      },
    },
  ];

  // get branch by clientId
  const branchData = async () => {
    try {
      const response = await getBranchByClient(activeGlobalClient?.id);
      if (response) {
        // setBranches(response);
        // setIsLoading(false);
        setGlobalBranchList(response);
      }
    } catch (error) {
      // console.log("get branch by client error", error);
      throw error;
    }
  };

  // editBranchData
  const editBranchData = async (branchDetails) => {
    const updatedBranchDetails = {};
    if (branchDetails.name !== editedBranch.name) {
      updatedBranchDetails.name = branchDetails.name;
    }
    if (branchDetails.address !== editedBranch.address) {
      updatedBranchDetails.address = branchDetails.address;
    }
    if (branchDetails.contactNumber !== editedBranch.contactNumber) {
      updatedBranchDetails.contactNumber = branchDetails.contactNumber;
    }
    if (branchDetails.description !== editedBranch.description) {
      updatedBranchDetails.description = branchDetails.description;
    }
    if (branchDetails.fbUrl !== editedBranch.fbUrl) {
      updatedBranchDetails.fbUrl = branchDetails.fbUrl;
    }
    if (branchDetails.instagramUrl !== editedBranch.instagramUrl) {
      updatedBranchDetails.instagramUrl = branchDetails.instagramUrl;
    }
    if (branchDetails.twitterUrl !== editedBranch.twitterUrl) {
      updatedBranchDetails.twitterUrl = branchDetails.twitterUrl;
    }
    if (branchDetails.whatsappNumber !== editedBranch.whatsappNumber) {
      updatedBranchDetails.whatsappNumber = branchDetails.whatsappNumber;
    }
    if (branchDetails.perRoomPrice !== editedBranch.perRoomPrice) {
      updatedBranchDetails.perRoomPrice = branchDetails.perRoomPrice;
    }
    if (branchDetails.email !== editedBranch.email) {
      updatedBranchDetails.email = branchDetails.email;
    }
    if (branchDetails.cityId !== editedBranch.cityId) {
      updatedBranchDetails.cityId = branchDetails.cityId;
    }
    if (branchDetails.gstNumber !== editedBranch.gstNumber) {
      updatedBranchDetails.gstNumber = branchDetails.gstNumber;
    }
    if (branchDetails.googleBusinessUrl !== editedBranch.googleBusinessUrl) {
      updatedBranchDetails.googleBusinessUrl = branchDetails.googleBusinessUrl;
    }
    if (branchDetails.file) {
      updatedBranchDetails.file = branchDetails.file;
    }
    try {
      const isNotingToUpdate = Object.keys(updatedBranchDetails).length === 0;
      const response =
        !isNotingToUpdate &&
        (await updateBranch(editedBranch.id, updatedBranchDetails));
      if (response) {
        // setGlobalBranchList((prevBranches) =>
        //   prevBranches.map((branch) =>
        //     branch.id === editedBranch.id
        //       ? {
        //         ...branch,
        //         id: editedBranch.id,
        //         name: branchDetails.name,
        //         address: branchDetails.address,
        //         contactNumber: branchDetails.contactNumber,
        //         description: branchDetails.description,
        //         fbUrl: branchDetails.fbUrl,
        //         instagramUrl: branchDetails.instagramUrl,
        //         twitterUrl: branchDetails.twitterUrl,
        //         whatsappNumber: branchDetails.whatsappNumber,
        //         perRoomPrice: branchDetails.perRoomPrice,
        //         file: branchDetails.file,
        //         email: branchDetails.email,
        //       }
        //       : branch
        //   )
        // );
        branchData();
        toast.success("Branch updated successfully");
        handleModalClose();
      } else {
        toast.success("Branch updated successfully");
        handleModalClose();
      }
    } catch (error) {
      if (error.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message?.message[0]);
      } else if (error.response?.data?.statusCode === 409) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error(error.response?.data?.message);
      }
    }
  };

  // deleteBranchData
  const deleteBranchData = async () => {
    const { deleteId } = openDeleteDialog;
    try {
      const updatedBranchValue = globalBranchList.filter(
        (branch) => branch.id !== deleteId
      );
      const response = await deleteBranch(deleteId);
      if (response) {
        setGlobalBranchList(updatedBranchValue);
        toast.success("Branch deleted successfully");
      }
    } catch (error) {
      toast.error("Error on deleting branch");
      // console.error("delete branch Error:", error);
    }
  };

  // create branch
  const formik = useFormik({
    initialValues: {
      branchName: "",
      branchAddress: "",
      branchDescription: "",
      //branchPerRoomPrice: "",
      branchContactNumber: "",
      branchWhatsapp: "",
      branchFbUrl: "",
      branchTwitterUrl: "",
      branchInstagramUrl: "",
      branchGoogleBusinessUrl: "",
      branchFile: undefined,
      branchEmail: "",
      branchStateId: null,
      branchCityId: null,
      branchGst: "",
      defaultServiceCategory: true,
    },

    validationSchema: createBranchValidationSchema,

    onSubmit: async (values) => {
      const branchDetails = {
        name: values.branchName,
        address: values.branchAddress,
        description: values.branchDescription,
        //perRoomPrice: values.branchPerRoomPrice,
        contactNumber: values.branchContactNumber,
        whatsappNumber: values.branchWhatsapp,
        fbUrl: values.branchFbUrl,
        twitterUrl: values.branchTwitterUrl,
        instagramUrl: values.branchInstagramUrl,
        googleBusinessUrl: values.branchGoogleBusinessUrl,
        email: values.branchEmail ? values.branchEmail : null,
        cityId: values.branchCityId?.id,
        gstNumber: values.branchGst,
        isCopyDefaultData: values.defaultServiceCategory,
      };
      if (values.branchFile) {
        branchDetails.file = values.branchFile;
      }
      if (modalMode === "add") {
        branchDetails.clientId = activeGlobalClient.id;
      }

      try {
        if (modalMode === "add") {
          const response = await addBranch(branchDetails);
          response && toast.success("Branch added successfully");
          setGlobalBranchList((prevBranchList) => [
            ...prevBranchList,
            response,
          ]);
          handleModalClose();
        } else if (modalMode === "edit") {
          editBranchData(branchDetails);
        }
      } catch (error) {
        if (error.response?.data?.statusCode === 400) {
          toast.error(error.response?.data?.message?.message[0]);
        } else if (error.response?.data?.statusCode === 409) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
      }
    },
  });

  useEffect(() => {
    activeGlobalClient && branchData();
  }, [activeGlobalClient]);

  useEffect(() => {
    const getSettingData = async () => {
      const response = await getSettings();
      if (response) {
        setSetting(response[0]);
      }
    };

    getSettingData();
  }, []);

  // fetch state
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await getStates();
        setStates(response);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, []);

  // Fetch cities based on selected state
  const fetchCities = async (stateId) => {
    try {
      const response = await getCities(stateId);
      setCities(response);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  return (
    <>
      <Container
        sx={{
          paddingX: "8px !important",
          marginX: "auto !important",
          maxWidth: "100% !important",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          sx={{
            border: "",
            ...(isSmallScreen && { marginTop: 2 }),
          }}
        >
          <Typography variant="h5" gutterBottom color={palette.primary.light}>
            Branches
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            disabled={userType === "supervisor" && true}
            onClick={handleModalOpen}
          >
            New Branch
          </Button>
        </Stack>

        <Card sx={{ mt: 3, overflowX: "auto" }}>
          {/* search */}
          <TextField
            label="Search Branch"
            variant="outlined"
            size="small"
            value={searchBranchValue}
            onChange={(e) => setSearchBranchValue(e.target.value)}
            sx={{ ml: 1, mt: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <DataGrid
            rows={
              globalBranchList
                ? globalBranchList?.filter((branch) =>
                    Object.values(branch).some(
                      (value) =>
                        value !== null &&
                        value
                          ?.toString()
                          .toLowerCase()
                          .includes(searchBranchValue.toLowerCase())
                    )
                  )
                : []
            }
            columns={columns}
            rowHeight={55}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 50]}
            disableRowSelectionOnClick
            localeText={{ noRowsLabel: "No branch found" }}
            autoHeight={true}
            sx={{
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row": {
                position: "relative",
              },
              // header-bottom
              "& .MuiDataGrid-row:first-child::before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // each-row-bottom
              "& .MuiDataGrid-row::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              // footer-bottom
              "& .MuiDataGrid-row:last-child::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "0.5px",
                backgroundColor: "#0000004D",
              },
              minWidth: "678px",
              width: "100%",
              overflowX: "auto",
            }}
          />
        </Card>

        <Drawer anchor="right" open={modalOpen} onClose={handleModalClose}>
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "background.paper",
                width: 400,
                overflowY: "auto",
                marginY: 2,
                ...(isSmallScreen && { width: 280 }),
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 5, right: 5 }}
                color={palette.grey[500]}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Typography variant="h6" gutterBottom mb={0}>
                  {modalMode === "add"
                    ? "Add Branch"
                    : `Edit Branch ${editedBranch?.name}`}
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ textAlign: "center" }}>
                  <Avatar
                    alt="Branch Avatar"
                    // src={selectedImage ? URL.createObjectURL(selectedImage) : '/default-avatar.png'}
                    src={
                      formik.values.branchFile
                        ? URL.createObjectURL(formik.values.branchFile)
                        : modalMode === "edit" &&
                          editedBranch &&
                          editedBranch.imageUrl
                        ? `${process.env.REACT_APP_API_BASE_IMAGE_URL}/branch/${editedBranch.imageUrl}`
                        : `${process.env.REACT_APP_API_BASE_IMAGE_URL}/defaultbranchimage/${setting?.defaultImageUrl}`
                    }
                    sx={{
                      width: 70,
                      height: 70,
                      borderRadius: "50%",
                      mx: "auto",
                      mb: 2,
                    }}
                    onClick={() => {
                      document.getElementById("branchFile").click();
                    }}
                  />
                  <Input
                    type="file"
                    accept="image/*"
                    id="branchFile"
                    name="branchFile"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "branchFile",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Name *"
                      name="name"
                      type="text"
                      {...formik.getFieldProps("branchName")}
                    />
                    {formik.touched.branchName && formik.errors.branchName ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.branchName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      multiline
                      rows={3}
                      {...formik.getFieldProps("branchDescription")}
                    />
                    {formik.touched.branchDescription &&
                    formik.errors.branchDescription ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.branchDescription}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Address *"
                      name="address"
                      multiline
                      rows={2}
                      {...formik.getFieldProps("branchAddress")}
                    />
                    {formik.touched.branchAddress &&
                    formik.errors.branchAddress ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.branchAddress}
                      </Typography>
                    ) : null}
                  </Grid>
                  {/* state */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={states ? states : []}
                      disableClearable
                      getOptionLabel={(option) => option?.name || ""}
                      onChange={(event, state) => {
                        formik.setFieldValue("branchStateId", state);
                        formik.setFieldValue("branchCityId", null);
                        fetchCities(state.id); // Fetch cities on state selection
                      }}
                      value={formik.values.branchStateId}
                      isOptionEqualToValue={(option, value) =>
                        option?.name === value
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="State *" />
                      )}
                    />
                    {formik.touched.branchStateId &&
                      formik.errors.branchStateId && (
                        <Typography
                          sx={{
                            color: "red",
                            variant: "body2",
                            fontSize: "12px",
                          }}
                        >
                          {formik.errors.branchStateId}
                        </Typography>
                      )}
                  </Grid>
                  {/* city */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={cities ? cities : []}
                      disableClearable
                      getOptionLabel={(option) => option?.name || ""}
                      onChange={(event, city) => {
                        formik.setFieldValue("branchCityId", city);
                      }}
                      value={formik.values.branchCityId}
                      isOptionEqualToValue={(option, value) =>
                        option?.name === value
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="City *" />
                      )}
                    />
                    {formik.touched.branchCityId &&
                      formik.errors.branchCityId && (
                        <Typography
                          sx={{
                            color: "red",
                            variant: "body2",
                            fontSize: "12px",
                          }}
                        >
                          {formik.errors.branchCityId}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="GST Number *"
                      name="gstNumber"
                      {...formik.getFieldProps("branchGst")}
                    />
                    {formik.touched.branchGst && formik.errors.branchGst ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.branchGst}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      {...formik.getFieldProps("branchEmail")}
                    />
                    {formik.touched.branchEmail && formik.errors.branchEmail ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.branchEmail}
                      </Typography>
                    ) : null}
                  </Grid>
                  {/*  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Per Room Price"
                      name="perRoomPrice"
                      {...formik.getFieldProps("branchPerRoomPrice")}
                    />
                    {formik.touched.branchPerRoomPrice &&
                      formik.errors.branchPerRoomPrice ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.branchPerRoomPrice}
                      </Typography>
                    ) : null}
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Contact Number *"
                      name="contactNumber"
                      {...formik.getFieldProps("branchContactNumber")}
                    />
                    {formik.touched.branchContactNumber &&
                    formik.errors.branchContactNumber ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.branchContactNumber}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Whatsapp Number"
                      name="whatsAppNumber"
                      {...formik.getFieldProps("branchWhatsapp")}
                    />
                    {formik.touched.branchWhatsapp &&
                    formik.errors.branchWhatsapp ? (
                      <Typography
                        sx={{
                          color: "red",
                          variant: "body2",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.branchWhatsapp}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Facebook URL"
                      name="fbUrl"
                      {...formik.getFieldProps("branchFbUrl")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Twitter URL"
                      name="twitterUrl"
                      {...formik.getFieldProps("branchTwitterUrl")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Instagram URL"
                      name="instagran URL"
                      {...formik.getFieldProps("branchInstagramUrl")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Google Business URL"
                      name="google business URL"
                      {...formik.getFieldProps("branchGoogleBusinessUrl")}
                    />
                  </Grid>
                  {modalMode === "add" ? (
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.defaultServiceCategory}
                            //onChange={handleCheckboxChange}
                            onChange={(event) => {
                              formik.setFieldValue(
                                "defaultServiceCategory",
                                event.target.checked
                              );
                            }}
                            color="primary"
                          />
                        }
                        label="Copy Default Data"
                      />
                    </Grid>
                  ) : null}
                </Grid>
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button type="submit" variant="contained" color="primary">
                    {modalMode === "add" ? "Add" : "Update"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: palette.grey[500],
                      "&:hover": {
                        backgroundColor: palette.grey[600],
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    clear
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Drawer>
        <ContactInfoPopover {...popoverProps} />

        <ImageDialog
          open={openImageDialog}
          onClose={() => setOpenImageDialog(false)}
          imageUrl={currentImageUrl}
        />

        {/* branch setting modal */}
        {openBranchSeting.status && (
          <BranchSettingModal
            openBranchSeting={openBranchSeting}
            setOpenBranchSetting={setOpenBranchSetting}
          />
        )}
      </Container>
    </>
  );
};

export default Branches;
