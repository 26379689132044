import { Box } from '@mui/material';
import React from 'react'

const LoaderBbl = () => {
    return (
        <Box pb={4}>
            <span class="loader-bbl"></span>
        </Box>
    )
}

export default LoaderBbl;